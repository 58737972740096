import Vue from 'vue';

export default {
  namespaced: true,

  state: {
    locale: null,
    menu: {},
    menus: [],

    //For info about these, see buildDictionaries mutation
    dictionaries: {
      categories: {},
      products: {},
      descriptions: {},
      price_groups: {},
      group_items: {},

      translated_set: new Set(),
    },

    selected_index: 0,

    images_cache: {},
  },

  mutations: {
    setLocale(state, payload) {
      state.locale = payload;
    },
    setSelectedIndex(state, payload) {
      state.selected_index = payload;
    },
    setMenu(state, payload) {
      state.menu = payload;

      if (state.menus.length == 0) {
        state.menus.push(payload);
      }
    },
    setMenus(state, payload) {
      state.menus = payload;
    },
    addMenu(state, payload) {
      state.menus.push(payload);
    },

    cacheImageUrl(state, payload) {
      Vue.set(state.images_cache, payload.key, payload.value);
    },

    /*
            buildDictionaries mutation creates 'translations' for each type of string 
            that can be displayed.

            It allows quick and easy way for components like OrderMenu, CategoryList, etc..
            to show correct names of objects

            If selected locale is not available, strings will be left as-is.

            Example:

            'ru' is a valid menu locale:

            categories: {
                'Drinks': 'Напитки',
                'Salads': 'Салаты'
            }
        */
    addBuiltinTranslations(state, locale) {
      let products_builtin = {};

      if (locale == 'uk') {
        products_builtin = {
          Чайові: 'Чайові',
          'Serving Fee': 'Комісія за обслуговування',
          'Taxes Fee': 'Податок',
        };
      } else if (locale == 'ru') {
        products_builtin = {
          Чайові: 'Чаевые',
          'Serving Fee': 'Комиссия за обслуживание',
          'Taxes Fee': 'Налог',
        };
      } else {
        products_builtin = {
          Чайові: 'Tips',
          'Serving Fee': 'Serving Fee',
          'Taxes Fee': 'Tax Fee',
        };
      }

      state.dictionaries.products = Object.assign(
        state.dictionaries.products,
        products_builtin
      );
    },
    clearDictionaries(state) {
      state.dictionaries = {
        categories: {},
        products: {},
        descriptions: {},
        price_groups: {},
        group_items: {},
        translated_set: new Set(),
      };
    },
    buildDictionaries(state) {
      const buildStart = new Date();
      let doTranslation =
        !!state.locale && state.menu.locales.includes(state.locale);

      state.menu.categories.forEach((cat, idx, arr) => {
        const flag = cat.i18n && cat.i18n[state.locale];
        Vue.set(
          state.dictionaries.categories,
          cat.name,
          doTranslation && flag ? cat.i18n[state.locale] : cat.name
        );
      });

      let all_products = state.menu.products;

      const wasTranslatedAlready = (v) =>
        state.dictionaries.translated_set.has(v);

      all_products.forEach((pr, idx, arr) => {
        let pr_i18n = pr.i18n.find((it) => it.locale == state.locale);

        if (!wasTranslatedAlready(pr.name))
          Vue.set(
            state.dictionaries.products,
            pr.name,
            doTranslation && pr_i18n ? pr_i18n.name : pr.name
          );

        if (doTranslation && pr_i18n && pr_i18n.name) {
          state.dictionaries.translated_set.add(pr.name);
        }

        if (!wasTranslatedAlready(pr.description))
          Vue.set(
            state.dictionaries.descriptions,
            pr.description,
            doTranslation && pr_i18n ? pr_i18n.description : pr.description
          );

        if (doTranslation && pr_i18n && pr_i18n.description) {
          state.dictionaries.translated_set.add(pr.description);
        }

        pr.price_groups.forEach((group, group_index) => {
          if (!wasTranslatedAlready(group.name))
            Vue.set(
              state.dictionaries.price_groups,
              group.name,
              doTranslation && pr_i18n && pr_i18n.price_groups[group_index]
                ? pr_i18n.price_groups[group_index].name
                : group.name
            );

          if (
            doTranslation &&
            pr_i18n &&
            pr_i18n.price_groups &&
            pr_i18n.price_groups[group_index] &&
            pr_i18n.price_groups[group_index].name
          ) {
            state.dictionaries.translated_set.add(group.name);
          }

          group.items.forEach((item, item_index) => {
            if (!wasTranslatedAlready(item.name))
              Vue.set(
                state.dictionaries.group_items,
                item.name,
                doTranslation &&
                  pr_i18n &&
                  pr_i18n.price_groups[group_index] &&
                  pr_i18n.price_groups[group_index].items[item_index]
                  ? pr_i18n.price_groups[group_index].items[item_index]
                  : item.name
              );

            if (
              doTranslation &&
              pr_i18n &&
              pr_i18n.price_groups[group_index] &&
              pr_i18n.price_groups[group_index].items[item_index]
            ) {
              state.dictionaries.translated_set.add(item.name);
            }
          });
        });
      });

      console.log(
        `Built translation dictionaries in ${new Date() - buildStart} ms.`
      );
    },
  },

  actions: {
    tryLocale(context, payload) {
      if (context.state.menu.locales.includes(payload)) {
        context.commit('setLocale', payload);
      } else {
        context.commit('setLocale', null);
      }
    },
    tryLocales(context, payload) {
      payload.forEach((ul) => {
        if (context.state.menu.locales.includes(payload)) {
          context.commit('setLocale', payload);
        }
      });
    },
    cacheMenuImages(context, menu) {
      menu.products.forEach((product) => {
        context.commit('cacheImageUrl', {
          key: product.name,
          value: product.image,
        });
      });
    },
    buildAllTranslations(context, locales) {
      const ts_start = Date.now();
      console.log(`Rebuilding menu translations, locales passed = `, locales);
      context.commit('clearDictionaries');
      context.state.menus.forEach((menu) => {
        context.commit('setMenu', menu);
        if (locales && menu.locales) {
          let first_locale = null;

          if (locales.includes(menu.original_locale)) {
            first_locale = menu.original_locale;
          } else {
            first_locale = locales.find(
              (l) => menu.original_locale == l || menu.locales.includes(l)
            );
          }

          context.commit('setLocale', first_locale || null);
        }
        context.commit('buildDictionaries');
      });
      console.log(
        `Rebuilt all possible translations for menus in ${Date.now() -
          ts_start} ms`
      );
    },
  },

  getters: {
    isLocalizing(state) {
      return state.locale != null;
    },

    localize(state) {
      return (type, key) => {
        const v = state.dictionaries[type][key];

        return !!v ? v : key;
      };
    },

    supportedLocales(state) {
      const builtin = ['uk', 'ru', 'en'];

      const menu = state.menus[state.selected_index];

      if (!menu) return builtin;

      const values = [menu.original_locale].concat(menu.locales);

      return Array.from(new Set(values));
    },

    productImageUrl(state) {
      return (product) => {
        const url = state.images_cache[product.name];

        return !!url ? url : '/icons/no-dish.jpg';
      };
    },
  },
};
