const DARK_THEME_THRESHOLD = 18;
const DARK_THEME_LOWER_THRESHOLD = 6; 

let first_hour = null;

let global_override = null;

const isMobile =
  window.innerWidth <= 1020 ||
  /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

function isDarkMode() {
    if (global_override) {
        if (!isMobile) return false; 
        return global_override == "light" ? false : true;
    }

    //We cache the first time we detected hours
    //because when user bypasses DARK_THEME_THRESHOLD
    //in runtime, the UI starts to break
    //so we leave the same theme until page reload
    if (!first_hour) {
        first_hour = new Date().getHours();
    }

    if (window.screen.width >= 768) return false;

    return first_hour >= DARK_THEME_THRESHOLD || first_hour <= DARK_THEME_LOWER_THRESHOLD;
}

function setGlobalOverride(theme) {
    global_override = theme;
}

setTimeout(() => {
    console.log(`Applying theme for, platform_mobile = ${isMobile}, result = ${isDarkMode()}`)
}, 4500);

export default {
    isDarkMode,
    setGlobalOverride
}
