import ThemeService from './service'

export default {
    inserted(el, binding) {
        if (binding && binding.hasOwnProperty('value') && !binding.value) return;
        
        if (ThemeService.isDarkMode()) {
            el.classList.add('dark');
        } else {
            el.classList.remove("dark");
        }
    },
    update(el, binding) {
        if (binding && binding.hasOwnProperty('value') && !binding.value) return;

        if (ThemeService.isDarkMode()) {
            el.classList.add('dark');
        } else {
            el.classList.remove("dark");
        }
    }
}   