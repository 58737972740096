import BaseAPI from '../base'

function initSession(data) {
    return BaseAPI.sendPost(`telemetry/init`, data);
}

function writeGestures(id, gestures) {
    return BaseAPI.sendPost(`telemetry/gestures/${id}`, {
        gestures
    });
}

function writeEvent(id, type, payload, timestamp) {
    return BaseAPI.sendPost(`telemetry/event/${id}`, {
        type,
        payload,
        timestamp
    });
}

function changeTarget(id, cafe_id, mode) {
    return BaseAPI.sendPost(`telemetry/target/${id}`, {
        cafe_id,
        mode
    });
}

function setMilestone(id, type) {
    return BaseAPI.sendPost(`telemetry/milestone/${id}/${type}`);
}

export default {
    initSession,
    writeEvent,
    writeGestures,
    changeTarget,
    setMilestone
}