const UK_LOCALE = {
    errors: {
        access_denied: `В доступі відмовлено.`,
        invalid_data: `Невірний формат даних.`,
        db_query_error: `Помилка зчитування бази даних.`,
        db_write_error: `Помилка запису в базу даних.`,
        not_found: `Запрошуваний об'єкт не знайдено.`,
        cafe_not_ready: `Заклад не налаштований для прийому замовлень.`,
        request_failed: `Слабке Інтернет-з'єднання.`,
        not_available: 'Ця операція недоступна.',
        cafe_not_paid_really: 'Система QRWaiter не оплачена',
        invalid_order_total: 'Невірна сума замовлення',
        cafe_not_paid: 'Система QRWaiter не оплачена',

        retry: 'Перезавантажте сторінку та спробуйте ще раз.',
        tech_problem: 'На жаль, по технічним причинам Ваше замовлення не може бути прийнято.'
    },
    error_modal: {
        text: 'На жаль, під час відправки вашого замовлення сталася помилка. Будь-ласка, спробуйте оновити сторінку, вимкнути/ввімкнути сервіси геолокації і спробуйте відправити замовлення пізніше.'
    },
    banner:{
        title:'Додаток для замовлення їжі',
        get_app:'ЗАВАНТАЖИТИ'
    },
    greeting: {
        make_order: 'Зробити замовлення',
        scheduleTitle: 'Графік роботи закладу',
        until_close_left: 'до закриття',
        closed: 'Зачинено :(',
        table: 'Стіл',
        not_paid: 'Заклад не оплатив послуги QRWaiter :(',
        free_day: 'Вихідний день.',

        introduce_text: 'Ваші персональні дані',
        introduce_input_name: 'Як до вас звертатись?',
        introduce_input_phone: 'Ваш номер телефону',
        skip: 'Пропустити',
        go: 'Вперед!',

        preorder: 'ПЕРЕДЗАМОВЛЕННЯ',

        choose_language: 'Оберіть бажану мову:',
        tutorial: 'Вперше у нас? Дізнайся як робити замовлення :)'
    },
    address:{
        correct_title:'Будь ласка, виправте формат адреси',
        correct_example:'Приклад: вул. Шевченка, 1, Київ',
        select_google: 'Адресу потрібно обрати в випадающому списку із запропонованих варіантів.'
    },
    error_modal:{
        title:'Код помилки:',
        text:'Упс, щось пішло не так...',
        solve:'Перезавантажте сторінку<br> та спробуйте ще раз',
        change_address: 'Будь ласка, змініть адресу доставки'
    },
    active_order: {
        check: 'Перевірте, схоже Ви вже щось замовили',
        modal_text: 'Перевірте, чи це ваше замовлення',
        accept: 'Прийняти',
        cancel: 'Скасувати'
    },
    schedule: {
        title: 'Графік роботи',

        mon: 'Понеділок',
        tue: 'Вівторок',
        wed: 'Середа',
        thu: 'Четвер',
        fri: 'П\'ятниця',
        sat: 'Субота',
        sun: 'Неділя',

        short: {
            mon: 'Пн',
            tue: 'Вт',
            wed: 'Ср',
            thu: 'Чт',
            fri: 'Пт',
            sat: 'Сб',
            sun: 'Нд',
        },

        free_day: 'Вихідний',
    },
    navbar: {
        services: 'Послуги',
        table_no: 'Стіл',
        room_no: 'Номер',
       offert: 'ПУБЛІЧНА ОФЕРТА'
    },
    menu: {
        show_more: 'показати більше...',
        show_less: 'показати менше...',

        menu_closed_after: 'На жаль, це меню працює тільки з ',
        menu_closed_before: 'На жаль, це меню працює тільки до ',

        choose_variant: 'Оберіть варіант:',
        choose_modificators: 'Додатки',
        add_to_cart: '+ Додати до замовлення',
        added_to_cart: 'Додано в корзину',
        recommending: 'QRWaiter рекомендує',
        out_of_stock: 'На жаль, цієї страви більше немає в наявності',

        show_all: 'показати все',

        exclusives: 'Рекомендації',

        weight: 'Вага:',

        where_to_start: 'З чого почати?',

        price_per_unit: 'Ціна за 1 {unit}:'
    },
    switcher: {
        primary: 'Основне меню',
        tags: {
            bar: 'Бар меню'
        }
    },
    cart: {
        empty: 'Ваша корзина порожня.',
        comment: 'Коментар:',
        comment_desc: 'Побажання / прохання для офіціанта',
        next:'Далі >',
        send: 'Відправити замовлення',
        min_check: 'Сума Вашого замовлення має бути щонайменше:',
        wait: 'Обробка...',
        empty_desktop: 'На жаль, в корзині ще немає товарів. Але не засмучуйтесь, Ви завжди можете їх додати.',

        cashback: 'Бонус від цього замовлення складатиме {cashback}'
    },
    not_paid_modal: {
        title: 'Заклад не оплатив послуги',
        text: 'На жаль, цей заклад не оплатив послуги QRWaiter і тому створення замовлень в ньому тимчасово заблоковане.'
    },
    card: {
        status_cart: 'Ви можете редагувати',
        status_waiting: 'Відправлено закладу',
        status_serving: 'Було прийнято {mins} хв. тому',
        status_serving_recent: 'Було прийнято 1 хв. тому',
        status_cancelled: 'Відмінено',
        status_served: 'Подано.',
        status_unpaid: 'Оплата',
        status_paying: 'Оплата...',
        status_executed: 'Оплачено',
        order: 'Замовлення',
        check: 'Чек',
        waiting_time: 'Час очікування:',

        append: 'Дозамовлення',
        waiting_time_long: 'більше 20 хвилин.',
        dont_close: 'Не закривайте сторінку, поки офіціант не прийме Ваше замовлення.',
        play_game: 'Можете поки зіграти в змійку, натиснувши на неї нижче.',
        bonus_balance: 'Баланс бонусного рахунку: {balance} грн.',
        download_app: 'Завантажити додаток',
        order_else: `Замовити ще`,
        self_service: 'Будь ласка, заберіть Ваше замовлення.',
        cancel_reason: 'Причина відмови:'
    },
    actions: {
        cancel: 'Відмінити замовлення',
        pay: 'Розрахуватись',
        cancel_2: 'Скасувати замовлення'
    },
    pay: {
        support_text: 'Якщо Ви задоволені швидкістю обслуговування та сервісом, можете залишити чайові та підтримати заклад :)',
        tip_amount: 'від Вашого чеку',
        tip_info: 'Чайові вибрано автоматично, так як персонал швидко відреагував на ваше замовлення.',
        choose_pay_method: 'Вибрати спосіб оплати',
        method_bonus: 'Бонусний рахунок QRWaiter',
        method_cash: 'Розрахуватись готівкою',
        method_card: 'Розрахуватись через термінал',
        method_liqpay: 'Розрахуватись онлайн',
        method_bonus: 'Бонусний рахунок QRWaiter',
        choose_tip_summ:'Вкажіть суму чайових',
        tip_add_to_cheque:'Додати до чеку',
        separate:'Оплатити окремо'
    },
    cancel: {
        confirm_text: 'Ви дійсно бажаєте відмінити своє замовлення?',
        select_reason: 'Вкажіть причину:',
        reasons: {
            wrong_order: 'Замовив(ла) випадково',
            no_time: 'Немаю часу чекати',
            custom: 'Інша причина'
        },

        enter_reason: 'Напишіть причину',

        button_continue: 'Продовжити',
        button_cancel: 'Скасувати'
    },
    geo: {

        title: `Нам потрібно перевірити\n чи Ви дійсно знаходитесь в закладі`,
        title_hotel: 'Нам потрібно перевірити чи Ви дійсно знаходитесь на території готелю',
        please: 'Будь ласка надайте <br/>  одноразовий доступ <br/> до Вашої геолокації',
        just_click: 'Просто натисніть цю кнопку :)',
        try_again: 'Спробувати ще раз',
        reload:'Перезавантажити сторінку',
        fail_title: 'Щоб зробити замовлення Ви повинні надати доступ до геолокації, щоб система зрозуміла, що ви зназходитесь в закладі.',
        fail_titles: {
            browser: 'Ви не надали доступ <br> до своєї геолокації',
            out_of_range: 'Ви знаходитесь<br> поза зоною доставки.',
            not_in_cafe: 'Ви знаходитесь<br>поза зоною закладу.'
        },
        fail_subtitles: {
            browser: "Перезавантажте сторінку\
             та спробуйте ще раз",
            not_in_cafe: 'Замовлення можна зробити тільки знаходячись в закладі',
            out_of_range: 'Доставка до Вашого місцезнаходження недоступна.'
        },
        not_in_cafe: 'Я не знаходжусь в закладі'
    },
    deleted_dish: {
        sorry: 'Вибачте за незручності',
        description: 'Офіціант видалив цю страву(и) з вашого чеку з причини:',
        order_else: 'Замовити щось інше :)'
    },
    superman: {
        wait: 'Зачекайте',
        paid_cash_card: 'Офіціант вже летить вас розраховувати',
        paid_online: 'Ваш платіж полетів в обробку'
    },
    list: {
        total: 'Всього:',
        free: 'Безкоштовно',
        tips: 'Чайові',
        cutlery: 'Прибори'
    },
    bill: {
        check_num: 'ID чеку:',
        serving_time: 'Час обслуговування:',
        waiter: 'Офіціант:',
        table: 'Стіл:',
        date: 'Дата:',
        discount: 'Знижка:',
        download_check: 'Завантажити чек',
        cash: 'Готівка',
        card: 'Термінал',
        online: 'Онлайн',
        connect_cafe:'Підключити власний заклад'
    },  
    preorder: {
        mode_preorder: 'TAKEAWAY',
        mode_delivery: 'DELIVERY',
        title:'Деталі передзамовлення',
        delivery:'Деталі доставки',
        contact_phone: 'Контактний телефон закладу:',
        address:'Вулиця, номер будинку, місто',
        action:'Підтвердити замовлення',
        edit: 'Редагувати'
    },
    preorder_modes: {
        how: 'Як ви бажаєте зробити замовлення?',
        normal_desc: 'Передзамовлення їжі "на виніс" - з собою.',
        delivery_desc: ' Ваше замовлення буде доставлено за вказаною адресою, та на вказану дату та час.',
    
        min_check: 'Мінімальна сума замовлення:'
    },
    mode_modal: {
        min_check_header: 'Мінімальне замовлення',
        min_check_content: 'Сума мінімального замовлення складає',

        delivery_price_header: 'Вартість та умови доставки',

        preorder_title: 'За допомогою системи TAKEAWAY Ви можете переглянути меню та зручно зробити замовлення "з собою".',
        takeaway_title: 'За допомогою системи TAKEAWAY Ви можете переглянути меню та зручно зробити замовлення "з собою".',
        delivery_title: 'За допомогою системи DELIVERY Ви можете переглянути меню та зручно зробити замовлення на доставку.',
        rooms_title: 'За допомогою системи ROOMS Ви можете переглянути меню та замовити їжу, напої або інший рум-сервіс прямо в Ваш номер.',
        reserve_title:'За допомогою системи RESERVE Ви можете замовити столик на вечір.',
        inside_title: '',

        delivery_types: {
            free: 'Доставка в цьому закладі безкоштовна',
            constant: 'Вартість доставки складає {cost}{currency}.',
            additional: 'Вартість доставки нараховується згідно тарифів служби доставки і оплачується додатково при отриманні.',
            conditional: 'При замовленні на суму від {min_check}{currency} - доставка безкоштовна.'
        },

        btn_menu: 'Перейти до меню',
        btn_hide: 'Не показувати цю інформацію'
    },
    delivery_mode_modal: {
        min_check: 'Мінімальна сума замовлення:',
        cost: 'Вартість доставки:',
        cost_details: 'Умови доставки:',
        cost_types: {
            free: 'Доставка в цьому закладі безкоштовна',
            paid: 'Вартість доставки буде додана в Ваш чек.',
            taxi: 'Вартість доставки нараховується згідно тарифів служби доставки.',
            conditional: 'Для замовлень на суму від {cost} {currency} - доставка безкоштовна.'
        },
        cost_free: 'Безкоштовно',
        cost_taxi: 'Додатково'
    },
    preorder_time_modal: {
        header: 'На яку годину підготувати Ваше замовлення',
        header_delivery: 'Коли Ваше замовлення повинно бути доставлене',
        date: 'Дата',
        date_no_change: `Дату передзамовлення змінити неможливо.<br />Вона повинна співпадати з сьогоднішньою.<br />Ящо вона не співпадає – перезавантажте сторінку.`,
        choose_time: 'Оберіть час',
        choose_time_warn: 'Оберіть час на який ваша страва має бути готова.',

        personal_title: 'Ваші персональні дані',
        call_you: 'Як до Вас звертатись?',
        phone: 'Ваш номер телефону',
        phone_desc: `Вкажіть Ваш номер телефону для зв'язку з Вами.`,
        address: 'Ваша адреса',
        address_desc: 'Введіть Вашу адресу для доставки',
        fast_as_possible: 'Якнайшвидше',
        time: 'Час',
        time_desc: 'Оберіть час на який ваша страва має бути готова.',
        address_warning: 'Вкажіть коректну адресу та номер будинку.'
    },
    preorder_bill: {
        title: 'Передзамовлення',

        action_pay: 'Підтвердити замовлення',

        id: 'ID чеку:',
        takeaway: 'Час отримання:',
        date: 'Дата:',
        phone: 'Моб. номер:',
        address: 'Адреса:',
        time: 'Час:',

        warning: 'Ваше передзамовлення було відправлено. <br> Воно буде готове на вказаний Вами час. <br><br> Щоб отримати його, просто покажіть <br> офіціанту цей чек. <br><br> ! Не закривайте цю сторінку, або збережіть <br> чек щоб не втратити його',
        send_after_payment: 'Ваше замовлення буде відправлено закладу після оплати.',

        individual: {
            tenshi_delivery: 'Доставка оплачується при отриманні. Вартість доставки змінюється згідно тарифів служби доставки.'
        },

        cafe_address: 'Адреса закладу:',
        open_map: 'Відкрити карту',

        with_takeaway: 'З собою',
        with_reservation: '+ бронь столика',

        btn_back_to_menu: 'Повернутись в меню',

        taxi_tariff: 'Вартість доставки потрібно буде оплатити додатково при отриманні.'
    },
    delivery_bill: {
        warning: 'Ваше замовлення<br> було відправлено закладу.<br>',
        delivery_item: 'Доставка',
        delivery_additional: 'Додатково',
        delivery_free: 'Безкоштовно',
        delivery_additional_long: 'Додатково при отриманні',
        cash_warning: 'Замовлення потрібно буде оплатити готівкою при отриманні.',
        cost_by_receive: 'Доставка оплачується додатково при отриманні.',

        delivery_cost_upper: 'ДОСТАВКА',
    },
    delivery_info: {
        title: 'Доставка',
        free: 'Доставка безкоштовна',
        constant: 'Вартість доставки становитиме',
        payment_check: 'включено в чек',
        payment_receive: 'оплачується при отриманні',
        taxi: `Вартість доставки нараховується згідно тарифів кур'єрської служби та оплачується при отриманні.`
    },
     reserve_info: {
        title: 'Депозит',
        paid: 'Для бронювання потрібно оплатити депозит',  
        // free: ''
    },
    units: {
        minutes: 'хв.',
        hours: 'год.',
        g: 'г',
        ml: 'мл',
        l: 'л',
        kg: 'кг',
        cm: 'см.',
        secs: 'сек.'
    },
    partners: {
        title: 'Наш партнер:',
        go: 'Перейти',
    },
    roulette: {
        get_a_chance: 'Отримайте шанс виграти страву за рахунок QRWaiter',
        try: 'Спробувати!',
        next_time: 'Іншим разом',
        approve_code: 'Введіть код підтвердження <br> <span>з СМС</span>',
        leave_info: 'Залиште інформацію <br> <span>щоб отримати приз</span>',
        name_ph: 'Як до Вас звертатись?',
        phone_ph: 'Ваш номер телефону',
        next: 'Відправити код через СМС >',
        confirm: 'Підтвердити',
        resend: 'Відправити код ще раз',

        limit: 'Ви вже брали участь у цьому розіграші.',

        your_prize: 'Ваш приз!',

        will_be_added: 'Ваш приз буде додано до чеку. <br /> Ви зможете його отримати, зробивши замовлення через сервіс QRWaiter.',
        no_prize: 'Не пощастило :(',

        you_lost: 'На жаль, Ви нічого не виграли. <br /> Пощастить наступного разу!',

        take_prize: 'Забрати приз!',
        close: 'Закрити'
    },
    back_to_marketplace: 'Повернутись до списку закладів',
    cafe_closed: {
        title: 'Заклад зачинено :(',
        try_later: 'Пограти в змійку',
        to_marketplace: 'Повернутись на головну',
        refresh: 'Перезавантажити сторінку'
    },
    desktop_delivery: {
        navbar_menu: 'Меню',
        navbar_cart: 'Кошик',
        navbar_back: 'На головну',

        tab_cart: 'Корзина',
        tab_client: 'Мої контакти',
        tab_delivery: 'Доставка',

        modal_subtitle: 'Ви можете редагувати',

        next: 'Далі >',
        sms_confirm: 'Введіть код підтвердження з СМС:',

        to_pay: 'До сплати',
        pay_cash: 'Оплатити готівкою',
        pay_online: 'Оплатити онлайн',
        address: 'Адреса',
        address_ph: 'Введіть Вашу адресу',

        menu_quantity: 'Оберіть кількість',

        comment_desc: 'Вкажіть додатковий коментар при необхідності.'
    },
    aiwaiter_survey: {
        help_us: 'Допоможи нам у створенні штучного інтелекту офіціанта.',
        start_button: 'Пройти опитування',
        describe_1: 'Опиши свою улюблену їжу',
        describe_2: 'Яка вона?',
        example: 'Наприклад: італійська, дієтична, японська, веганська, ситна, солодка...',
        send: 'Відправити'
    },
     
    order_buttons: {
        tips: `Чайові`,
        menu:'Меню',
        cart:'Кошик',
        if1: `Якщо Ви власник закладу, Ви можете`,
        if2: `Підключити власний заклад`,
        feedback: `Залишити відгук`,
        go2cheque:'Перейти до замовлення',
        dishes:'страв | {n} страва | {n} страви | {n} страв',
        send: 'Відправити замовлення',
    },
    phone_confirm:{
        title:'Твій номер телефону',
        sub:'Підтвердження номеру',
        smscode:'Введіть код з СМС',
        change_phone: 'Перевірити телефон'
    },
    alcoholic_modal: {
        subtitle: 'Ви додали в замовлення акцизний товар.',
        text: 'Підтвердіть,<br> що Вам виповнилось 18 років.',
        confirm: 'Так, я повнолітній',
        cancel: 'Відміна'
    },
    reserve: {
        header: 'Деталі бронювання',

        name_ph: `Ваше ім'я`,
        fullname_ph: 'Ваше ПІБ',
        phone_ph: 'Номер телефону',

        today: 'Сьогодні',
        tomorrow: 'Завтра',
        guests_count: '0 | 1 особа | {n} особи | {n} осіб',
        guests_amount:'Кількість осіб',
        action: 'Забронювати',

        card_title: 'RESERVE',
        card_guests_count: 'Кількість осіб:',
        card_reserve_time: 'Час бронювання:',
        card_reserve_date: 'Дата бронювання:',
        card_phone: 'Моб. номер:',
        
        back_to_market:'Повернутись до маркетплейсу'
    },
    pay_methods: {
        title: 'СПОСІБ ОПЛАТИ',
        total: 'ВСЬОГО',
        bonus_balance: 'БОНУСНИЙ БАЛАНС',
        null: {
            long: 'Виберіть спосіб оплати'
        },
        cash: {
            short: 'Готівка',
            long: 'Оплатити готівкою',
            preorder: 'Готівкою при отриманні'
        },
        card: {
            short: 'Термінал',
            long: 'Оплатити терміналом',
            preorder: 'Терміналом при отриманні'
        },
        online: {
            short: 'Онлайн',
            long: 'Оплатити онлайн',
            preorder: 'Онлайн-передоплата'
        },
        bonus: {
            short: 'Бонусами',
            long: 'Оплатити бонунсим рахунком',
            preorder: 'Бонусами'
        }
    },
    feedback: {
        header: 'Залишіть відгук',
        for_cafe: 'Про заклад',
        for_qrwaiter: 'Про QRWaiter',
        title:'Відгук:',
        rating: 'Рейтинг',
        send: 'Відправити відгук',
        cancel:'Не залишати відгук',
        comment_ph: 'Ваш коментар',
        close: 'Закрити',
        thanks: 'Дякуємо за відгук!'
    },
    marketplace: {
        list: {
            search_ph: 'Пошук',
            filters: 'Фільтри',
            cities_list: 'Список міст',
            load_error: 'На жаль, не вдалось завантажити список закладів.',
            loading: 'Завантаження...',
            city: 'Місто:',
            all_cities: 'Всі міста',
            favorite_cafes: 'Твої улюблені заклади',
            map: 'Карта закладів',
            no_cafes: 'На жаль, у Вашій локації немає закладів',
            schedule: 'Години роботи:',
            closed: 'Зачинено',
            day_off: 'вихідний',
            opens_at: 'відкриється о',
            all_categories: 'Всі',

            opens_in: 'до відкриття',
            closes_in: 'до закриття',
            open_24hr: 'цілодобово',

            goto_menu: 'Перейти до меню',
            goto_chain: 'Переглянути заклади',

            distance: 'км. від Вас'
        },
        filters: {
            by_mode: 'По системах',
            by_price: 'По ціні:',
            by_city: 'Місто',
            apply: 'Застосувати фільтри',
            all_cities: 'Всі міста',
            geo_city: 'На основі моєї геопозиції',

            desktop_title: 'ВІДОБРАЖАТИ ЗАКЛАДИ',
            sys_inside: 'Замовлення через QR-коди',
            sys_preorder: 'Передзамовлення',
            sys_delivery: 'Доставка',
            sys_reserve: 'Бронювання столів'
        },
        city_filters: {
            geo: 'На основі моєї локації',
            all: 'Всі'
        },
        map: {
            open: 'Відкрити'
        },
        chain: {
            count: 'закладів',
        },
        code_region:'Код країни'
    },
    preorder_details: {
        contacts: 'Ваші контакти',
        takeaway: 'З собою',
        with_reservation: 'Забронювати столик',
        guests_count: 'Кількість наборів',
        guests_count_suffix: 'Без приборів | {n} набір | {n} набори | {n} наборів',
        preorder_title: 'Деталі передзамовлення',
        delivery_title: 'Деталі доставки',
        choose_time: 'Оберіть дату та час',
        choose_time_only: 'Оберіть час',

        details_types: {
            flat: 'Квартира',
            floor: 'Поверх',
            entrance: `Під'їзд`
        },

        new_comment: 'Ви можете залишити коментар до замовлення. Наприклад, вказати алергенні інгрідієнти або ж свої побажання.'
    },
    choose_mode: {
        preorder: 'Замов їжу "з собою"',
        delivery: 'Замов їжу з доставкою',
        reserve: 'Забронюй столик на вечір',
        view_menu: 'Меню',
        delivery_missing: 'Підключити доставку',
        preorder_missing: 'Підключити попереднє замовлення',
        reserve_missing: 'Підключити бронювання столів',
        go_reserve: 'Забронювати стіл',
        preview_menu: 'Переглянути меню'
    },
    download_bill: {
        product_check: 'Товарний чек',
        check_no: 'Чек #',
        table_no: 'Стіл № ',
        waiter: 'Офіціант',
        qnt: 'шт',
        discount: 'Знижка',
        cash: 'Готівка',
        card: 'Термінал',
        online: 'Онлайн',
        pay_method: 'Метод оплати',
        total: 'ВСЬОГО'
    },
    rotate_device: 'Поверніть Ваш пристрій.',
    comment_field_title:'Ваш коментар до замовлення',
    promocode:{
        title:'У вас є промо?',
        input:'Промокод',
        correct_code:'По вашому промокоду Ви отримуєте знижку 20% на весь чек!',
        wrong_code:'Невірний або вже використаний промокод',
        promo_types: {
            check_discount: 'По цьому промокоду Ви отримуєте знижку {discount}% на весь чек!',
            free_delivery: 'По цьому промокоду Ви отримуєте безкоштовну доставку'
        }
    },
    load_error: {
        title: 'На жаль, щось пішло не так :(',
        cafe_not_paid: 'Меню недоступно',
        code: 'Код помилки:',
        support: 'Тех. підтримка',
        back: 'Повернутись на головну',
        mode_setup: 'Система {mode} не налаштована в цьому закладі.',
        wrong_setup: 'Заклад невірно налаштований.',
        not_found: `Об'єкт не знайдено, перевірте правильність посилання.`,
        request_failed: `Запит не вдався, перевірте з'єднання до мережі Інтернет.`
    },
    desktop_blocker: {
        description: 'Ця сторінка доступна тільки на мобільній версії. Проскануйте QR-код, щоб відкрити мобільну версію сайту.'
    },
    activate_cafe: {
        title: 'Заклад {cafe_name} активовано.',
        text: 'Вітаємо! Наклейки з QR-кодами активовані і після оплати підписки Ваші гості зможуть робити замовлення.',
        subscribe: 'Оплатити підписку'
    },
    moeco_partner_card: {
        title_delivery: 'Ваше замовлення буде доставлено екологічним таксі moeco',
        motto: 'Будь еко з moeco',
        title_normal: 'Наш партнер по доставці -<br> еко-таксі moeco.',
        save_contact: 'Зберегти контакт'
    },
    marketplace_geo_modal: {
        hint: 'Це для того, щоб показувати список закладів твого міста',
        title: 'Будь ласка, надай одноразовий<br> доступ до твоєї геолокації',
        ok: 'OK',
        deny: 'Не надавати геолокацію',
        denied: 'В геолокації відмовлено.',
        error_text: 'Для перегляду списку закладів, надайте доступ до геолокації.',
        try_again: 'Спробувати знову'
    },
    split_payment: {
        number: 'рахунок',
        sent_text: 'Ваше замовлення буде відправлено закладу після оплати.',
        pay_button: 'Оплатити рахунок'
    },
    online_payment_modal: {
        redirect_btn: 'Перейти до оплати',
        cancel_btn: 'Скасувати'
    },
    support_modal: {
        title: 'Оберіть зручний для Вас спосіб зв’язку з технічною підтримкою: ',
        close: 'Закрити'
    },
    successfull_payment: {
        title: 'Ваш онлайн платіж було прийнято.',
        button: 'Закрити це вікно',
        subtitle: 'Ви можете закрити цю вкладку та повернутись до замовлення.'
    },
    order_webstatuses: {
        confirmed_off: 'Ваше замовлення відправлено.<br>Очікуємо підтвердження...',
        confirmed_on: 'Замовлення підтверджено',
        cancelled_on: 'Ваше замовлення скасовано:<br>{reason}',
        accepted_off: 'Приготування',
        accepted_on: 'Ваше замовлення готується.<br>Час очікування: {waiting_time} хв',
        served_off: 'Ваше замовлення готове',
        served_on: 'Ваше замовлення готове',
        delivered_off: 'Доставка',
        delivered_on: `Доставка...<br>Орієнтовний час: {shipping_time} хв.`,
        closed_off: 'Оплата',
        closed_on: 'Оплачено',
        reserved_off: 'Підтвердження бронювання',
        reserved_on: 'Підтверджено'
    },
    notifications_update: {
        title: 'QRWaiter відтепер не присилатиме SMS сповіщення',
        content: 'Статус замовлення Ви можете відслідкувати під чеком або на головній сторінці маркетплейсу'
    },
    fix_address_modal: {
        title: 'Будь ласка, виправте адресу',
        subtitle: 'Приклад: вул. Перемоги, 3, Київ'
    },
    tips_modal: {
        title: 'Якщо Вам сподобалось обслуговування, то Ви можете оцінити роботу персоналу за допомогою чайових =)',

        btn_pay: 'Оплатити',
        btn_add: 'Додати в чек',
        btn_cancel: 'Повернутись назад',

        thanks: 'Гарно дякуємо!',

        leave_tips: 'Залишити чайові',
        add_tips: 'Додати чайові'
    },
    order_not_accepted: {
        title: 'Ваше замовлення не було прийнято',
        button: 'Замовити щось інше'
    },
    future_alert: {
        title: 'Попереднє замовлення',
        info: 'Ваше замовлення зроблене в неробочий час. Заклад отримає сповіщення про Ваше замовлення о {date} (при відчиненні)'
    },
    packaging_info: {
        title: 'Упаковка',
        desc: 'В Ваш чек буде додатково включена вартість упаковки на суму'
    }
};


export default UK_LOCALE;