const EN_LOCALE = {
  errors: {
    access_denied: `Access denied.`,
    invalid_data: `Invalid data format.`,
    db_query_error: `Database read error.`,
    db_write_error: `Database write error.`,
    not_found: `Object not found.`,
    cafe_not_ready: `The place is not configured yet.`,
    request_failed: `Connection lost.`,
    not_available: "This action is not available.",
    invalid_order_total: "Invalid order total.",
    cafe_not_paid: "QRWaiter system is not paid",

    retry: "Reload page and try again.",
    tech_problem:
      "Unfortunately, your order could not be processed due to techincal reasons.",
  },
  banner: {
    title: "App for ordering food",
    get_app: "DOWNLOAD",
  },
  greeting: {
    make_order: "Make an order",
    until_close_left: "until closed",
    table: "Table",
    closed: "Closed :(",
    not_paid: "The place has not paid for the QRWaiter subscription :(",
    free_day: "Day off.",

    introduce_text: "Your personal data",
    introduce_input_name: "What is your name?",
    introduce_input_phone: "Your phone number",
    skip: "Skip",
    go: "Go!",
    preorder: "TAKEAWAY",

    choose_language: "Choose language:",
    tutorial: "First time here? Learn how to make an order :)",
  },
  address: {
    correct_title: "Please, change the address",
    select_google:
      "Address must be selected from dropdown of suggested addresses",
    correct_example: "Example: st. Shevchenka, 1, Kiev",
  },
  erorr_modal: {
    title: "Eror code:",
    text: "Oops, something went wrong...",
    solve: "Refresh the page and try again",
  },
  active_order: {
    check: "Seems that you have already ordered something",
    modal_text:
      'If this is your order - press "Accept" to add it to your check',
    accept: "Accept",
    cancel: "Cancel",
  },
  schedule: {
    title: "Working time",
    mon: "Monday",
    tue: "Tuesday",
    wed: "Wednesday",
    thu: "Thursday",
    fri: "Friday",
    sat: "Saturday",
    sun: "Sunday",

    short: {
      mon: "Mo",
      tue: "Tu",
      wed: "We",
      thu: "Th",
      fri: "Fr",
      sat: "Sa",
      sun: "Su",
    },

    free_day: "Day off",
  },
  navbar: {
    services: "Services",
    room_no: "Room",
    table_no: "Table",
    offert: "PUBLIC OFFERT",
  },
  menu: {
    show_more: "show more...",
    show_less: "show less...",

    choose_variant: "Choose a variant:",
    choose_modificators: "Choose additions",
    add_to_cart: "+ Add to cart",
    added_to_cart: "Added to cart",
    recommending: "QRWaiter recommends",

    menu_closed_after: "Unfortunately, this menu works only from ",
    menu_closed_before: "Unfortunately, this menu works only until ",

    out_of_stock: "Unfortunately, this dish is out of stock.",

    show_all: "show all",
    exclusives: "Recommendations",
    weight: "Weight:",
    where_to_start: "Getting started",

    price_per_unit: "Price for 1 {unit}:",
  },
  switcher: {
    primary: "Main menu",
    tags: {
      bar: "Bar",
    },
  },
  cart: {
    empty: "Your cart is empty.",
    comment: "Comment:",
    comment_desc: "Any additional information for the waiter (optional)",
    send: "Send order",
    next: "Next >",
    min_check: "Your total must be at least:",
    wait: "Processing...",
    cashback: "Bonus from this order will equal {cashback}",
    empty_desktop:
      "Unfortunately, your cart is empty. However, it is easy to fix - you can always add a product to it.",
  },
  not_paid_modal: {
    title: "The place hasn't paid",
    text:
      "Unfortunately, this cafe hasn't paid for QRWaiter services and so we have disabled order sending temporarily for this place.",
  },
  card: {
    status_cart: "You can edit",
    status_waiting: "Sent to the cafe",
    status_serving: "Your order was accepted {mins} minutes ago",
    status_serving_recent: "Your order was accepted less than a minute ago.",
    status_served: "Served.",
    status_cancelled: "Cancelled.",
    status_unpaid: "Not paid.",
    status_paying: "Payment in progress...",
    status_executed: "Paid",
    order: "Order",
    check: "Cheque",
    waiting_time: "Waiting time:",
    self_service: "Please, take your order.",
    cancel_reason: "Cancel reason:",

    append: "Order",
    approve_code: "Enter verification code <br> <span>from SMS</span>",
    waiting_time_long: "more than 20 minutes.",
    dont_close: `Don't close the page until the waiter accepts your order.`,
    play_game: "And you can play snake game by clicking on it below.",
    bonus_balance: "Available bonuses: {balance} UAH",
    download_app: "Download app",
    order_else: `Order more`,
  },
  actions: {
    cancel: "Cancel order",
    pay: "Pay",
    cancel_2: "Cancel order",
  },
  pay: {
    support_text: "If you have enjoyed the service - you may leave tips :)",
    tip_amount: "of your check",
    tip_info:
      "Tipping was added automatically as the waiter responded quickly to your order.",
    choose_pay_method: "Choose payment method",
    method_bonus: "Bonus account QRWaiter",
    method_cash: "Pay with cash",
    method_card: "Pay using terminal",
    method_liqpay: "Pay online",
    method_bonus: "Pay with bonuses",
    choose_tip_summ: "Specify tip amount",
    tip_add_to_cheque: "Add to cheque",
    separate: "Pay separated",
  },
  cancel: {
    confirm_text: "Are you sure you want to cancel your order?",
    select_reason: "Please specify reason:",
    reasons: {
      wrong_order: "Ordered by accident",
      no_time: "No time to wait",
      custom: "Custom reason",
    },

    enter_reason: "Enter a reason",

    button_continue: "Continue",
    button_cancel: "Cancel",
  },
  geo: {
    title: "We need to make sure that you are at the place",
    title_hotel: "We need to make sure you are at hotel",
    please: "Please provide us access to your geolocation",
    just_click: "Just press this button :)",
    reload: "Reload page",
    fail_title:
      "To make an order, you must provide geolocation access so we could check if your are really at the place.",
    fail_subtitle:
      "To provide access, please go to Settings -> Privacy -> Geolocation access",

    fail_titles: {
      browser: "You did not give<br>access to your geolocation.",
      out_of_range: "You are out<br>of delivery reach.",
      not_in_cafe: "You are<br>out of cafe reach.",
    },
    fail_subtitles: {
      browser: "Refresh the page and try again.",
      out_of_range: "Delivery to your location is not possible.",
      not_in_cafe: "You must be in cafe to make an order",
    },

    not_in_cafe: "I am not at the cafe",
  },
  deleted_dish: {
    sorry: "Sorry for inconvenience",
    description: "The waiter removed this dish(es) from your order because ",
    order_else: "Order something else :)",
  },
  superman: {
    wait: "Please wait",
    paid_cash_card: "The waiter is coming to give you a bill",
    paid_online: "Your payment is in progress",
  },
  list: {
    total: "Total:",
    free: "Free",
    tips: "Tips",
    cutlery: "Cutlery",
  },
  bill: {
    check_num: "Check ID:",
    serving_time: "Service time:",
    waiter: "Waiter:",
    table: "Table:",
    date: "Date:",
    discount: "Discount:",
    download_check: "Download check",

    cash: "Cash",
    card: "Terminal",
    online: "Online",
    connect_cafe: "Connect own cafe or restaurant",
  },
  preorder_bill: {
    title: "Preorder",

    action_pay: "Confirm order",

    id: "Check ID:",
    takeaway: "Receive time:",
    date: "Date:",
    phone: "Phone:",
    address: "Address:",
    time: "Time:",

    with_takeaway: "Takeaway",
    with_reservation: "+ table reservation",

    open_map: "Open map",

    cafe_address: "Cafe address:",

    btn_back_to_menu: "Back to menu",

    warning:
      "Your preorder was sent. <br>It will be ready at specified time. <br><br> To receive it, just show  <br> that check to the waiter. <br><br> ! Don't close this page or save<br>the bill to prevent losing it.",
    send_after_payment: "Your order will be sent after payment.",
    individual: {
      tenshi_delivery:
        "Delivery cost is payed after receiving the order. The cost depends on delivery service pricing.",
    },
    taxi_tariff:
      "Delivery cost is payed after receiving the order. The cost depends on delivery service pricing.",
  },
  preorder: {
    mode_preorder: "TAKEAWAY",
    mode_delivery: "DELIVERY",
    address: "Street name, house, city",

    contact_phone: "Cafe contact phone:",

    edit: "Edit",
  },
  mode_modal: {
    min_check_header: "Minimal order",
    min_check_content: "Minimal price total equals",

    delivery_price_header: "Delivery pricing and conditions",

    preorder_title:
      'With TAKEAWAY system you can view menu and make a "take-away" order.',
    takeaway_title:
      'With TAKEAWAY system you can view menu and make a "take-away" order.',
    delivery_title:
      "With DELIVERY system you can view menu and easily make a delivery order",
    rooms_title:
      "With ROOMS system you can view menu and order food, drinks or other room-service directly to your room.",

    reserve_title: "With RESERVE system you can book a table for evening",

    delivery_types: {
      free: "Delivery in this cafe is free.",
      constant: "Delivery cost equals {cost} {currency}.",
      additional:
        "Delivery cost is calculated by courier service and is paid upon order receival.",
      conditional:
        "For orders with total over {min_check} {currency} - delivery is free.",
    },

    btn_menu: "View menu",
    btn_hide: `Don't show this again`,
  },
  delivery_bill: {
    warning: "You order<br> has been sent to the cafe.<br>",
    delivery_item: "Delivery",
    delivery_additional: "Additional",
    delivery_free: "Free",
    delivery_additional_long: "Additional upon receiving",
    cash_warning: "The order must be paid by cash after receiving.",
    cost_by_receive: "Delivery is paid additionally upon receiving of order.",
    delivery_cost_upper: "DELIVERY",
  },
  delivery_info: {
    title: "Delivery",
    free: "Delivery is free",
    constant: "Delivery price will equal",
    payment_check: "included in check",
    payment_receive: "paid upon receival",
    taxi: `Delivery price is calculated by courier service and is paid upon receival.`,
  },
  reserve_info: {
    title: "Deposit",
    info: "To make reservation you have to pay deposit",
  },
  units: {
    minutes: "min.",
    hours: "hrs.",
    g: "g",
    ml: "ml",
    l: "l",
    kg: "kg",
    cm: "см",
    secs: "sec.",
  },
  partners: {
    title: "Our partner:",
    go: "Open",
    apteka: {
      name: "Ласкава-Фарм",
      desc: `Network of online pharmacies. Buy medicines directly from your smartphone. Shipping throughout Ukraine.`,
    },
  },
  roulette: {
    get_a_chance: "Get a chance to win a dish from QRWaiter!",
    try: "Try now!",
    next_time: "Later",
    approve_code: "Enter confirmation code <br> <span>for your number.</span>",

    leave_info: "Enter some information, <br> to get a prize.",
    name_ph: "What is your name?",
    phone_ph: "Your mobile phone number",
    next: "Send code via SMS >",
    confirm: "Confirm",
    resend: "Resend code",

    limit: "You have already taken part in this giveaway",

    your_prize: "Your prize!",

    will_be_added:
      "Your prize will be added to your check. <br /> You will be able to claim it, if you will make an order via QRWaiter.",
    no_prize: "No luck :(",

    you_lost: `Unfortunately, you didn't win a prize that time. But don't worry, you'll get lucky next time!`,

    take_prize: "Claim prize!",
    close: "Close",
  },
  preorder_modes: {
    how: "How do you want to make an order?",
    normal_desc: 'Preorder food and drinks as "take-away" order.',
    delivery_desc:
      "Your order will be delivered to the specified address, and at specfied time and date.",

    min_check: "Minimal order total:",
  },
  preorder_time_modal: {
    header: "When you order should be served",
    header_delivery: "When your order should be delivered",
    date: "Date",
    date_no_change: `Preorder date cannot be changed. <br /> It must match the today date.<br />Otherwise - refresh the page.`,
    choose_time: "Choose time",
    choose_time_warn: "Choose time, until which your order must be ready.",

    personal_title: "Your personal info",
    call_you: "What is your name?",
    phone: "Your mobile phone",
    phone_desc: `Enter your mobile phone for feedback.`,
    address: "Your address",
    address_desc: "Enter your delivery address.",
    fast_as_possible: "Fast as possible",
    time: "Time",
    time_desc: "Choose time, until which your order should be ready.",
    address_warning: "Specify correct address and house number.",
  },
  delivery_mode_modal: {
    min_check: "Minimal order total:",
    cost: "Delivery cost:",
    cost_details: "Delivery conditions:",
    cost_types: {
      free: "Delivery in this cafe is free.",
      paid: "Delivery cost will be added to your check.",
      taxi:
        "Delivery cost is calculated according to delivery service pricing.",
      conditional:
        "For orders with total over {cost} {currency} - delivery cost is free.",
    },
    cost_free: "Free",
    cost_taxi: "Additional",
  },
  back_to_marketplace: "Back to cafe list",
  cafe_closed: {
    title: "Place is closed :(",
    try_later: "Play snake",
    to_marketplace: "Back to cafes",
    refresh: "Reload page",
  },
  desktop_delivery: {
    navbar_menu: "Menu",
    navbar_cart: "Cart",
    navbar_back: "To mainpage",

    tab_cart: "Cart",
    tab_client: "My contacts",
    tab_delivery: "Delivery",

    modal_subtitle: "You can edit",

    next: "Next >",
    sms_confirm: "Enter confirmation code from SMS:",

    to_pay: "To pay",
    pay_cash: "Pay by cash",
    pay_online: "Pay online",
    address: "Address",
    address_ph: "Enter your address",

    menu_quantity: "Choose quantity",

    comment_desc: "Enter optional comment if needed.",
  },
  aiwaiter_survey: {
    help_us: "Help us create artifical intelligence waiter",
    start_button: "Start survey",
    describe_1: "Describe your favourite food.",
    describe_2: "What is it like?",
    example: "For example: italian, diet, japanese, vegan, sweet...",
    send: "Send",
  },
  phone_confirm: {
    title: "Your phone number",
    sub: "Phone confirmation",
    smscode: "Enter the code from SMS",
    change_phone: "Re-check phone",
  },
  order_buttons: {
    tips: `Tips`,
    menu: "Menu",
    cart: "Cart",
    if1: `If you are a cafe owner, you can`,
    if2: `Connect your own cafe`,
    feedback: `Leave feedback`,
    go2cheque: "Go to order",
    dishes: "{n} dish | {n} dishes",
    send: "Send order",
  },
  alcoholic_modal: {
    subtitle: "You have added excise product to your order.",
    text: "Confirm,<br> that you are at least 18 years old.",
    confirm: "Yes, I confirm",
    cancel: "Cancel",
  },
  reserve: {
    header: "Reservation details",

    name_ph: `Your name`,
    fullname_ph: "Your fullname",
    phone_ph: "Your phone number",

    today: "Today",
    tomorrow: "Tomorrow",
    guests_count: "0 | 1 person | {n} people",
    guests_amount: "Amount of persons",
    action: "Reserve",

    card_title: "RESERVE",
    card_guests_count: "Guests count:",
    card_reserve_time: "Reservation time:",
    card_reserve_date: "Reservation date:",
    card_phone: "Phone number:",

    back_to_market: "Go back to marketplace",
  },
  pay_methods: {
    title: "PAY METHOD",
    total: "TOTAL",
    bonus_balance: "BONUS BALANCE",
    null: {
      long: "Choose pay method",
    },
    cash: {
      short: "Cash",
      long: "Pay by cash",
      preorder: "Cash by receiving",
    },
    card: {
      short: "Terminal",
      long: "Pay by terminal",
      preorder: "Terminal by receiving",
    },
    online: {
      short: "Online",
      long: "Pay online",
      preorder: "Online pre-paid",
    },
    bonus: {
      short: "Bonus",
      long: "Pay with bonuses",
    },
  },
  feedback: {
    header: "Leave a review",
    for_cafe: "For cafe",
    for_qrwaiter: "For QRWaiter",
    title: "Review:",
    rating: "Rating",
    send: "Send review",
    cancel: "Do not leave a review",
    comment_ph: "Your comment",
    close: "Close",
    thanks: "Thank you for feedback!",
  },
  marketplace: {
    list: {
      search_ph: "Search",
      filters: "Filters",
      cities_list: "Cities list",
      load_error: "Unfortunately, there was an error searching for cafes.",
      loading: "Loading...",
      city: "City:",
      all_cities: "All cities",
      favorite_cafes: "Your favorite cafes",
      map: "Cafes map",
      no_cafes: "Unfortunately, there are no cafes near you.",
      schedule: "Working time:",
      closed: "Closed",
      day_off: "day off",
      opens_at: "opents at",
      opens_in: "till open",
      closes_in: "till close",
      distance: "km. from you",
      goto_menu: "View menu",
      goto_chain: "View cafes",
      open_24hr: "open around the clock",
      all_categories: "All",
    },
    filters: {
      by_mode: "By system",
      by_price: "By price",
      by_city: "City",
      apply: "Apply filters",
      all_cities: "All cities",
      geo_city: "Based on my geolocation",
      desktop_title: "SHOW CAFES",
      sys_inside: "Orders via QR-codes",
      sys_preorder: "Preordering",
      sys_delivery: "Delivery",
      sys_reserve: "Table reservations",
    },
    city_filters: {
      geo: "Based on my geolocation",
      all: "All",
    },
    map: {
      open: "Open",
    },
    chain: {
      count: "spots",
    },
    code_region: "Country code",
  },
  preorder_details: {
    contacts: "Your contacts",
    takeaway: "Takeaway",
    with_reservation: "Reserve a table",
    guests_count: "Cutlery kits count",
    preorder_title: "Preorder details",
    guests_count_suffix: "No cutlery | 1 kit | {n} kits",
    delivery_title: "Delivery details",
    choose_time: "Choose date and time",
    choose_time_only: "Choose time",

    details_types: {
      flat: "Flat",
      floor: "Floor",
      entrance: `Entrance`,
    },

    new_comment:
      "You can leave a comment to the order. For example, specify allergic ingredients or your wish.",
  },
  choose_mode: {
    preorder: "Order takeaway food",
    delivery: "Order food with delivery",
    reserve: "Reserve a table for evening",
    view_menu: "View menu",
    delivery_missing: "Connect delivery",
    preorder_missing: "Connect preordering",
    reserve_missing: "Connect table reservations",
    go_reserve: "Book a table",
    preview_menu: "Preview menu",
  },
  download_bill: {
    product_check: "Check",
    check_no: "Check #",
    table_no: "Table № ",
    waiter: "Waiter",
    qnt: "pc",
    discount: "Discount",
    cash: "Cash",
    card: "Card",
    online: "Online",
    pay_method: "Pay method",
    total: "TOTAL",
  },
  rotate_device: "Please, rotate your device.",
  comment_field_title: "Your comment to the order",
  comment_field_info:
    "You can leave a comment to the order. For example, specify allergenic ingredients or your wishes.",
  promocode: {
    title: "You have a promo?",
    input: "Promocode",
    correct_code: "Your promo gives you 20% discount for the whole cheque!",
    wrong_code: "Invalid or expired promocode",
    promo_types: {
      check_discount: "This promocode gives you {discount}% total discount!",
      free_delivery: "This promocodes gives you free delivery",
    },
  },
  load_error: {
    title: "Something went wrong :(",
    cafe_not_paid: "Menu is unavailable.",
    code: "Error code:",
    back: "Back to mainpage",
    support: "Contact support",
    mode_setup: "System {mode} is not ready in this cafe.",
    wrong_setup: "Cafe is not ready.",
    not_found: `Object not found, check your link validity.`,
    request_failed: `Request failed, check your Internet connection.`,
  },
  desktop_blocker: {
    description:
      "This page is only available on mobile device. Scan the QR-code, to open the mobile version.",
  },
  activate_cafe: {
    title: "Cafe {cafe_name} activated.",
    text:
      "Congratulations! The QR-code stickers have been activated and your guests will be able to make orders right after paying a subscription.",
    subscribe: "Pay subscription",
  },
  moeco_partner_card: {
    title_delivery: "Your order will delivered by eco-taxi moeco",
    motto: "Be eco with moeco",
    title_normal: "Our partner - eco taxi moeco.",
    save_contact: "Save contact",
  },
  marketplace_geo_modal: {
    hint: `It's to show cafes of your town`,
    title: "Please, provide one-time acces <br> to your geolocation",
    ok: "OK",
    deny: `Don't give geolocation`,
    denied: "Denied in geolocation.",
    error_text: "To view cafes list, provide access to your geolocation.",
    try_again: "Retry",
  },
  split_payment: {
    number: "bill",
    sent_text: "Your order will be sent after payment.",
    pay_button: "Pay bill",
  },
  online_payment_modal: {
    redirect_btn: "Go to payment",
    cancel_btn: "Cancel",
  },
  support_modal: {
    title: "Choose the best way for contacting QRWaiter support:",
    close: "Close",
  },
  successfull_payment: {
    title: "Your payment has been accepted.",
    button: "Close this window",
    subtitle: "You can close this tab and return to your order.",
  },
  order_webstatuses: {
    confirmed_off: "Your order has been sent.<br>Awaiting confirmation...",
    confirmed_on: "Order has been confirmed.",
    cancelled_on: "Order cancelled:<br>{reason}",
    accepted_off: "Cooking",
    accepted_on: "Cooking...<br>Waiting time: {waiting_time} min.",
    served_off: "Ready",
    served_on: "You order is ready",
    delivered_off: "Delivery",
    delivered_on: "Delivering...<br>ETA: {shipping_time} min.",
    closed_off: "Payment",
    closed_on: "Payment complete",
    reserved_off: "Needs confirmation",
    reserved_on: "Accepted",
  },
  notifications_update: {
    title: "QRWaiter now will not send SMS notifications",
    content:
      "Order status can be found under the cheque or on marketplace page",
  },
  fix_address_modal: {
    title: "Please, fix your address",
    subtitle: "Example: Manchester Street, 5, London",
  },
  error_modal: {
    title: "Error code:",
    text: "Oops, something went wrong...",
    solve: "Reload page and try again",
    change_address: "Please, change your delivery address",
  },
  tips_modal: {
    title: "If you liked the service, you can leave a tip =)",

    btn_pay: "Pay",
    btn_add: "Add to check",
    btn_cancel: "Go back",

    thanks: "Thanks a lot!",

    leave_tips: "Leave a tip",
    add_tips: "Add a tip",
  },
  order_not_accepted: {
    title: "Your order was not accepted",
    button: "Order something else",
  },
  future_alert: {
    title: "Pre-order",
    info:
      "Your order has been made while cafe is closed. The cafe will receive notification about your order at {date} (after opening)",
  },
  packaging_info: {
    title: "Packaging",
    desc: "Packaging cost will be additionaly included in your check with price of",
  },
};


// module.exports = EN_LOCALE;
export default EN_LOCALE;