const setAPILanguage = (lang, libraries, key) => {
    //Destroy old API
    document.querySelectorAll('script[src^="https://maps.googleapis.com"]').forEach(script => {
        script.remove();
    });
    if (google) delete google.maps;

    //Generate new Google Maps API script
    let newAPI = document.createElement('script');
    newAPI.src = 'https://maps.googleapis.com/maps/api/js?libraries=' + libraries.join(',') + '&key=' + key + '&language=' + lang + '&callback=googleMapsAPILoaded';

    //Callback for the Google Maps API src
    window.googleMapsAPILoaded = () => {
        let event = new CustomEvent('googleMapsAPILoaded');
        window.dispatchEvent(event);
    }

    //Wait for the callback to be executed
    let apiLoaded = new Promise(resolve => {
        window.addEventListener('googleMapsAPILoaded', () => {
            resolve();
        });
    });

    //Start the script
    document.querySelector('head').appendChild(newAPI);

    return apiLoaded;
}

export default {
    setAPILanguage
}