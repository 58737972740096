import Vue from 'vue'

import PromotionsAPI from '@/api/promotions'

import moment from 'moment'

const state = {
    drinkdiscount: null
};

const mutations = {
    setDrinkdiscount(state, payload) {
        state.drinkdiscount = payload;
    }
};

const actions = {
    init(context, payload) {
        if (![
            172,
            236
        ].includes(payload.cafe_id)) {
            return;
        }

        PromotionsAPI.getDrinkdiscount(payload.cafe_id).then(data => {
            context.commit('setDrinkdiscount', data.settings);
        })
    },
    checkCart(context, payload) {
        if (!context.state.drinkdiscount) return;

        const items = context.rootState.cart.items.slice();

        let max_index = -1;
        let max_price = 0;

        const croissant = items.find(p => p.name.includes('Круасан') || p.category == 'Круасан');

        for (let i = 0; i < items.length; i++) {
            items[i].price = items[i].initial_price || items[i].price;

            if (!croissant) continue;

            if (!state.drinkdiscount.categories.toLowerCase().includes(
                items[i].category.toLowerCase()
            )) {
                continue;
            }

            items[i].variants.forEach((v, vi) => {
                if (v.discount_price)
                    items[i].variants[vi].discount_price = v.price;
            });

            if (items[i].price > max_price) {
                max_price = items[i].price;
                max_index = i;
            }
        }

        if (max_index != -1) {
            items[max_index].price = Math.round(items[max_index].price * 0.5);
            items[max_index].variants.forEach((v,vi) => {
                console.log(v, vi);
                items[max_index].variants[vi].discount_price = Math.round(v.price * 0.5);
                
                Vue.set(items[max_index].variants[vi], 'discount_price', Math.round(v.price * 0.5));
            });
        }

        context.commit('cart/setItems', items, {
            root: true
        });
    }
};

const getters = {
    getProductDiscount(state, getters, rootState) {
        return (product) => {
            if (!state.drinkdiscount) return null;

            if (!moment().isBetween(
                state.drinkdiscount.range.start,
                state.drinkdiscount.range.end
            )) return null;

            if (!state.drinkdiscount.categories.toLowerCase().includes(
                product.category.toLowerCase()
            )) {
                return null;
            }

            let discounted_product = rootState.cart.items.find(p => {
                return state.drinkdiscount.categories.toLowerCase().includes(
                    p.category.toLowerCase()
                );
            });

            if (discounted_product) return null;

            const croissant = rootState.cart.items.find(p => p.name.includes('Круасан') || p.category == 'Круасан');

            if (croissant) return 0.5;

            return null;
        };
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}