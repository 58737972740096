import CurrencyAPI from '@/api/currency'

const state = {
    current: {
        code: 'грн.',
        iso_code: 'UAH',
        symbol: '₴'
    },
    can_round_prices: false
};

const mutations = {
    setCurrent(state, payload) {
        state.current = payload;
    },
    setCanRoundPrices(state, value) {
        state.can_round_prices = value;
    }
};

const actions = {
    fetch(context, payload) {
        if (!payload) return;

        CurrencyAPI.getById(payload).then(data =>{
            context.commit('setCurrent', data.currency);
        }).catch(error => {
            console.error(error);
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
}