import BaseAPI from '../base'

function registerView(params) {
    return BaseAPI.sendPost(`analytics/view`, params)
}

function registerEvent(data) {
    return BaseAPI.sendPost(`analytics/event`, data)
}

export default {
    registerView,
    registerEvent
}