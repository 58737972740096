import Vue from 'vue'
import ClientsAPI from '@/api/clients'

const CLIENT_LS_KEY = 'client_id';

const state = {
    client_id: null,
    client: null,
    balance: null
};

const mutations = {
    setClient(state, payload) {
        state.client = payload;
    },
    setClientId(state, payload) {
        state.client_id = payload;
    },
    setBalance(state, payload) {
        state.balance = payload;
    }
};

const actions = {
    fetchClient(context) {
        return new Promise((resolve, reject) => {
            ClientsAPI.getProfile(context.state.client_id).then(data => {
                if (!data.client) {
                    context.commit('clear');
                    reject();
                    return;
                }

                context.commit('setClient', data.client);
                resolve();
            }).catch(error => {
                context.commit('clear');
                console.error(error);
                reject(error);
            });
        });
    },
    fetchBalance(context, payload) {
        if (!context.getters.isAuthorized) return;

        return new Promise((resolve, reject) => {
            ClientsAPI.getBalance(
                context.state.client_id,
                payload.cafe_id
            ).then(data => {
                context.commit('setBalance', data.balance);
                resolve();
            }).catch(error => {
                console.error(error);
                reject(error);
            });
        });
    },
    init(context) {
        return new Promise((resolve, reject) => {
            const cached_id = window.localStorage.getItem(CLIENT_LS_KEY);

            if (cached_id) {
                context.commit('setClientId', cached_id);
                context.dispatch('fetchClient').then(resolve).catch(reject);
            } else {
                resolve();
            }
        });
    },
    setClientId(context, payload) {
        window.localStorage.setItem(CLIENT_LS_KEY, payload);
    },
    clear(context) {
        context.commit('setClientId', null);
        window.localStorage.removeItem(CLIENT_LS_KEY);
    },
    create(context, payload) {
        return new Promise((resolve, reject) => {
            ClientsAPI.create(
                payload.name,
                payload.phone,
                payload.address
            ).then(data => {
                context.commit(`setClient`, data.client);
                context.commit('setClientId', data.client._id);

                window.localStorage.setItem(CLIENT_LS_KEY, data.client._id);

                resolve();
            }).catch(error => {
                context.commit('setClientId', null);
                reject();
            });
        });
    }
};

const getters = {
    isAuthorized(state) {
        return !!state.client_id && state.client;
    },
    canPayByBonus(state, getters) {
        return amount => {
            if (!state.client || !state.client_id) return null;

            return getters.bonusBalance >= amount;
        }
    },
    bonusBalance(state) {
        if (!state.client || !state.balance) return 0;

        return state.balance.bonuses;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}