const GEO_KEY = "geolocation_cache";

function isSupported() {
  return "geolocation" in window.navigator;
}

function getPosition() {
  return new Promise((resolve, reject) => {
    window.navigator.geolocation.getCurrentPosition((pos) => {
      resolve({
        lat: pos.coords.latitude,
        lon: pos.coords.longitude
      });
    }, (err) => {
      reject(err);
    }, {
      enableHighAccuracy: true
    });
  });
}

function cacheGeolocationPermission() {
  window.localStorage.setItem(GEO_KEY, "granted");
}

function isPermissionCached() {
  return window.localStorage.getItem(GEO_KEY) !== null;
}

function clearPermissionCache() {
  window.localStorage.removeItem(GEO_KEY);
}

export default {
  getPosition,
  isSupported,
  isPermissionCached,
  cacheGeolocationPermission,
  clearPermissionCache
}