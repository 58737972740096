import WorldwideAPI from '@/api/worldwide'

import GoogleMapsLanguage from '@/services/googlemaps/language.js'

const state = {
    region_code: 'UA',
    
    //Even here, we must set some default value
    region: {
        phone_code: '{+38}-000-000-00-00',
        language: 'uk'
    }
};

const mutations = {
    setRegionCode(state, payload) {
        state.region_code = payload;
    },
    setRegion(state, payload) {
        state.region = payload;
    }
}

const actions = {
    init(context, payload) {
        context.commit('setRegionCode', payload);

        return new Promise((resolve, reject) => {
            WorldwideAPI.getRegion(payload).then(data => {
                context.commit('setRegion', data.region);

                if (data.region.language != 'uk') GoogleMapsLanguage.setAPILanguage(
                    data.region.language,
                    ['places'],
                    'AIzaSyAE_zfobn3wn1nVswI_Fj_wxFls45U9X7c'
                )

                resolve();
            }).catch(error => {
                console.error(error);
                resolve();
            });
        })
    }
}

const getters = {
    phoneMask(state) {
        return state.region.phone_code;
        // return `{+${state.region.phone_code}}-000-000-00-00`
    },
    googleMapsCountry(state) {
        return state.region_code.toLowerCase();
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}