var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"choose-mode-wrapper"},[_c('div',{staticClass:"choose-mode-backdrop",style:({
      background: _vm.isDarkMode ? 'black' : '#ededed',
    })}),_c('div',{staticClass:"cafe-page-container"},[(_vm.showMainBg)?_c('img',{staticClass:"cafe-logo-wrapper",style:({
        top: _vm.isBannerShown ? '13.4vh' : '7.7vh',
      }),attrs:{"src":_vm.cafeBackgroundImage}}):_vm._e(),_c('img',{staticClass:"cafe-logo-main",attrs:{"src":_vm.cafe.image ? _vm.cafe.image : '/icons/cafe-placeholder.jpg',"alt":((_vm.cafe.name) + " logo")}}),(_vm.cashbackPercent)?_c('div',{staticClass:"cafe-bonus"},[_vm._v("\n      "+_vm._s(_vm.cashbackPercent)+"%\n    ")]):_vm._e(),(_vm.cafe.rating.reviews && false)?_c('div',{directives:[{name:"thematic",rawName:"v-thematic"}],staticClass:"cafe-rating"},[_vm._v("\n      "+_vm._s(_vm.cafe.rating.reviews)+"\n      "),_c('img',{attrs:{"src":_vm.isDarkMode
            ? '/icons/hand-rating-white.svg'
            : '/icons/hand-rating.svg'}})]):_vm._e()]),_c('div',{directives:[{name:"thematic",rawName:"v-thematic"}],staticClass:"cafe-general-info"},[_c('div',{staticClass:"title"},[(false)?_c('img',{staticClass:"star",attrs:{"src":_vm.addedToFav
            ? '/icons/star-white-active.svg'
            : '/icons/star-white.svg'},on:{"click":function($event){_vm.addedToFav = !_vm.addedToFav}}}):_vm._e(),_c('h3',{directives:[{name:"thematic",rawName:"v-thematic"}]},[_vm._v(_vm._s(_vm.cafe.name))])]),(_vm.cafe.description)?_c('div',{directives:[{name:"thematic",rawName:"v-thematic"}],staticClass:"cafe-description",domProps:{"innerHTML":_vm._s(_vm.descriptionHtml)}}):_vm._e(),(_vm.cafe.has_preview)?_c('button',{directives:[{name:"thematic",rawName:"v-thematic"}],staticClass:"menu-action-btn w-100 transparent-btn",on:{"click":_vm.openMenuPreview}},[_vm._v("\n      "+_vm._s(_vm.$t("choose_mode.preview_menu"))+"\n      "),_c('span',[_c('img',{staticClass:"arrow",attrs:{"src":_vm.isDarkMode ? "/icons/arrow-black.svg" : "/icons/arrow-white.svg"}})])]):_vm._e()]),_c('PreorderMode',{attrs:{"modes":_vm.cafe.modes,"cafe_id":_vm.cafe._id,"cafe":_vm.cafe,"deliveryState":_vm.deliveryUiState,"minCheckCost":_vm.cafe.delivery.min_check},on:{"select":_vm.onSelect,"cancel":_vm.backToMarketplace}}),(_vm.hasValidSchedule)?_c('div',{directives:[{name:"thematic",rawName:"v-thematic"}],staticClass:"bottom-wrapper"},[_c('div',{directives:[{name:"thematic",rawName:"v-thematic"}],staticClass:"schedule-box"},[_c('h4',{directives:[{name:"thematic",rawName:"v-thematic"}]},[_vm._v(_vm._s(_vm.$t("schedule.title")))]),_c('div',{directives:[{name:"thematic",rawName:"v-thematic"}],staticClass:"schedule-days"},_vm._l((_vm.scheduleDays),function(day,index){return _c('div',{directives:[{name:"thematic",rawName:"v-thematic"}],key:index,staticClass:"day",class:_vm.selectedScheduleDay == index ? 'active' : '',on:{"click":function($event){_vm.selectedScheduleDay = index}}},[_vm._v("\n          "+_vm._s(_vm.$t("schedule.short." + day))+"\n        ")])}),0),_c('div',{directives:[{name:"thematic",rawName:"v-thematic"}],staticClass:"schedule-info",class:[
          _vm.selectedScheduleDay !== 0 ? 'borders-right' : '',
          _vm.selectedScheduleDay !== 6 ? 'borders-left' : '',
          _vm.selectedScheduleDay !== 0 && _vm.selectedScheduleDay !== 6
            ? 'full-borders'
            : '' ]},[_c('p',{directives:[{name:"thematic",rawName:"v-thematic"}],staticClass:"work-time"},[_vm._v(_vm._s(_vm.selectedDayText))]),(_vm.scheduleVerdict.workday && _vm.todayDay == _vm.selectedScheduleDay)?[(_vm.scheduleVerdict.is24hr)?_c('p',{staticClass:"till-close"},[_vm._v("\n            "+_vm._s(_vm.$t("marketplace.list.open_24hr"))+"\n          ")]):(_vm.scheduleVerdict.open)?_c('p',{staticClass:"till-close"},[_vm._v("\n            "+_vm._s(_vm.scheduleVerdict.time_left)+"\n            "+_vm._s(_vm.$t("marketplace.list.closes_in"))+"\n          ")]):_c('p',{staticClass:"till-close"},[_vm._v("\n            "+_vm._s(_vm.scheduleVerdict.time_left)+"\n            "+_vm._s(_vm.$t("marketplace.list.opens_in"))+"\n          ")])]:_vm._e()],2)]),_c('div',{staticClass:"feedback-container"},[(!_vm.feedbackWasSent)?_c('FeedbackButton',{staticClass:"feedback-btn",attrs:{"blackOnly":true},on:{"click":function($event){_vm.feedbackIsShown = true}}}):_vm._e(),_c('ContactIcons',{staticClass:"contact-icons-container",attrs:{"cafe":_vm.cafe}})],1)]):_vm._e(),(_vm.feedbackIsShown)?_c('CafeFeedback',{attrs:{"mode":'choose_mode',"order_id":("" + (_vm.cafe._id))},on:{"cancel":function($event){_vm.feedbackIsShown = false},"success":function($event){_vm.feedbackIsShown = false;
      _vm.feedbackWasSent = true;}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }