import Vue from 'vue'
import Router from 'vue-router'

// import NewOrder from '@/views/NewOrder.vue'
// import Preorder from '@/views/Preorder.vue'

import Snake from '@/components/SnakeGame.vue'
import DirectMenu from '@/views/DirectMenu.vue'

const NewOrder = () => import('./views/NewOrder.vue');
const Preorder = () => import('./views/Preorder.vue');
const MenuPreview = () => import('./views/MenuPreview.vue');
const MarketplaceMode = () => import('./views/MarketplaceMode.vue');
const ChooseMode = () => import('./views/ChooseMode.vue');
const Reserve = () => import('./views/Reserve.vue');
const Marketplace = () => import('./views/Marketplace.vue');
// const MarketplaceChainList = () => import('./views/MarketplaceChainList.vue');
const SyncFromDesktop = () => import('./views/SyncFromDesktop.vue');
const DownloadApp = () => import('./views/DownloadApp.vue');
const ActivateCafe = () => import('./views/ActivateCafe.vue');
const InsideLite = () => import('./views/InsideLite.vue');
const OffertView = () => import('./views/OffertView.vue');
const HotelRoom = () => import('./views/HotelRoom.vue');
const ChainCafes = () => import('./views/ChainCafes.vue');
const EventRedirect = () => import('./views/EventRedirect.vue');

import SuccessfullPayment from '@/views/SuccessfullPayment.vue'

Vue.use(Router)

export default new Router({
  mode: process.env.VUE_APP_TEST_BUILD ? "hash" : "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "marketplace_root",
      component: Marketplace,
    },
    {
      path: "/new/:code",
      name: "new_order_code",
      component: NewOrder,
    },
    {
      path: "/preorder/:code",
      name: "preorder",
      component: Preorder,
    },
    {
      path: "/delivery/:code",
      name: "delivery",
      component: Preorder,
    },
    {
      path: "/choose_mode/:code",
      name: "choose_mode",
      component: ChooseMode,
    },
    {
      path: "/marketplace",
      name: "marketplace",
      component: Marketplace,
    },
    {
      path: "/marketplace_mode",
      name: "marketplace_mode",
      component: MarketplaceMode,
    },
    // {
    //   path: '/marketplace/chainlist',
    //   name: 'marketplace',
    //   component: MarketplaceChainList
    // },
    {
      path: "/reserve/:code",
      name: "reserve",
      component: Reserve,
    },
    {
      path: "/snake",
      name: "snake",
      component: Snake,
    },
    {
      path: "/menu_preview/:id",
      name: "menu_preview",
      component: MenuPreview,
    },
    {
      path: "/from_desktop/:link/:id",
      name: "sync_from_desktop",
      component: SyncFromDesktop,
    },
    {
      path: "/order_link/:mode/:link/:id",
      name: "order_link",
      component: SyncFromDesktop,
    },
    {
      path: "/download",
      name: "download_app",
      component: DownloadApp,
    },
    {
      path: "/room/:code",
      name: "hotel_room",
      component: HotelRoom,
    },
    {
      path: "/activate_cafe/:code",
      name: "activate_cafe",
      component: ActivateCafe,
    },
    {
      path: "/direct_menu/:code",
      name: "direct_menu",
      component: DirectMenu,
    },
    {
      path: "/lite/:code",
      name: "inside_lite",
      component: InsideLite,
    },
    {
      path: "/chain/:chain_id",
      name: "chain",
      component: ChainCafes,
    },
    {
      path: "/successfull_payment",
      name: "successfull_payment",
      component: SuccessfullPayment,
    },
    {
      path: "/r/:key",
      name: "event_redirect",
      component: EventRedirect,
    },
    {
      path: "/offert_view/:cafe_id/:menu_id",
      name: "offert_view",
      component: OffertView,
    },
  ],
});
