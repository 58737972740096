import LocaleUk from "@/i18n/uk.js";
import LocaleRu from "@/i18n/ru.js";
import LocaleEn from "@/i18n/en.js";
import LocaleCs from "@/i18n/cs.js";
import LocaleTr from "@/i18n/tr.js"

const LS_KEY = "locale";

const LOCALE_CODES = ["en", "ru", "uk", "cs", 'tr'];

function getMessagesObject() {
  return {
    uk: LocaleUk,
    ru: LocaleRu,
    en: LocaleEn,
    cs: LocaleCs,
    tr: LocaleTr,
    rus: LocaleRu
  };
}

function detect() {
  const navLang = getAllLocales();

  let retVal = "uk";

  if (!navLang.includes("uk") && !navLang.includes("ua")) {
    retVal = "ru";

    if (!navLang.includes("ru")) {
      retVal = "en";
    }
  }

  LOCALE_CODES.forEach((l) => {
    if (navLang.includes(l)) {
      retVal = l;
    }
  });

  console.log(retVal);

  return retVal;
}

function getAllLocales() {
  let res = (navigator.languages || [])
    .map((s) => s.toLowerCase())
    .map((s) => s.split("-"))
    .flat()
    .slice();

  res.push((navigator.language || navigator.userLanguage).toLowerCase());

  return Array.from(new Set(res));
}

function isLocaleSaved() {
  return window.localStorage.getItem(LS_KEY) !== null;
}

function getSavedLocale() {
  return window.localStorage.getItem(LS_KEY);
}

function nextSavedLocale() {
  if (!isLocaleSaved()) {
    const auto = detect();

    window.localStorage.setItem(LS_KEY, auto);

    return auto;
  }

  let idx = LOCALE_CODES.indexOf(getSavedLocale());

  idx++;

  if (idx >= LOCALE_CODES.length) {
    idx = 0;
  }

  window.localStorage.setItem(LS_KEY, LOCALE_CODES[idx]);

  return LOCALE_CODES[idx];
}

function updateLocale(locale) {
  window.localStorage.setItem(LS_KEY, locale);
}

export default {
  detect,
  getMessagesObject,
  nextSavedLocale,
  getSavedLocale,
  isLocaleSaved,
  updateLocale,
  getAllLocales,
};
