const FPS = 60;
const UPDATE_TIME = 100;

const CELL_SIZE = 15;

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default class SnakeGame {
    constructor(canvas_el, width, height, callbacks) {
        this.canvas_el = canvas_el;

        this.width = width;
        this.height = height;

        this.canvas_el.width = this.width;
        this.canvas_el.height = this.height;

        this.rows = Math.floor(this.height / CELL_SIZE);
        this.cols = Math.floor(this.width / CELL_SIZE);

        this.ctx = this.canvas_el.getContext('2d');

        this.canvas_el.addEventListener('click', this.onClick.bind(this));

        this.records = [];

        this.addRecord('наш дизайнер', 5);
        this.addRecord('наш Frontend Engineer', 10);
        this.addRecord('наш CEO', 20);
        this.addRecord('наш Backend Engineer', 30);
        this.addRecord('Ілон Маск', 50);

        this.callbacks = callbacks;

        this.reset();
    }

    addRecord(name, score) {
        this.records.push({
            name,
            score
        });
    }

    onClick() {
        if (this.game_over) {
            this.reset();
        }
    }

    reset() {
        const start_x = getRandomInt(2, this.cols - 2);
        const start_y = getRandomInt(2, this.rows - 2);

        this.snake = {
            x: start_x,
            y: start_x,
            size: 3,
            body: [
                this.makeVector(start_x - 1, start_y),
                this.makeVector(start_x - 2, start_y),
                this.makeVector(start_x - 3, start_y),
            ],
            dirX: 1,
            dirY: 0
        }

        this.placeFood();

        this.game_over = false;
        this.score = 0;
        this.loadHighScore();
    }

    loadHighScore() {
        if (window.localStorage.getItem('snake_highscore') != null) {
            this.high_score = parseInt(window.localStorage.getItem('snake_highscore'));
        } else {
            this.high_score = 0;
        }
    }

    updateHighScore(value) {
        window.localStorage.setItem('snake_highscore', value);
        if (this.callbacks.onHighScore) {
            this.callbacks.onHighScore(value);
        } 
    }

    placeFood() {
        let food_x;
        let food_y;

        while (true) {
            food_x = getRandomInt(2, this.cols - 2);
            food_y = getRandomInt(2, this.rows - 2);

            let food_is_in_snake = false;

            for (let b = 0; b < this.snake.body.length; b++) {
                const part = this.snake.body[b];
                if (food_x == part.x && food_y == part.y) {
                    food_is_in_snake = true;
                }
            }

            if (!food_is_in_snake) break;
        }

        this.food = this.makeVector(food_x, food_y);
    }

    makeVector(x, y) {
        return {
            x,
            y
        }
    }

    start() {
        this.render_timer = setInterval(this.render.bind(this), 1000 / FPS);
        this.update_timer = setInterval(this.update.bind(this), UPDATE_TIME);
    }

    drawCell(x, y, color) {
        const draw_x = x * CELL_SIZE;
        const draw_y = y * CELL_SIZE;

        this.ctx.fillStyle = color || 'white';
        this.ctx.fillRect(draw_x, draw_y, CELL_SIZE, CELL_SIZE);
    }

    drawSnake() {
        this.snake.body.forEach(part => {
            this.drawCell(part.x, part.y, '#129B81');
        });
    }

    gameOver() {
        this.game_over = true;

        this.updateHighScore(this.score);

        this.game_over_text = '';

        this.records.forEach(record => {
            if (this.score > record.score) this.game_over_text = `Ви набрали більше очків, чим ${record.name}!`;
        });
    }

    update() {
        if (this.game_over) return;

        this.snake.x += this.snake.dirX;
        this.snake.y += this.snake.dirY;

        if (this.snake.x >= this.cols) {
            this.snake.x = 0;
        }

        if (this.snake.y >= this.rows) {
            this.snake.y = 0;
        }

        if (this.snake.x < 0) {
            this.snake.x = this.cols - 1;
        }

        if (this.snake.y < 0) {
            this.snake.y = this.rows - 1;
        }

        for (let i = 0; i < this.snake.body.length - 1; i++) {
            const part = this.snake.body[i];
            if (this.snake.x == part.x && this.snake.y == part.y) {
                this.gameOver();
            }
        }

        this.snake.body.push(this.makeVector(this.snake.x, this.snake.y));
        this.snake.body = this.snake.body.slice(1);

        if (this.snake.x == this.food.x && this.snake.y == this.food.y) {
            this.placeFood();
            this.snake.size++;
            this.score++;
            this.snake.body.push(this.makeVector(this.snake.x + this.snake.dirX, this.snake.y + this.snake.dirY));
        }
    }

    render() {
        this.ctx.fillStyle = '#1D1D1B';
        this.ctx.fillRect(0, 0, this.width, this.height);

        this.drawCell(this.food.x, this.food.y, 'red');

        this.drawSnake();

        this.ctx.strokeStyle = 'white';
        this.ctx.fillStyle = 'white';
        this.ctx.font = '30px Roboto';
        this.ctx.textAlign = 'center';

        if (this.game_over) {
            this.ctx.fillText('GAME OVER', this.width / 2, this.height / 2 - 50);

            if (this.game_over_text) {
                this.ctx.font = '12px Roboto';
                this.ctx.fillText(this.game_over_text, this.width / 2, this.height / 2 + 50);
            }


            this.ctx.font = 'bold 14px Roboto';
            this.ctx.fillText('Tap to TRY AGAIN', this.width / 2, this.height / 2 + 100);

        }

        const score_color = this.score > this.high_score ? 'yellow' : 'white';
        this.ctx.font = '30px Roboto';
        this.ctx.fillStyle = score_color;
        this.ctx.strokeStyle = score_color;
        this.ctx.fillText(`${this.score}`, this.width / 2, this.height / 2);
    }

    input(dirX, dirY) {
        this.snake.dirX = dirX;
        this.snake.dirY = dirY;
    }

    stop() {
        clearInterval(this.render_timer);
        clearInterval(this.update_timer);
    }
}
