<template>
  <div class="successfull-payment-wrapper">
      <img src="/hero-pics/eat.svg" class="panda">
      <h3 class="payment-success">
        {{ $t('successfull_payment.title') }}
      </h3>
      <p>
        {{ $t('successfull_payment.subtitle') }}
      </p>
      <!-- <LongButton class="close-button" variant="danger" @click="close">
       {{ $t('successfull_payment.button') }}
      </LongButton> -->
  </div>
</template>

<script>
export default {
  methods: {
    close() {
      window.close();
    } 
  }
}
</script>

<style scoped>
.successfull-payment-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    background: #1d1d1b;
    
    padding-top: 30vh;

    /* display: flex; */
    justify-content: center;
    text-align: center;
    align-items: center;
}

.panda {
  width: 20vh;
  height: auto;
}

.payment-success {
  color: #129b81;
  margin-top: 1.65vh;
}

.close-button {
  margin-top: 1.9vh;
}

p {
  padding: 0 2vh;
  color: #ededed;
}
</style>