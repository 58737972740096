import BaseAPI from '../base'

function prepare(data) {
    // if (is_preorder) {
    //     return BaseAPI.sendPost(`preorder/prepare`, {
    //         link: data
    //     });
    // } else {
    //     return BaseAPI.sendPost(`order/prepare`, {
    //         code: data
    //     });
    // }
    return BaseAPI.sendPost(`order/prepare`, {
        code: data
    });
}

function createOrder(data) {
    // return BaseAPI.sendPost(`order/create`, {
    //     target,
    //     cart,
    //     geolocation,
    //     comment,
    //     client_id,
    //     request_id
    // });
    return BaseAPI.sendPost(`order/create`, data);
}

function sendAppend(order_id, cart, geolocation, comment) {
    return BaseAPI.sendPost(`order/append`, {
        order_id,
        cart,
        geolocation,
        comment
    });
}

function callService(target, service, geolocation) {
    return BaseAPI.sendPost(`order/call`, {
        target,
        service,
        geolocation
    });
}

function clearActiveOrder(cafe_id, section, table) {
    return BaseAPI.sendPost(`order/clearActiveOrder`, {
        cafe_id,
        section,
        table
    });
}

function prepareInsideLite(code) {
    return BaseAPI.sendPost(`order/prepare/inside_lite`, {
        code
    })
}

function sync(id) {
    return BaseAPI.sendPost(`order/sync/${id}`);
}

function requestPayment(id, pay_method, last_tip) {
    return BaseAPI.sendPost(`order/requestPayment/${id}`, {
        pay_method,
        last_tip
    });
}

export default {
  prepare,
  createOrder,
  sendAppend,
  callService,
  clearActiveOrder,
  prepareInsideLite,
  requestPayment,
  sync,
};