let scroll_positions = {};

function get_page_scroll() {
    return window.document.documentElement.scrollTop;
}

function set_page_scroll(val) {
    window.document.documentElement.scrollTop = val;
}

export default {
    install(Vue, options) {
        Vue.prototype.$saveScroll = (id) => {
            scroll_positions[id] = get_page_scroll();
        }

        Vue.prototype.$restoreScroll = (id) => {
            Vue.nextTick(() => {
                set_page_scroll(scroll_positions[id]);
            });
        }
    }
}