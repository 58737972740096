import axios from 'axios'

const baseInstance = axios.create({
    baseURL: '/api/',
    timeout: 10000
});

function sendGet(method, params) {
    return new Promise((resolve, reject) => {
        baseInstance.get(method, {
            params
        }).then(response => {
            if (!response.data.success) {
                reject(response.data.error);
                return;
            }

            resolve(response.data);
        }).catch(error => {
            reject('request_failed');
        });
    });
}

function sendPost(method, data) {
    return new Promise((resolve, reject) => {
        baseInstance.post(method, data).then(response => {
            if (!response.data.success) {
                reject(response.data.error);
                return;
            }

            resolve(response.data);
        }).catch(error => {
            reject('request_failed');
        });
    });
}

export default {
    sendGet,
    sendPost
}