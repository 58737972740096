<template>
  <div class="contact-info">
    <a
      :href="`tel:${cafe.contact_phone}`"
      target="_blank"
      @click="onUsed('phone', cafe.contact_phone)"
      v-if="cafe.contact_phone"
    >
      <img :src="`/icons/navbar/phone${themeSuffix}.svg`" />
    </a>
    <a :href="cafe.social.website" target="_blank" v-if="cafe.social.website">
       <img :src="`/icons/navbar/website${themeSuffix}.svg`" />
    </a>
    <a
      :href="cafe.social.instagram"
      target="_blank"
      v-if="cafe.social.instagram"
    >
      <img :src="`/icons/navbar/inst${themeSuffix}.svg`" />
    </a>
    <a :href="cafe.social.facebook" target="_blank" v-if="cafe.social.facebook">
      <img :src="`/icons/navbar/fb${themeSuffix}.svg`" />
    </a>
    <a
      :href="cafe.address_url_override || `https://maps.google.com/?q=${cafe.address}`"
      target="_blank"
      v-if="cafe.address"
    >
      <img :src="`/icons/navbar/map${themeSuffix}.svg`" />
    </a>
  </div>
</template>

<script>
export default {
  props: {
    cafe: Object,
  },
  data() {
    return {
      themeSuffix: this.isDarkMode || !this.isMobile ? "-transparent" : "-white",
    };
  },
  methods: {
    onUsed(a,b) {
      console.log(a,b);
    }
  },
  mounted() {
    this.themeSuffix = !this.isDarkMode ? "-transparent" : "-white";
  }
};
</script>

<style scoped>
.contact-info {
  width: 90%;
  display: flex;
  justify-content: space-around;
  margin: 3.7vh auto 0;
}

.contact-info img {
  height: 5vh;
  width: auto;
}
</style>