<template>
  <div class="rotate-screen-container">
    <div class="hide-content"></div>
    <div class="rotate-screen-modal">
      <img src="../../assets/rotate-screen.png" class="rotate" />
      <h4 class="title">
        {{ $t('rotate_device') }}
      </h4>
      <img src="/hero-pics/sad.svg" class="hero" />
    </div>
  </div>
</template>

<style scoped>
.hide-content {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4500;
  height: 100vh;
  width: 100vw;
  backdrop-filter: blur(15px);
}

.rotate-screen-modal {
  z-index: 5000;
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  background-color: #1d1d1d;
  border-radius: 2.5vh;
  width: 90vh;
  padding: 2.5vh;
  margin: 0 auto;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
img.rotate {
  height: 8.8vh;
  width: auto;
}
h4.title {
  color: #908f8d;
  padding-top: 1.2vh;
  width: 50%;
  margin: 0 auto;
  font-size: 3.2vh;
}

img.hero {
  margin-top: 2vh;
  height: 33vh;
  width: 100%;
}

.rotate-screen-container {
  display: none;
}

@media screen and (min-aspect-ratio: 13/9) {
  .rotate-screen-container {
    display: block;
  }
  
  body, html {
    overflow: hidden !important;
  }
}
</style>

<script>
export default {
  data() {
    return {
      is_rotated: false
    }
  },
  watch: {
    is_rotated() {
      this.toggleGlobalScrolling(this.is_rotated);
    }
  },
  mounted() {
    window.addEventListener("orientationchange", ev => {
      const value = event.target.screen.orientation.angle;

      this.is_rotated = value != 0;
    });
  }
}
</script>