const CART_CACHE_KEY = 'cart';

const MAX_CART_TTL = 8 * 60 * 60 * 1000;

function updateCartCache(type, cafe_id, cart) {
    window.localStorage.setItem(`cart_${type}`, JSON.stringify({
        cafe_id,
        cart,
        timestamp: Date.now()
    }));
}

function getCartCache(type, cafe_id) {
    const obj = window.localStorage.getItem(`cart_${type}`);

    if (obj === null) {
        return [];
    } else {
        const contents = JSON.parse(obj);

        const diff = Date.now() - (contents.timestamp || 0); 

        if (`${contents.cafe_id}` == `${cafe_id}` && diff < MAX_CART_TTL) {
            return contents.cart;
        } else {
            window.localStorage.removeItem(`cart_${type}`);
            return [];
        }
    }
}

function clearCartCache(type) {
    window.localStorage.removeItem(`cart_${type}`);
}

export default {
    updateCartCache,
    getCartCache,
    clearCartCache
}