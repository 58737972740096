<template>
    <button :class="buttonClass" :disabled="disabled" @click="emitClick">
        <slot></slot>
    </button>
</template>

<style scoped>
.generic-button {
    width: 80vw;
    padding: 1.6vh 2vw;
    margin: 3.7vh auto 0;
    display: block;
    border: 0px;
    background:#129B81;
    box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.25);
    border-radius: 5vh;
    font-size: 2.2vh;
    font-weight: 500;
    outline:none;
}

.rect-borders {
    border-radius: 1vh;
    width: 90%;
    padding: 1.9vh 0;
    box-shadow: none;
}

.variant-primary {
    color: #ededed;
    background-color: #129B81;
}

.variant-danger {
    color: #ededed;
    background: #E2223B;
}

.variant-success {
    color: #ededed;
    background-color: #129B81;
}

.variant-yellow {
    background-color: #FFD2A8;
}

.variant-blue {
    background-color: #cbd9e6;
}

.variant-cyan {
    background-color: #aeccc6;
    color: #1d1d1b;
}

.variant-dark {
    background-color: #1d1d1b;
    color: #ededed;
}

.variant-red-outline {
    color: #E2223B;
    box-shadow: none;
    border: 2px solid #E2223B;
    background: transparent;
}

.variant-white-outline {
    color: #ededed;
    box-shadow: none;
    border: 2px solid #ededed;
    background: transparent;
}


.variant-success-outline {
    color: #129B81;
    background-color: transparent;
    border: 2px solid #129B81;
    box-shadow: none;
    border-radius: 10px;
    padding: 10px 20px;
}
</style>

<script>
export default {
    props: {
        variant: {
            type: String,
            default: 'primary'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        emitClick() {
            this.$emit('click')
        }
    },
    computed: {
        buttonClass() {
            return [
                'generic-button',
                `variant-${this.variant}`
            ];
        }
    }
}
</script>