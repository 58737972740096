<template>
  <div>
    <div class="hide-content"></div>
    <div class="schedule color-white">
      <!-- <span class="close" @click="emitClose">x</span> -->
      <img src="/icons/del-white.png" class="close" @click="emitClose" />

      <h4>{{ $t('schedule.title') }}</h4>
      <div class="schedule-time">
        <div class="days">
          <ul v-for="s,k in schedule" :class="{'today': isToday(k)}">
            <li>{{ $t(`schedule.${k}`) }}</li>
          </ul>
        </div>
        <div class="time">
          <ul>
            <li v-for="s,k in schedule" :class="{'today': isToday(k)}">
                <span v-if="s.workday">{{ formatDayString(s) }}</span>
                <span v-if="!s.workday" class="dayoff">
                    <a href="https://www.youtube.com/watch?v=PDaPqQd1Mns">
                        {{ $t('schedule.free_day') }}
                    </a>
                </span>
            </li>
          </ul>
        </div>
      </div>
      <p>
          {{today}}
      </p>
    </div>
  </div>
</template>


<style scoped>
.working-time {
  opacity: 0.8;
  color: black;
  font-size: 16px;
}

.dayoff a,
.close-time {
  color: #ff4444;
}

.schedule-time {
  display: flex;
  justify-content: space-between;
  padding: 10px 25px;
  line-height: 30px;
}

.schedule p {
  padding-top: 10px;
  color: #878787;
  margin: 0;
  font-weight: 600;
}

.schedule small {
  color: #878787;
}

.days {
  text-align: left;
}

.time {
  letter-spacing: 3px;
  font-weight: 500;
  text-align: right;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.descr {
  font-weight: 600;
  color: black;
  font-size: 17px;
  opacity: 0.4;
}

h5 {
  font-size: 18px;
  font-weight: bold;
}

.close {
  width: auto;
  height: 4.2vh;
  opacity: 1;
  right: 5.5vw;
  position: absolute;
  /* box-shadow: 2px 2px 8px lightgray; */
}

.hide-content {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2;
  overflow: hidden;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
  top: 0;
  left: 0;
}

.schedule {
  background: #1d1d1b;
  border-radius: 5vw;
  display: flex;
  padding: 3vh 5.3vw 4.5vh;
  flex-flow: column nowrap;
  margin: 0 auto;
  transition: 0.3s;
  overflow-y: auto;
  max-height: 90vh;

  z-index: 10;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);

  width: 90vw;

  text-align: center;
}

.schedule h4 {
  color: #545454;
  font-weight: 500;
  margin-top: 3px;
  font-size: 20px;
}

.today {
    font-weight: bold;
}
</style>

<script>
import moment from 'moment'

export default {
    props: {
        schedule: Object
    },
    methods: {
        emitClose() {
            this.$emit('close')
        },
        formatDayString(day) {
            const sh = day.startHour;
            const sm = `${day.startMinute}`.padStart(2, '0');
            const eh = day.endHour;
            const em = `${day.endMinute}`.padStart(2, '0');

            return `${sh}:${sm} - ${eh}:${em}`;
        },
        isToday(dk) {
            return Object.keys(this.schedule)[moment().isoWeekday()-1] == dk;
        },
    },
    computed: {
        today() {
            const fmt = moment().format('dddd, D MMMM'); 
            return fmt.charAt(0).toUpperCase() + fmt.slice(1);
        }
    }
}
</script>