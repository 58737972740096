import moment from 'moment'

import mdfs from 'moment-duration-format'

const dayNames = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

class ScheduleHelper {
    constructor(schedule, server_time) {
        if (!schedule) {
            this.startMoment = moment().startOf('day');
            this.endMoment = moment().endOf('day');
            return;
        }
        this.server_time = server_time || Date.now();
        this.schedule = schedule;

        this.loadItem();
        this.makeMoments();

        console.log(server_time);
    }

    loadItem() {
        const today = moment(this.server_time);

        const day = today.day();

        const key = dayNames[day];

        this.item = this.schedule[key];
    }

    makeMoments() {
        const startHour = this.item.startHour;
        const startMinute = this.item.startMinute;

        const endHour = this.item.endHour;
        const endMinute = this.item.endMinute;

        this.startMoment = moment(this.server_time).minutes(startMinute).hours(startHour);
        this.endMoment = moment(this.server_time).minutes(endMinute).hours(endHour);

        if (this.startMoment.isAfter(this.endMoment)) {
            this.endMoment = this.endMoment.add(1, 'days');
        }

        if (this.isOpen()) return;

        const prev_moment = moment(this.server_time).subtract(1, 'days');
        const prev_day = prev_moment.day();

        const prev_schedule_item = this.schedule[dayNames[prev_day]];

        if (!prev_schedule_item || !prev_schedule_item.workday) {
            return;
        }

        let prev_start = moment(this.server_time)
            .minutes(prev_schedule_item.startMinute)
            .hours(prev_schedule_item.startHour);

        let prev_end = moment(this.server_time)
            .minutes(prev_schedule_item.endMinute)
            .hours(prev_schedule_item.endHour);

        if (!prev_start.isAfter(prev_end)) {
            return;
        }

        console.log(prev_start, prev_end);

        if (moment(this.server_time).isBefore(prev_end)) {
            this.startMoment = moment(this.server_time).minutes(startMinute).hours(startHour).subtract(1, 'days');
            this.endMoment = prev_end;
        }
    }

    format() {
        if (!this.item) {
            return null;
        }

        if (!this.isOpen()) {
            return `${this.startMoment.format('HH:mm')}`;
        }

        return `${this.startMoment.format('HH:mm')} - ${this.endMoment.format('HH:mm')}`;
    }

    isWorkday() {
        return this.item.workday;
    }

    isOpen() {
        if (!this.item.workday) return false;
        return moment(this.server_time).isBetween(this.startMoment, this.endMoment);
    }

    isOpenTimeLimit(limit) {
        if (!this.item.workday) return false;
        if (this.item.startHour == 0 && this.item.endHour == 24) {
            return true;
        }
        const start_point = this.startMoment;
        const end_point = this.endMoment.subtract(limit, 'minutes');
        return moment(this.server_time).isBetween(start_point, end_point);
    }

    remainingTime(unitHour, unitMinute) {
        const diff = moment.duration(this.endMoment.diff(moment()));
        const hrs = `${diff.hours()}`.padStart(2, '0');
        const mins = `${diff.minutes()}`.padStart(2, '0');

        return `${hrs}:${mins}`;
    }

    isClosingSoon() {
        const diff = this.endMoment.diff(moment(this.server_time), 'minutes');

        return diff <= 65;
    }

    remainingTimeHours() {
        return Math.round(moment(this.endMoment).diff(
            moment(),
            'hours',
            true
        ) * 10) / 10;
    }

    hoursTillOpen() {
        const diff = moment.duration(this.startMoment.diff(moment()));
        const hrs = `${Math.abs(diff.hours())}`.padStart(2, '0');
        const mins = `${Math.abs(diff.minutes())}`.padStart(2, '0');

        return `${hrs}:${mins}`;

        return Math.abs(Math.round(moment(this.startMoment).diff(
            moment(),
            'hours',
            true
        ) * 10) / 10);
    }

    isOpenAllDay() {
        return moment(this.endMoment).diff(this.startMoment, 'hours', true) >= 23.9;
    }
}

export default {
    ScheduleHelper
}
