<template>
  <div class="download-app" v-if="isRouteForBanner">
    <div class="wrapper">
      <div class="d-flex">
        <img src="/icons/app-logo.svg" class="app" />
        <div class="app-info">
          <h5>QRWaiter</h5>
          <small>{{ $t("banner.title") }}</small>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <button @click="downloadApp" class="get-app">{{ $t("banner.get_app") }}</button>
        <img src="/icons/close.svg" class="close" @click="closeBanner()" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    '$route.name'() {
      if (!this.isRouteForBanner) this.closeBanner();
    }
  },
  methods: {
    closeBanner() {
      this.$emit("close-banner");
    },
    downloadApp() {
        const user_agent = navigator.userAgent || navigator.vendor;

        if (/iPhone/i.test(user_agent)) {
            window.location.href = 'https://apps.apple.com/ua/app/qrdelivery/id1507792224?l=uk';
            return
        }

        if (/Android/i.test(user_agent)) {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.webqrdelivery.qrdelivery';
            return
        }
    }
  },
  computed: {
    isRouteForBanner() {
      return [
        'marketplace',
        'marketplace_root',
        'choose_mode'
      ].includes(this.$route.name);
    }
  }
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.download-app {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  padding: 1vh 1.8vh;
  background: #000;
}

.app-info {
  display: grid;
  width: auto;
  padding: 0 0.8vh;
  align-self: center;
}

.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

h5 {
  color: #fff;
  font-size: 1.8vh;
}

small {
  font-size: 1.4vh;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  line-height: normal;
  color: #908f8d;
  overflow: hidden;
}

.get-app {
  background: transparent;
  border: 1px solid #ededed;
  color: #ededed;
  font-size: 1.7vh;
  margin-right: 1.8vh;
  padding: 0.5vh 1vh;
  border-radius: 0.8vh;
}

img.app {
  height: 100%;
  width: auto;
}

img.close {
  height: 2vh;
  width: auto;
  display: block;
}
</style>
