import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { 
  FormRadioPlugin,
  FormCheckboxPlugin,
  FormGroupPlugin
} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'moment-timezone'
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faMinus, faPlus, faTimes, faArrowLeft, faBars, faSpinner, faCartArrowDown, faClock } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import ThemeDirective from '@/services/theme/directive.js'
import ThemeMixin from '@/services/theme/mixin.js'

import LongButton from '@/components/LongButton.vue'

import VueI18n from 'vue-i18n'

import PolygloteService from '@/services/polyglote'

import store from '@/store'

import ScrollSaver from '@/services/scrollsaver'

import moment from 'moment'

// library.add(faMinus, faPlus, faTimes, faArrowLeft, faBars, faSpinner, faCartArrowDown, faClock)
 
// Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('LongButton', LongButton);

const defaultImpl = VueI18n.prototype.getChoiceIndex

/**
 * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
 * @param choicesLength {number} an overall amount of available choices
 * @returns a final choice index to select plural word by
**/
VueI18n.prototype.getChoiceIndex = function (choice, choicesLength) {
  // this === VueI18n instance, so the locale property also exists here
  if (this.locale !== 'uk' && this.locale !== 'ru') {
    // proceed to the default implementation
    return defaultImpl.apply(this, arguments)
  }

  if (choice === 0) {
    return 0;
  }

  const teen = choice > 10 && choice < 20;
  const endsWithOne = choice % 10 === 1;

  if (!teen && endsWithOne) {
    return 1;
  }

  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2;
  }

  return (choicesLength < 4) ? 2 : 3;
}

Vue.use(FormRadioPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormGroupPlugin);

Vue.use(VueI18n);

Vue.use(ScrollSaver);

Vue.directive('thematic', ThemeDirective);

Vue.config.productionTip = false

const i18n = new VueI18n({
  locale: 'uk',
  messages: PolygloteService.getMessagesObject()
});

function preventDefault(e) {
  e.preventDefault();
}

Vue.mixin({
  methods: {
    resetScroll() {
      window.document.documentElement.scrollTop = 0;
    },
    toggleGlobalScrolling(disable, dont_reset_scroll) {
      dont_reset_scroll = dont_reset_scroll || false;
      if (disable) {
        document.body.addEventListener('touchmove', preventDefault, {
          passive : false
        });
        if (!dont_reset_scroll) document.documentElement.scrollTop = 0;
      } else {
        document.body.removeEventListener('touchmove', preventDefault);
      }

      document.body.style.overflowY = disable ? "hidden" : "";
      document.body.style.height = disable ? "auto" : "";
    },
  }
});

Vue.mixin({
  data() {
    return {
      isMobile: window.innerWidth <= 1020 || /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    }
  },
  methods: {
    onGlobalWindowResize() {
      this.isMobile = window.innerWidth <= 1020 || /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
  },
  created() {
    window.addEventListener('resize', this.onGlobalWindowResize);
    this.onGlobalWindowResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onGlobalWindowResize);
  }
});

Vue.mixin(ThemeMixin);

Vue.filter('formatTime', (raw) => {
  if (!raw) return '';

  return moment(raw).format('HH:mm');
});

Vue.filter('formatDate', (raw) => {
  if (!raw) return '';

  return moment(raw).format('DD.MM.YY');
});

Vue.filter('formatMoney', (raw) => {
  if (!raw) return 0;

  return `${Math.round(raw * 100) / 100}`
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
