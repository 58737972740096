const LS_KEY = 'marketplace_flag';

function get() {
    const raw = window.localStorage.getItem(LS_KEY);

    if (!raw) return null;

    return JSON.parse(raw); 
}

function isValid(cafe_link) {
    const item = get();

    return !!item && item.cafe_link == cafe_link;
}

function set(cafe_link, flag) {
    window.localStorage.setItem(LS_KEY, JSON.stringify({
        cafe_link,
        flag
    }));
}

function clear() {
    window.localStorage.removeItem(LS_KEY);
}

export default {
    get,
    isValid,
    set,
    clear
}