import Vue from 'vue'

import CartCache from '@/services/cart'

const state = {
    items: [],

    type: 'inside',
    cafe_id: null
};

const mutations = {
    setItems(state, payload) {
        state.items = payload;
    },
    setTarget(state, payload) {
        state.type = payload.type;
        state.cafe_id = payload.cafe_id;
    },
    addItem(state, payload) {
        const possible_index = state.items.findIndex(item => {
            if (item.name != payload.name) return false;
            if (item.category != payload.category) return false;
            if (item.modificators.length != payload.modificators.length) return false;

            if (item.price != payload.price) return false;
            if (item.variants && item.variants.map(v => v.name).join() != payload.variants.map(v => v.name).join()) return false;
            return true;
        });

        if (possible_index !== -1) {
            if (state.items[possible_index].quantity+payload.quantity < (state.items[possible_index].max_count || 30)) {
                state.items[possible_index].quantity += payload.quantity;
                if (payload.total_weight) {
                    state.items[possible_index].total_weight = Math.round((state.items[possible_index].total_weight + payload.total_weight) * 100) / 100;
                }
            }
        } else {
            state.items.push(payload);
        }
    },
    changeQuantity(state, payload) {
        const old_quantity = state.items[payload.index].quantity;

        Vue.set(state.items[payload.index], 'quantity', old_quantity + payload.delta);
    },
    changeTotalWeight(state, payload) {
        Vue.set(state.items[payload.index], `total_weight`, payload.total_weight);
    },
    removeItem(state, index) {
        state.items.splice(index, 1);
    }
};

const actions = {
    init(context, payload) {
        const type = payload.type;
        const cafe_id = payload.cafe_id;

        context.commit('setTarget', {
            type,
            cafe_id
        })
        context.commit('setItems', CartCache.getCartCache(type, cafe_id));
    },
    addItem(context, payload) {
        context.commit('addItem', payload);
        context.dispatch('updateCache');
    },
    updateCache(context) {
        CartCache.updateCartCache(
            context.state.type,
            context.state.cafe_id,
            context.state.items
        );
    },
    changeQuantity(context, payload) {
        const item = context.state.items[payload.index];

        if (!item) return;

        const new_quantity = item.quantity + payload.delta;

        if (new_quantity == 0) {
            context.commit('removeItem', payload.index);
            context.dispatch('updateCache');
            return;
        }

        const max_count = !isNaN(item.max_count) ? Math.min(
            item.max_count,
            30
        ) : 30;

        if (new_quantity <= 0 || new_quantity > max_count) return;

        if (item.weight_flag != 0) {
            context.commit('changeTotalWeight', {
                index: payload.index,
                total_weight: item.weight * new_quantity
            });
        }

        context.commit('changeQuantity', payload);
        context.dispatch('updateCache');
    },
    clear(context) {
        context.commit('setItems', []);
        CartCache.clearCartCache(context.state.type);
    }
};

const getters = {
    total(state) {
        let cart_total = 0;
        state.items.forEach(item => {
            let item_price = item.price;

            if (item.modificators && item.modificators.length) {
                item_price += item.modificators.reduce((acc,mod) => acc + mod.price, 0);
            }

            cart_total += item_price * item.quantity;
        });
        return Math.round(cart_total * 100) / 100;
    },
    hasExciseItem(state) {
        return !!state.items.find(item => item.is_excise);
    },
    getItemCount(state) {
        return (id) => {
            const entry = state.items.find(it => it.product_id == id);

            if (!entry) return 0;

            return entry.quantity;
        }
    },
    hasMetaItem(state) {
        return (type) => {
            return !!state.items.find(it => it.metatype == type);
        }
    }
    // isInCart(state) {
    //     return (target_item) => {
    //         const cart_entry = state.items.find(cart_item => {
    //             if (target_item.name != cart_item.name) return false;

    //             if (target_item.category != cart_item.category) return false;

    //             if (target_item.variant != )
    //         });
    //     };
    // }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}