const RU_LOCALE = {
  errors: {
    access_denied: `В доступе отказано.`,
    invalid_data: `Неверный формат данных`,
    db_query_error: `Ошибка чтения базы даных.`,
    db_write_error: `Ошибка записи в базу данных.`,
    not_found: `Запрашиваемый объект не найден.`,
    cafe_not_ready: `Заведение не настроено для приема заказов.`,
    request_failed: `Слабое интернет соединение.`,
    not_available: "Эта операция сейчас недоступна.",
    invalid_order_total: "Неверная сумма заказа",

    cafe_not_paid: "Система QRWaiter не оплачена",

    retry: "Перезагрузите страницу и попробуйте еще раз",
    tech_problem:
      "К сожалению, по техническим причинам Ваш заказ не может быть принят.",
  },
  // error_modal: {
  //     text: 'К сожалению, во время отправки заказа произошла ошибка. Пожалуйста, попробуйте обновить страницу, выключить/включить геолокацию и отправить заказ позже.'
  // },
  banner: {
    title: "Приложение для заказа еды",
    get_app: "СКАЧАТЬ",
  },
  greeting: {
    make_order: "Сделать заказ",
    table: "Стол",
    until_close_left: "до закрытия",
    closed: "Закрыто :(",
    not_paid: "Заведение не оплатило услуги QRWaiter :(",
    free_day: "Выходной день.",

    introduce_text: "Ваши персональные данные",
    introduce_input_name: "Как к Вам обращаться?",
    introduce_input_phone: "Ваш номер телефона",
    skip: "Пропустить",
    go: "Вперед!",

    preorder: "ПРЕДЗАКАЗ",

    choose_language: "Выберите язык:",
    tutorial: "Впервые у нас? Узнайте, как сделать заказ :)",
  },
  address: {
    correct_title: "Пожалуйста, исправте адрес",
    correct_example: "Пример: ул. Шевченка, 1, Киев",
    select_google:
      "Адрес нужно выбрать в выпадающем списке из предложенных вариантов.",
  },
  error_modal: {
    title: "Код ошибки:",
    text: "Упс, что-то пошло не так...",
    solve: "Обновите страницу<br> и попробуйте еще раз",
    change_address: "Пожалуйста, смените адрес доставки",
  },
  active_order: {
    check: "Проверьте, похоже Вы уже что-то заказали ",
    modal_text:
      'Если это Ваш заказ - нажмите "Принять" чтобы добавить его в чек',
    accept: "Принять",
    cancel: "Отменить",
  },
  schedule: {
    title: "График работы",
    mon: "Понедельник",
    tue: "Вторник",
    wed: "Среда",
    thu: "Четверг",
    fri: "Пятница",
    sat: "Суботта",
    sun: "Воскресенье",

    short: {
      mon: "Пн",
      tue: "Вт",
      wed: "Ср",
      thu: "Чт",
      fri: "Пт",
      sat: "Сб",
      sun: "Вс",
    },

    free_day: "Выходной",
  },
  navbar: {
    services: "Услуги",
    table_no: "Стол",
    room_no: "Номер",
    offert: "ПУБЛИЧНАЯ ОФЕРТА",
  },
  menu: {
    show_more: "показать больше...",
    show_less: "показать меньше...",

    choose_variant: "Выберите вариант:",
    choose_modificators: "Выберите дополнения",
    add_to_cart: "+ Добавить в корзину",
    added_to_cart: "Добавлено в корзину",
    recommending: "QRWaiter рекомендует",

    menu_closed_after: "К сожалению, это меню работает только с  ",
    menu_closed_before: "К сожалению, это меню работает только до ",

    out_of_stock: "К сожалению, этого блюда больше нет в наличии",

    show_all: "показать все",
    exclusives: "Рекомендации",

    weight: "Вес:",
    where_to_start: "С чего начать?",

    price_per_unit: "Цена за 1 {unit}:",
  },
  switcher: {
    primary: "Основное меню",
    tags: {
      bar: "Бар меню",
    },
  },
  cart: {
    empty: "Ваша корзина пуста.",
    comment: "Комментарий:",
    comment_desc: "Пожелания / просьбы для официанта",
    send: "Отправить заказ",
    next: "Далее >",
    min_check: "Сумма Вашего заказа должна быть как минимум:",
    wait: "Обработка...",
    cashback: "Бонус от этого заказа составит {cashback}",
    empty_desktop:
      "К сожалению, Ваша корзина пустая. Но не огорчайтесь, Вы всегда можете добавить товар.",
  },
  not_paid_modal: {
    title: "Заведение не оплатило услуги",
    text:
      "К сожалению, это заведение не оплатило услуги QRWaiter, и поэтому отправка заказов в этом заведении временно заблокирована.",
  },
  card: {
    status_cart: "Вы можете редактировать",
    status_waiting: "Отправлено заведению",
    status_serving: "Был принят {mins} мин. назад",
    status_served: "Подано.",
    status_serving_recent: "Был принят 1 мин. назад.",
    status_cancelled: "Отменено.",
    status_unpaid: "Неоплачено.",
    status_paying: "Оплата...",
    status_executed: "Оплачено",
    order: "Заказ",
    check: "Чек",
    waiting_time: "Время ожидания:",
    approve_code: "Введите код подтверждения <br> <span>с СМС</span>",
    append: "Дозаказ",
    waiting_time_long: "более 20 минут",
    dont_close: "Не закрывайте страницу, пока официант не приймет Ваш заказ.",
    play_game: "А пока можете поиграть в змейку, нажав на нее ниже.",
    bonus_balance: "Баланс бонусного счета: {balance} грн.",
    download_app: "Загрузить приложение",
    order_else: `Заказать еще`,
    self_service: "Пожалуйста, заберите Ваш заказ.",
    cancel_reason: "Причина отказа:",
  },
  actions: {
    cancel: "Отменить заказ",
    pay: "Оплатить",
    cancel_2: "Отменить заказ",
  },
  pay: {
    support_text:
      "Если Вы довольны скоростью обслуживания и сервисом, можете оставить чаевые и поддержать заведение :)",
    tip_amount: "от Вашего чека",
    tip_info:
      "Чаевые выбраны автоматически, так как персонал быстро отреагировал на ваш заказ.",
    choose_pay_method: "Выберите способ оплаты",
    method_bonus: "Бонусный счет QRWaiter",
    method_cash: "Оплатить наличными",
    method_card: "Оплатить используя терминал",
    method_liqpay: "Оплатить онлайн",
    method_bonus: "Оплатить бонусным счетом",
    choose_tip_summ: "Укажите сумму чаевых",
    tip_add_to_cheque: "Добавить к чеку",
    separate: "Оплатить отдельно",
  },
  cancel: {
    confirm_text: "Вы действительно хотите отменить свой заказ?",
    select_reason: "Укажите причину:",
    reasons: {
      wrong_order: "Заказал(а) случайно",
      no_time: "Нет времени ждать",
      custom: "Другая причина",
    },

    enter_reason: "Напишите причину",

    button_continue: "Продолжить",
    button_cancel: "Отменить",
  },
  geo: {
    title: "Нам нужно проверить действительно ли Вы находитесь в заведении",
    title_hotel:
      "Нам нужно проверить действительно ли Вы находитесь на територии отеля",
    please: "Пожалуйста предоставьте доступ к Вашей геолокации",
    just_click: "Просто нажмите эту кнопку :)",
    reload: "Обновить страницу",
    fail_title:
      "Для того чтоб сделать заказ Вы должны предоставить доступ к геолокации, чтобы система поняла, что Вы находитесь в заведении.",
    fail_subtitle:
      "Для предоставления доступа перейдите в Настройки -> Конфиденциальность -> Разрешение на геолокацию",

    fail_titles: {
      browser: "Ви не дали доступ<br> к своей геолокации.",
      out_of_range: "Вы находитесь<br>вне зоны доставки.",
      not_in_cafe: "Вы находиетсь<br>вне зоны заведения.",
    },
    fail_subtitles: {
      browser: "Обновите страницу и попробуйте снова.",
      out_of_range: "Доставка к Вашей локации недоступна.",
      not_in_cafe: "Заказ можно сделать только находясь в заведении.",
    },
    not_in_cafe: "Я не нахожусь в заведении",
  },
  deleted_dish: {
    sorry: "Извините за неудобства",
    description: "Официант удалил это блюдо(а) с вашего чека по причине:",
    order_else: "Заказать что-то другое :)",
  },
  superman: {
    wait: "Подождите",
    paid_cash_card: "Официант уже летит вас рассчитывать",
    paid_online: "Ваш платеж полетел в обработку",
  },
  list: {
    total: "Всего:",
    free: "Бесплатно",
    tips: "Чаевые",
    cutlery: "Приборы",
  },
  bill: {
    check_num: "ID чека:",
    serving_time: "Время обслуживания:",
    waiter: "Официант:",
    table: "Стол:",
    date: "Дата:",
    discount: "Скидка:",
    download_check: "Сохранить чек",

    cash: "Наличка",
    card: "Терминал",
    online: "Онлайн",
    connect_cafe: "Подключить свое заведение",
  },
  preorder: {
    mode_preorder: "TAKEAWAY",
    mode_delivery: "DELIVERY",

    address: "Улица, номер дома, город",

    contact_phone: "Контактный телефон заведения:",

    edit: "Редактировать",
  },
  preorder_bill: {
    title: "Предзаказ",

    action_pay: "Подтвердить заказ",

    id: "ID чека:",
    takeaway: "Время получения:",
    date: "Дата:",
    phone: "Моб. номер:",
    address: "Адрес:",
    time: "Время:",

    cafe_address: "Адрес заведения:",
    open_map: "Открыть карту",

    with_takeaway: "С собой",
    with_reservation: "+ бронь столика",

    warning:
      "Ваш предзаказ был отправлен. <br>Он будет готов в указанное Вами время.<br><br> Чтобы получить его, просто покажите<br> официанту этот чек. <br><br> ! Не закрывайте эту страницу либо<br>сохраните чек чтобы не потерять его..",
    send_after_payment: "Ваш заказ будет отправлен заведению после оплаты.",
    individual: {
      tenshi_delivery:
        "Доставка оплачивается при получении. Стоимость доставки меняеться в соответствии с тарифами службы доставки.",
    },
    taxi_tariff:
      "Стоимость доставки нужно будет оплатить дополнительно при получении.",

    btn_back_to_menu: "Вернуться в меню",
  },
  delivery_bill: {
    warning: "Ваш заказ<br> был отправлен заведению.<br>",
    delivery_item: "Доставка",
    delivery_additional: "Дополнительно",
    delivery_free: "Безкоштовно",
    delivery_additional_long: "Дополнительно при получении",
    cash_warning: "Заказ нужно будет оплатить наличными при получении.",
    cost_by_receive: "Доставка оплачивается дополнительно при получении.",
    delivery_cost_upper: "ДОСТАВКА",
  },
  delivery_info: {
    title: "Доставка",
    free: "Доставка бесплатная",
    constant: "Стоимость доставки равна",
    payment_check: "включена в чек",
    payment_receive: "оплачивается при получении",
    taxi: `Стоимость доставки начисляется по тарифам службы доставки и оплачивается при получении.`,
  },
  reserve_info: {
    title: "Депозит",
    info: "Для бронирования необходимо оплатить депозит",
  },
  units: {
    minutes: "мин.",
    hours: "ч.",
    g: "г",
    ml: "мл",
    l: "л",
    kg: "кг",
    cm: "см.",
    secs: "сек.",
  },
  partners: {
    title: "Наш партнер:",
    go: "Перейти",
    apteka: {
      name: "Ласкава-Фарм",
      desc: `Сеть онлайн аптек. Покупайте лекарства прямо со своего телефона. Доставка по всей Украине.`,
    },
  },
  roulette: {
    get_a_chance: "Получите шанс выиграть блюдо за счет QRWaiter!",
    try: "Попробовать!",
    next_time: "В другой раз",
    approve_code: "Введите код подтверждения <br> <span>из СМС</span>",

    leave_info: "Оставьте информацию, <br> чтобы получить приз.",
    name_ph: "Как Вас зовут?",
    phone_ph: "Ваш номер телефона",
    next: "Отправить код через СМС >",
    confirm: "Подтвердить",
    resend: "Отправить код еще раз",

    limit: "Вы уже принимали участие в этом розыгрыше.",

    your_prize: "Ваш приз!",

    will_be_added:
      "Ваш приз будет добавлен в чек. <br /> Вы сможете его забрать, сделав заказ через QRWaiter.",
    no_prize: "Не повезло :(",

    you_lost:
      "К сожалению, Вы ничего не выиграли. Но не огорчайтесь, повезет в следующий раз!",

    take_prize: "Забрать приз!",
    close: "Закрыть",
  },
  preorder_modes: {
    how: "Как Вы желаете сделать заказ?",
    normal_desc: 'Предзаказ еды и напитков "на вынос" - с собой.',
    delivery_desc:
      "Ваш заказ будет доставлен по указанному адресу, и в указанные дату и время.",
    min_check: "Минимальная сума заказа:",
  },
  mode_modal: {
    min_check_header: "Минимальный заказ",
    min_check_content: "Сумма минимального заказа составляет",

    delivery_price_header: "Стоимость и условия доставки",

    preorder_title:
      'С помощью системы TAKEAWAY Вы можете просмотреть меню и удобно сделать заказ "с собой".',
    takeaway_title:
      'С помощью системы TAKEAWAY Вы можете просмотреть меню и удобно сделать заказ "с собой".',
    delivery_title:
      "С помощью системы DELIVERY Вы можете просмотреть меню и удобно сделать заказ на доставку.",
    rooms_title:
      "С помощью системы ROOMS Вы можете просмотреть меню и заказать еду, напитки или другой рум-сервис прямо в Ваш номер.",
    reserve_title:
      "С помощью системой RESERVE Вы можете забронировать столик на вечер.",

    delivery_types: {
      free: "Доставка в этом заведении бесплатная",
      constant: "Стоимость доставки составляет {cost}{currency}.",
      additional:
        "Стоимость доставки начисляется по тарифу службы доставки и оплачивается дополнительно при получении заказа.",
      conditional:
        "Для заказов на сумму от {min_check}{currency} - доставка бесплатная.",
    },
    btn_menu: "Перейти к меню",
    btn_hide: "Больше не показывать",
  },
  preorder_time_modal: {
    header: "На какое время приготовить Ваш заказ",
    header_delivery: "Когда Ваш заказ должен быть доставлен",
    date: "Дата",
    date_no_change: `Дату предзаказа сменить нельзя.<br />Она должна совпадать с сегодняшней. <br />Если это не так - обновите страницу.`,
    choose_time: "Выберите время",
    choose_time_warn: "Выберите время, на которое Ваш заказ должен быть готов.",

    personal_title: "Ваши персональные данные",
    call_you: "Как Вас зовут?",
    phone: "Ваш номер телефона",
    phone_desc: `Укажите Ваш номер телефона для связи с Вами.`,
    address: "Ваш адрес",
    address_desc: "Укажите Ваш адрес для доставки",
    fast_as_possible: "Как можно скорее",
    time: "Время",
    time_desc: "Выберите время, на которе Ваш заказ должен быть готов.",
    address_warning: "Укажите корректный адрес и номер дома.",
  },
  delivery_mode_modal: {
    min_check: "Минимальная сумма заказа",
    cost: "Стоимость доставки:",
    cost_details: "Условия доставки:",
    cost_types: {
      free: "Доставка в этом заведении бесплатна.",
      paid: "Стоимость доставки будет добавлена в Ваш чек.",
      taxi:
        "Стоимость доставки расчитывается в соответствии с тарифами службы доставки.",
      conditional:
        "Для заказов на сумму от {cost} {currency} - доставка бесплатная.",
    },
    cost_free: "Бесплатно",
    cost_taxi: "Дополнительно",
  },
  back_to_marketplace: "Вернуться к списку заведений",
  cafe_closed: {
    title: "Заведение закрыто :(",
    try_later: "Поиграть в змейку",
    to_marketplace: "Вернуться на главную",
    refresh: "Перезагрузить страницу",
  },
  desktop_delivery: {
    navbar_menu: "Меню",
    navbar_cart: "Корзина",
    navbar_back: "На главную",

    tab_cart: "Корзина",
    tab_client: "Мои контакты",
    tab_delivery: "Доставка",

    modal_subtitle: "Вы можете редактировать",

    next: "Далее >",
    sms_confirm: "Введите код подтверждения из СМС:",

    to_pay: "К оплате",
    pay_cash: "Оплатить наличными",
    pay_online: "Оплатить онлайн",
    address: "Адрес",
    address_ph: "Введите Ваш адрес",

    menu_quantity: "Укажите количество",

    comment_desc: "Укажите дополнительный комментарий при необходимости.",
  },
  aiwaiter_survey: {
    help_us: "Помоги нам в создании искуственного интеллекта официанта.",
    start_button: "Пройти опрос",
    describe_1: "Опиши свою любмую еду",
    describe_2: "Какая она?",
    example:
      "Например: итальянская, диетическая, японская, веганская, сытная, сладкая...",
    send: "Отправить",
  },
  phone_confirm: {
    change_phone: "Проверить телефон",
    title: "Ваш номер телефона",
    sub: "Подтверждение номера",
    smscode: "Введите код из СМС",
  },
  order_buttons: {
    tips: `Чаевые`,
    menu: "Меню",
    cart: "Корзина",
    if1: `Если Вы владелец заведения, Вы можете`,
    if2: `Подключить собственное заведение`,
    feedback: `Оставить отзыв`,
    go2cheque: "Перейти к заказу",
    dishes: "блюд | {n} блюдо | {n} блюда | {n} блюд",
    send: "Отправить заказ",
  },
  alcoholic_modal: {
    subtitle: "Вы добавили в заказ акцизный товар.",
    text: "Подтвердите,<br> что Вам исполнилось 18 лет.",
    confirm: "Да, я совершеннолетний",
    cancel: "Отмена",
  },
  reserve: {
    header: "Детали брони",

    name_ph: `Ваше имя`,
    fullname_ph: "Ваш ФИО",
    phone_ph: "Номер телефона",

    today: "Сегодня",
    tomorrow: "Завтра",
    guests_count: "0 | 1 человек | {n} человек | {n} человек",
    guests_amount: "Количество человек",
    action: "Забронировать",

    card_title: "RESERVE",
    card_guests_count: "Количество мест:",
    card_reserve_time: "Время брони:",
    card_reserve_date: "Дата брони:",
    card_phone: "Моб. номер:",

    back_to_market: "Вернуться к маркетплейсу",
  },
  pay_methods: {
    title: "СПОСОБ ОПЛАТЫ",
    total: "ВСЕГО",
    bonus_balance: "БОНУСНЫЙ БАЛАНС",
    null: {
      long: "Выберите способ оплаты",
    },
    cash: {
      short: "Наличка",
      long: "Оплатить наличными",
      preorder: "Наличными при получении",
    },
    card: {
      short: "Терминал",
      long: "Оплатить терминалом",
      preorder: "Терминалом при получении",
    },
    online: {
      short: "Онлайн",
      long: "Оплатить онлайн",
      preorder: "Онлайн-предоплата",
    },
    bonus: {
      short: "Бонусами",
      long: "Оплатить бонусным счетом",
    },
  },
  feedback: {
    header: "Оставьте отзыв",
    title: "Отзыв:",
    for_cafe: "Про заведение",
    for_qrwaiter: "Про QRWaiter",
    rating: "Рейтинг",
    send: "Отправить отзыв",
    cancel: "Не оставлять отзыв",
    comment_ph: "Ваш комментарий",
    close: "Закрыть",
    thanks: "Спасибо за отзыв!",
  },
  marketplace: {
    list: {
      search_ph: "Поиск",
      filters: "Фильтры",
      cities_list: "Список городов",
      load_error: "К сожалению, произошла ошибка загрузки.",
      loading: "Загрузка...",
      city: "Город:",
      all_cities: "Все города",
      favorite_cafes: "Твои любимые заведения",
      map: "Карта заведений",
      no_cafes: "К сожалению, в Вашей локации нет заведений.",
      schedule: "Время работы:",
      closed: "Закрыто",
      day_off: "выходной",
      all_categories: "Все",
      opens_at: "откроется в",

      opens_in: "до открытия",
      closes_in: "до закрытия",
      open_24hr: "круглосуточно",

      goto_menu: "Перейти к меню",
      goto_chain: "Просмотреть заведения",

      distance: "км. от Вас",
    },
    filters: {
      by_mode: "По системах:",
      by_price: "По цене:",
      by_city: "Город",
      apply: "Применить фильтры",
      all_cities: "Все города",
      geo_city: "На основе моей локации",

      desktop_title: "ПОКАЗЫВАТЬ ЗАВЕДЕНИЯ",
      sys_inside: "Заказы через QR-коды",
      sys_preorder: "Предзаказ",
      sys_delivery: "Доставка",
      sys_reserve: "Бронь столов",
    },
    city_filters: {
      geo: "На основе моей локации",
      all: "Все",
    },
    map: {
      open: "Открыть",
    },
    chain: {
      count: "заведений",
    },
    code_region: "Код страны",
  },
  preorder_details: {
    contacts: "Ваши контакты",
    takeaway: "С собой",
    with_reservation: "Забронировать столик",
    guests_count: "Количество наборов",
    guests_count_suffix: "Без приборов | {n} набор | {n} набора | {n} наборов",
    preorder_title: "Детали предзаказа",
    delivery_title: "Детали доставки",
    choose_time: "Выберите дату и время",
    choose_time_only: "Выберите время",

    details_types: {
      flat: "Квартира",
      floor: "Этаж",
      entrance: `Подъезд`,
    },

    new_comment:
      "Вы можете оставить свой комментарий к заказу. Например, указать аллергенные ингредиенты или свои пожелания.",
  },
  choose_mode: {
    preorder: 'Закажи еду "с собой"',
    delivery: "Закажи еду с доставкой",
    reserve: "Забронируй столик на вечер",
    view_menu: "Меню",
    delivery_missing: "Подключить доставку",
    preorder_missing: "Подключить предзаказ",
    reserve_missing: "Подключить бронь столов",
    go_reserve: "Забронировать стол",
    preview_menu: "Просмотреть меню",
  },
  download_bill: {
    product_check: "Товарный чек",
    check_no: "Чек #",
    table_no: "Стол № ",
    waiter: "Официант",
    qnt: "шт",
    discount: "Скидка",
    cash: "Наличка",
    card: "Терминал",
    online: "Онлайн",
    pay_method: "Метод оплаты",
    total: "ВСЕГО",
  },
  rotate_device: "Переверните Ваше устройство.",
  comment_field_title: "Ваш комментарий к заказу",
  comment_field_info:
    "Вы можете оставить комментарий к заказу. Например указать аллергенные ингредиенты или свои пожелания.",
  promocode: {
    title: "У вас есть промо?",
    input: "Промокод",
    correct_code: "По вашему промокоду Вы получаете скидку 20% на весь чек!",
    wrong_code: "Неверный или уже использованный промокод",
    promo_types: {
      check_discount:
        "По этому промокоду Вы получаете скидку {discount}% на весь чек!",
      free_delivery: "По этому промокоду Вы получаете бесплатную доставку",
    },
  },
  load_error: {
    title: "К сожалению, что-то пошло не так :(",
    cafe_not_paid: "Меню недоступно",
    code: "Код ошибки:",
    back: "На главную",
    support: "Тех. поддержка",
    mode_setup: "Система {mode} не настроена в этом заведении",
    wrong_setup: "Заведение настроено неверно.",
    not_found: `Объект не найден, проверьте корректность ссылки.`,
    request_failed: `Запрос не удался, проверьте доступ к Интернету.`,
  },
  desktop_blocker: {
    description:
      "Эта страница доступна только на мобильной версии. Просканируйте QR-код, чтобы открыть мобильную версию.",
  },
  activate_cafe: {
    title: "Заведение {cafe_name} активировано.",
    text:
      "Поздравляем! Наклейки с QR-кодами активированы и после оплаты подписки Ваши гости смогут делать заказы.",
    subscribe: "Оплатить подписку",
  },
  moeco_partner_card: {
    title_delivery: "Ваш заказ будет доставлен экологическим такси moeco",
    motto: "Будь эко з moeco",
    title_normal: "Наш партнер по доставке -<br> эко-такси moeco.",
    save_contact: "Сохранить контакт",
  },
  marketplace_geo_modal: {
    hint: "Это для того чтобы показывать заведения твоего города",
    title: "Пожалуйста, предоставьте одноразовый<br> доступ к Вашей геолокации",
    ok: "OK",
    deny: "Не давать геолокацию",
    denied: "В доступе к геолокации отказано.",
    error_text:
      "Для просмотра списка заведений, предоставьте доступ к геолокации.",
    try_again: "Попробовать еще раз",
  },
  split_payment: {
    number: "счет",
    sent_text: "Ваш заказ будет отправлен заведению после оплаты.",
    pay_button: "Оплатить счет",
  },
  online_payment_modal: {
    redirect_btn: "Перейти к оплате",
    cancel_btn: "Отменить",
  },
  support_modal: {
    title:
      "Выберите удобный для Вас способ обратной связи с тех. поддержкой QRWaiter:",
    close: "Закрыть",
  },
  successfull_payment: {
    title: "Ваш онлайн платеж был принят.",
    button: "Закрыть это окно",
    subtitle: "Вы можете закрыть эту вкладку и вернуться с заказу.",
  },
  order_webstatuses: {
    confirmed_off: "Ваш заказ принят.<br>Ожидание подтверджения...",
    confirmed_on: "Заказ подтверджен",
    cancelled_on: "Ваш заказ отменен:<br>{reason}",
    accepted_off: "Приготовление",
    accepted_on: "Приготовление...<br>Время ожидания: {waiting_time} мин.",
    served_off: "Ваш заказ готов",
    served_on: "Ваш заказ готов",
    delivered_off: "Доставка",
    delivered_on: "Доставка...<br>Приблизительное время: {shipping_time} хв.",
    closed_off: "Оплата",
    closed_on: "Оплачено",
    reserved_off: "Подтверждение брони...",
    reserved_on: "Подтверджено",
  },
  notifications_update: {
    title: "QRWaiter теперь не будет присылать SMS уведомления",
    content:
      "Статус заказа Вы можете отследить под чеком или на главной странице маркетплейса",
  },
  fix_address_modal: {
    title: "Пожалуйста, исправьте адрес",
    subtitle: "Пример: ул. Победы, 2, Москва",
  },
  tips_modal: {
    title:
      "Если Вам понравилось обслуживание то Вы можете его оценить с помощью чаевых =)",

    btn_pay: "Оплатить",
    btn_add: "Добавить в чек",
    btn_cancel: "Вернуться назад",

    thanks: "Спасибо!",

    leave_tips: "Оставить чаевые",
    add_tips: "Добавить чаевые",
  },
  order_not_accepted: {
    title: "Ваш заказ не был принят",
    button: "Заказать что-то другое",
  },
  future_alert: {
    title: "Заказ наперед",
    info:
      "Ваш заказ был сделан в нерабочее время. Заведение получит уведомление о нем в {date} (после открытия)",
  },
  packaging_info: {
    title: "Упаковка",
    desc: "В Ваш чек будет дополнительно включена стоимость упаковки на сумму",
  },
};

export default RU_LOCALE;
