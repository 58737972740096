import ThemeService from './service'

export default {
  methods: {
    isDarkModeMethod() {
      return ThemeService.isDarkMode() && this.isMobile;
    },
  },
  computed: {
    isDarkMode() {
      return ThemeService.isDarkMode() && this.isMobile;
    },
  },
};