<template>
  <div>
    <img
      src="/icons/map-close-icon.svg"
      class="close-mode"
      v-if="selected_cafe"
      @click="selected_cafe = null"
    />

    <img
      src="/icons/map-close-icon.svg"
      class="close-mode"
      v-if="!selected_cafe && isMobile"
      @click="closeMap"
    />
    <div class="map-wrapper">
      <div class="google-map" ref="map"></div>
    </div>
    <!-- <div class="selected-cafe" v-thematic v-if="selected_cafe">
      <div class="cafe-content">
        <h4 class="cafe-title">
          {{ selected_cafe.name }} • {{ selected_cafe.price_level }}
        </h4>
        <img
          src="../../assets/close-cafe-map.svg"
          @click="selected_cafe = null"
        /> <div class="cafe-content">
        <h4 class="cafe-title">{{ selected_cafe.name }} • {{ selected_cafe.price_level }}</h4>
        <img src="../../assets/close-cafe-map.svg" @click="selected_cafe = null" />
        <span v-thematic class="cafe-schedule">
          <small
            >{{ $t("marketplace.list.schedule") }}
            {{ selected_cafe.schedule_verdict.text }}</small
          >
        </span>
        <button class="cafe-enter-button" @click="onCafeSelect">
          {{ $t("marketplace.map.open") }}
        </button>
      </div>
      <img :src="selected_cafe.image" class="cafe-image" />
      <span class="discount" v-if="selected_cafe.cashback_percent" v-thematic>
        {{ selected_cafe.cashback_percent }}%
      </span>
      <div class="labels">
        <div class="label i" v-if="selected_cafe.modes.inside">INSIDE</div>
        <div class="label r" v-if="selected_cafe.modes.reserve">RESERVE</div>
        <div class="label d" v-if="selected_cafe.modes.delivery">DELIVERY</div>
        <div class="label p" v-if="selected_cafe.modes.preorder">TAKEAWAY</div>
      </div>
    </div> -->
    <div v-if="selected_cafe && isMobile">
      <ChooseModeUI
        :showMainBg="false"
        :isMapOpened="true"
        :class="isDarkMode ? 'black-bg pb-50' : 'white-bg pb-50'"
        :cafe="selected_cafe"
      />
    </div>
    <CafePageDesktop :cafe="selected_cafe" @close="selected_cafe = null" v-else-if="selected_cafe && !isMobile"/>
    <header>
      <!-- <img
        class="map-close"
        src="/icons/map-close-icon.svg"
        @click="closeMap"
        v-if="!isMobile"
      /> -->
    </header>
    <nav>
      <img
        class="map-home"
        src="/icons/map-location-icon.svg"
        @click="toMyLocation"
        v-if="startPos && isMobile"
      />
    </nav>
    <div class="map-controll-container" v-if="!isMobile">
      <div class="my-location-btn" @click="toMyLocation">
        <img src="/icons/desktop/map-controll-location.svg" alt="" />
        <img src="/icons/desktop/map-controll-location-white.svg" alt="" />
      </div>
      <div>
        <div class="map-controll-button" @click="changeZoom(1)">
          <img src="/icons/desktop/map-controll-plus.svg" alt="" />
          <img src="/icons/desktop/map-controll-plus-white.svg" alt="" />
        </div>
        <div class="map-controll-button" @click="changeZoom(-1)">
          <img src="/icons/desktop/map-controll-minus.svg" alt="" />
          <img src="/icons/desktop/map-controll-minus-white.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CafePageDesktop from "@/components/desktop/CafePageDesktop.vue"
import ChooseModeUI from "@/components/ChooseModeUI.vue";

export default {
  components: {
    ChooseModeUI,
    CafePageDesktop
  },
  props: {
    cafes: Array,
    canViewDetails: {
      type: Boolean,
      default: true,
    },
    startPos: Object,
  },
  data() {
    return {
      map_object: null,
      selected_cafe: null,

      last_cafe_selected_at: Date.now()
    };
  },
  methods: {
    changeZoom(delta) {
      const z = this.map_object.getZoom() || 0;
      this.map_object.setZoom(z+delta);
    },
    renderMap() {
      const coords = new google.maps.LatLng(48.622247, 22.298556);

      this.map_object = new google.maps.Map(this.$refs.map, {
        zoom: 19,
        center: coords,
        streetViewControl: false,
        mapTypeControl: false,
        disableDefaultUI: true,
      });

      this.cafes.forEach((c) => {
        if (c.address_location) {
          this.addCafeMarker(c);
        }
      });

      if (this.startPos) {
        this.toMyLocation();
      } else {
        const first_cafe = this.cafes.find((c) => !!c.address_location);
        if (!first_cafe) return;

        const first_cafe_pos = first_cafe.address_location;
        this.map_object.setCenter({
          lat: first_cafe_pos.lat,
          lng: first_cafe_pos.lon,
        });
      }

      const vm = this;

      // this.map_object.addListener("click", () => {
      //   console.log(`clicked`);
        
      //   if (!vm.isMobile && Date.now() - vm.last_cafe_selected_at > 500) {
      //     vm.selected_cafe = null;
      //   }
      // });
    },
    onCafeSelect(cafe) {
      this.$emit("cafe", cafe || this.selected_cafe);
    },
    closeMap() {
      this.$emit("close");
    },
    toMyLocation() {
      this.map_object.setCenter({
        lat: this.startPos.lat,
        lng: this.startPos.lon,
      });
    },
    addCafeMarker(cafe) {
      // const marker = new window.google.maps.Marker({
      //   position: new google.maps.LatLng(
      //     cafe.address_location.lat,
      //     cafe.address_location.lon
      //   ),
      //   icon: "/icons/map-marker-icon.svg",
      //   map: this.map_object,
      // });

      // const vm = this;

      // window.google.maps.event.addListener(marker, "click", () => {
      //   if (!vm.canViewDetails) return;
      //   vm.selected_cafe = cafe;
      //   vm.map_object.setCenter(marker.getPosition());
      // });
      let map = this.map_object;
      const bounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(
          cafe.address_location.lat,
          cafe.address_location.lon
        )
      );
      let image = "/icons/desktop/map-marker-icon.svg";
      const vm = this;
      class USGSOverlay extends google.maps.OverlayView {
        constructor(bounds, image) {
          super();
          this.bounds = bounds;
          this.image = image;
        }
        onAdd() {
          // Start style the marker

          this.div = document.createElement("div");
          this.div.style =
            "position:absolute;display:flex;align-items:center;cursor:pointer";
          const img = document.createElement("img");
          img.src = this.image;
          img.style.width = "6vh";
          img.style.height = "6vh";
          img.style.marginRight = "0.556vh";
          const cafeName = document.createElement("div");
          cafeName.innerText = cafe.name;
          cafeName.style = "font-size: 3vh;line-height: 3.2vh;color: #1D1D1B;";
          const cafeCategory = document.createElement("div");
          cafeCategory.innerText = "";
          cafeCategory.style =
            "font-size: 2vh;line-height: 2.2vh;color: #1D1D1B;";
          const cafeLabel = document.createElement("div");
          cafeLabel.appendChild(cafeName);
          cafeLabel.appendChild(cafeCategory);
          this.div.appendChild(img);
          this.div.appendChild(cafeLabel);

          // End style the marker
          const classVm =this;

          const panes = this.getPanes();
          panes.overlayMouseTarget.appendChild(this.div);
          google.maps.event.addDomListener(this.div, "click", () => {
            if (!vm.canViewDetails) return;
            // vm.selected_cafe = cafe;
            vm.onCafeSelect(cafe);
            vm.map_object.setCenter(classVm.bounds.getSouthWest());
            vm.last_cafe_selected_at = Date.now();
          });
        }
        draw() {
          const overlayProjection = this.getProjection();
          const sw = overlayProjection.fromLatLngToDivPixel(
            this.bounds.getSouthWest()
          );
          const ne = overlayProjection.fromLatLngToDivPixel(
            this.bounds.getNorthEast()
          );
          if (this.div) {
            this.div.style.left = sw.x + "px";
            this.div.style.top = ne.y + "px";
          }
        }
      }
      
      const overlay = new USGSOverlay(bounds, image);
      overlay.setMap(map);
    },
    runUiClose() {
      if (this.selected_cafe) {
        this.selected_cafe = null;
      } else {
        this.$emit('close');
      }
    }
  },
  mounted() {
    this.renderMap();
  },
};
</script>

<style scoped>
.pb-50 {
  padding-bottom: 12vh;
}

/* .white-bg {
  background: linear-gradient(180deg, rgba(237, 237, 237, 0.1) 0%, #EDEDED 100%);
}

.black-bg {
 background: linear-gradient(180deg, rgba(237, 237, 237, 0.7) 0%, #000 100%);
} */

header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
  opacity: 0.6;
  height: 12vh;
  background: linear-gradient(
    180deg,
    rgba(95, 95, 95, 0.35) 0%,
    rgba(95, 95, 95, 0) 100%
  );
}

nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 5;
  opacity: 0.6;
  height: 12vh;
  background: linear-gradient(
    180deg,
    rgba(95, 95, 95, 0) 0%,
    rgba(95, 95, 95, 0.35) 100%
  );
}

.map-wrapper {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;

  z-index: 5;
}

span.discount {
  background: #1d1d1d;
  padding: 0.2vh 3vh;
  position: absolute;
  font-size: 2vh;
  bottom: 15vh;
  left: 2vh;
  color: #ededed;
}

.labels {
  display: flex;
  flex-flow: column;
  position: absolute;
  bottom: 3vh;
  right: 2vh;
}

.label {
  font-size: 1vh;
  text-align: center;
  width: 7vh;
  padding: 0.5vh 0;
  line-height: initial;
  color: #1d1d1b;
  letter-spacing: -1px;
  margin-bottom: 0.6vh;
  font-weight: bold;
  border-radius: 0.5vh;
}
.label.i {
  background: #1d1d1b;
  color: #ededed;
}
.label.r {
  background: #aeccc6;
  color: #1d1d1b;
}
.label.d {
  background: #cbd9e6;
}
.label.p {
  background: #ffd2a8;
}

.google-map {
  width: 100vw;
  height: 100vh;
}

.selected-cafe {
  animation: slideup 0.5s;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 37vh;
  transform: translateY(-50%);
  width: 100%;
  border-radius: 2.5vh 2.5vh 0 0;
  background: transparent;
  color: #1d1d1b;

  z-index: 10;
}

.selected-cafe.dark {
  background: #1d1d1b;
  color: #ededed;
}

@keyframes slideup {
  0% {
    bottom: -90vh;

    position: relative;
  }
  100% {
    bottom: 8%;
    opacity: 1;
  }
}

.cafe-image {
  width: 100%;
  object-fit: cover;
  height: 21vh;
}

h4.cafe-title {
  font-size: 2.5vh;
  line-height: 0.8;
  margin: 0;
}

.cafe-content {
  padding: 2.2vh;
}

img.close-mode {
  height: 5vh;
  z-index: 6;
  top: 2vh;
  position: fixed;
  right: 1.3vh;
}

.cafe-enter-button {
  outline: none;
  border: 0;
  text-align: center;
  margin-top: 2.2vh;
  border-radius: 1.5vh;
  width: 100%;
  background: #129b81 !important;
  color: #ededed !important;
  padding: 1.6vh;
}

.cafe-schedule {
  color: #1d1d1b;
  display: block;
  line-height: normal;
}

.cafe-schedule.dark {
  color: #ededed;
}

.map-home {
  position: fixed;
  z-index: 11;
  right: 2.5vh;
  bottom: 2.5vh;
}
.my-location-btn {
  width: 4.444444444444445vh;
  height: 4.444444444444445vh;
  background: rgba(29, 29, 27, 0.7);
  backdrop-filter: blur(20px);
  border-radius: 0.3333333333333333vh;
  margin-bottom: 2.2222vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-location-btn img {
  width: 2.2222vh;
  cursor: pointer;
  display: none;
}
.my-location-btn img:first-child {
  display: block;
}
.my-location-btn:hover img:first-child {
  display: none;
}
.my-location-btn:hover img:last-child {
  display: block;
}
.map-controll-button {
  width: 4.444444444444445vh;
  height: 4.444444444444445vh;
  background: rgba(29, 29, 27, 0.7);
  backdrop-filter: blur(20px);
  border-radius: 0.3333333333333333vh 0.3333333333333333vh 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.map-controll-button img {
  width: 2.2222vh;
  display: none;
  cursor: pointer;
}
.map-controll-button img:first-child {
  display: block;
}
.map-controll-button:hover img:first-child {
  display: none;
}
.map-controll-button:hover img:last-child {
  display: block;
}
.map-controll-button:last-child {
  border-radius: 0px 0px 0.3333333333333333vh 0.3333333333333333vh;
}
.map-controll-container {
  position: fixed;
  right: 4.4444vh;
  bottom: 4.4444vh;
  z-index: 5;
}
</style>
