const TR_LOCALE = {
  errors: {
    access_denied: `Access denied.`,
    invaLid_data: `InvaLid data format.`,
    db_query_error: `Database read error.`,
    db_write_error: `Database write error.`,
    not_found: `Object not found.`,
    cafe_not_ready: `Pere pLace is not confiGured yet.`,
    request_faiLed: `Connection Lost.`,
    not_avaiLable: "Peris action is not available.",
    invalid_order_total: "Invalid order total.",
    cafe_not_paid: "QRWaiter system is not paid",

    retry: "Cmtyfayı yenileyin and try aGain.",
    tech_problem:
      " this dish is out of stock., your order could not be processed due to techincal reasons.",
  },
  banner: {
    title: "App for ordering food",
    Get_app: "DOWNLOAD",
  },
  GreetinG: {
    make_order: "Make an order",
    until_close_left: "until closed",
    table: "Masa",
    closed: "Kapatd :(",
    not_paid: "Pere place has not paid for the QRWaiter subscription :(",
    free_day: "İzin günü.",

    introduce_text: "Your personal data",
    introduce_input_name: "What is your name?",
    introduce_input_phone: "Your phone number",
    skip: "Skip",
    go: "Go!",
    preorder: "TAKEAWAY",

    choose_language: "Choose language:",
    tutorial: "First time here? Learn how to make an order :)",
  },
  address: {
    correct_title: " take your order., change the address",
    select_google:
      "Address must be selected from dropdown of suggested addresses",
    correct_example: "Example: st. Shevchenka, 1, Kiev",
  },
  erorr_modal: {
    title: "Eror code:",
    text: " something went wrong..., something went wrong...",
    solve: "Refresh the page and try again",
  },
  active_order: {
    check: "Zaten bir şey sipariş etmişsin.",
    modal_text:
      "Bu sizin siparişinizse - sepete eklemek için Kabul Et'e basınız",
    accept: "Kabul et",
    cancel: "İptal ",
  },
  schedule: {
    title: "Çalışma zamanı",
    mon: "Pazartesi",
    tue: "Cmtlı",
    wed: "Çarşamba",
    thu: "Perşembe",
    fri: "Cuma",
    sat: "Cumartesi",
    sun: "Pazar",

    short: {
      mon: "Pzt",
      tue: "Cmtl",
      wed: "Çar",
      thu: "Per",
      fri: "Cum",
      sat: "Cmt",
      sun: "Pzr",
    },

    free_day: "İzin günü",
  },
  navbar: {
    services: "Hizmetler",
    room_no: "Room",
    table_no: "Masa",
    offert: "KAMU TEKLİFİ",
  },
  menu: {
    show_more: "Daha fazla göster...",
    show_less: "Daha az göster...",

    choose_variant: "Bir seçenek seçin:",
    choose_modificators: "Eklemeleri seçiniz",
    add_to_cart: "+ Sepete ekle",
    added_to_cart: "Sepete eklendi",
    recommending: "QRWaiter önerileri",

    menu_closed_after:
      " this dish is out of stock., this menu works only from ",
    menu_closed_before:
      " this dish is out of stock., this menu works only until ",

    out_of_stock: "Ne yazık ki, bu yemek stokta yok.",

    show_all: "Hepsini Göster",
    exclusives: "Öneriler",
    weight: "Ağırlık:",
    where_to_start: "Başlarken",
    price_per_unit: "Price for 1 {unit}:",
  },
  switcher: {
    primary: "Ana menü",
    tags: {
      bar: "Bar",
    },
  },
  cart: {
    empty: "Sepetiniz boş.",
    comment: "Yorum Yap:",
    comment_desc: "Any additional information for the waiter (optional)",
    send: "Sipariş gönder",
    next: "Next >",
    min_check: "Toplamınız: ",
    wait: "İşleme süreci...",
    cashback: "Bonus from this order will equal {cashback}",
    empty_desktop:
      "your cart is empty. However, it is easy to fix - you can always add a product to it.",
  },
  not_paid_modal: {
    title: "Pere place hasn't paid",
    text:
      " this dish is out of stock., this cafe hasn't paid for QRWaiter services and so we have disabled order sending temporarily for this place.",
  },
  card: {
    status_cart: "Düzenleyebilirsiniz",
    status_waiting: "Kafeye gönderildi",
    status_serving: "Siparişiniz {min} dakika önce kabul edildi",
    status_serving_recent:
      "Siparişiniz bir dakikadan kısa bir süre önce kabul edildi.",
    status_served: "Servis edildi.",
    status_cancelled: "İptal edildi.",
    status_unpaid: "Ödenmedi",
    status_paying: "Ödeme yapılıyor...",
    status_executed: "Ödendi",
    order: "Şipariş",
    check: "Kontrol",
    waiting_time: "Bekleme süresi:",
    self_service: "Lütfen cihazınızı döndürün.",
    cancel_reason: "İptal nedeni:",

    append: "Şipariş",
    approve_code: "Enter verification code <br> <span>from SMS</span>",
    waiting_time_long: "more than 20 minutes.",
    dont_close: `Don't close the page until the waiter accepts your order.`,
    play_game: "And you can play snake game by clicking on it below.",
    bonus_balance: "Available bonuses: {balance} UAH",
    download_app: "Uygulamayı indirin",
    order_else: `Daha fazla sipariş ver`,
  },
  actions: {
    cancel: "Siparişi iptal et",
    pay: "Ödeme yap",
    cancel_2: "İptal order",
  },
  pay: {
    support_text: "Hizmetten memnun kaldıysanız - bahşiş bırakabilirsiniz :)",
    tip_amount: "Toplamınızın %0'ı",
    tip_info:
      "Tipping was added automatically as the waiter responded quickly to your order.",
    choose_pay_method: "Ödeme yöntemini seçiniz",
    method_bonus: "Bonus account QRWaiter",
    method_cash: "Nakit öde",
    method_card: "Terminali kullanarak ödeme yapın",
    method_liqpay: "Çevrimiçi ödeme",
    method_bonus: "Bonuslarla öde",
    choose_tip_summ: "Bahşiş miktarını belirtin",
    tip_add_to_cheque: "Kontrol etmek için ekleyin",
    separate: "Ayrı ödeme yapın",
  },
  cancel: {
    confirm_text: "Siparişinizi iptal etmek istediğinizden emin misiniz?",
    select_reason: "Lütfen nedenini belirtin:",
    reasons: {
      wrong_order: "Sipariş yanlış verildi",
      no_time: "Bekleyecek zaman yok",
      custom: "Diğer",
    },

    enter_reason: "Bir neden girin",

    button_continue: "Devam et",
    button_cancel: "İptal",
  },
  geo: {
    title: "Konumun doğru olduğundan emin olmalıyız.",
    title_hotel: "Çar need to make sure you are at hotel",
    please: "Lütfen konumunuza erişmemize izin veriniz.",
    just_click: "Bu butona basmanız yeterli :)",
    reload: "Cmtyfayı yenileyin",
    fail_title:
      " you must provide geolocation access so we could check if your are really at the place., you must provide geolocation access so we could check if your are really at the place.",
    fail_subtitle:
      " please go to Settings -> Privacy -> Geolocation access, please go to Settings -> Privacy -> Geolocation access",

    fail_titles: {
      browser: "Coğrafi konumunuza<br>erişim vermediniz.",
      out_of_range: "You are out<br>of delivery reach.",
      not_in_cafe: "You are<br>out of cafe reach.",
    },
    fail_subtitles: {
      browser: "Cmtyfayı yenileyin ve tekrar deneyin.",
      out_of_range: "Delivery to your location is not possible.",
      not_in_cafe: "Sipariş vermek için kafede olmalısınız",
    },

    not_in_cafe: "Ben kafede değilim",
  },
  deleted_dish: {
    sorry: "Sorry for inconvenience",
    description: "Pere waiter removed this dish(es) from your order because ",
    order_else: "Başka bir şey sipariş et :)",
  },
  superman: {
    wait: "Lütfen bekleyin",
    paid_cash_card: "Garson sana fişi vermeye geliyor",
    paid_online: "Ödemeniz devam ediyor",
  },
  list: {
    total: "Toplam:",
    free: "Ücretsiz",
    tips: "Bahşişler",
    cutlery: "Çatal bıçak takımı",
  },
  bill: {
    check_num: "KiMliği kontrol et:",
    serving_time: "Servis zamanı:",
    waiter: "Garson:",
    table: "Masa:",
    date: "Tarih:",
    discount: "İndirim:",
    download_check: "Çeki indir",

    cash: "Peşin",
    card: "Terminal",
    online: "Çevrimiçi",
    connect_cafe: "Connect own cafe or restaurant",
  },
  preorder_bill: {
    title: "Preorder",

    action_pay: "<br> that you are at least 18 years old. order",

    id: "Kontrol et ID:",
    takeaway: "Receive time:",
    date: "Tarih:",
    phone: "Phone:",
    address: "Address:",
    time: "Time:",

    with_takeaway: "Takeaway",
    with_reservation: "+ table reservation",

    btn_back_to_menu: "Back to menu",

    open_map: "Open map",

    cafe_address: "Cafe address:",

    warning:
      "Your preorder was sent. <br>It will be ready at specified time. <br><br> To receive it, just show  <br> that check to the waiter. <br><br> ! Don't close this page or save<br>the bill to prevent losing it.",
    send_after_payment: "Siparişiniz ödeme yapıldıktan sonra gönderilecektir.",
    individual: {
      tenshi_delivery:
        "Delivery cost is payed after receiving the order. The cost depends on delivery service pricing.",
    },
    taxi_tariff:
      "Delivery cost is payed after receiving the order. The cost depends on delivery service pricing.",
  },
  preorder: {
    mode_preorder: "TAKEAWAY",
    mode_delivery: "DELIVERY",
    address: "Street name, house, city",

    contact_phone: "Cafe contact phone:",

    edit: "Edit",
  },
  mode_modal: {
    min_check_header: "Minimal order",
    min_check_content: "Minimal price total equals",

    delivery_price_header: "Delivery pricing and conditions",

    preorder_title:
      'With TAKEAWAY system you can view menu and make a "take-away" order.',
    takeaway_title:
      'With TAKEAWAY system you can view menu and make a "take-away" order.',
    delivery_title:
      "With DELIVERY system you can view menu and easily make a delivery order",
    rooms_title:
      "With ROOMS system you can view menu and order food, drinks or other room-service directly to your room.",

    reserve_title: "With RESERVE system you can book a table for evening",

    delivery_types: {
      free: "Delivery in this cafe is free.",
      constant: "Delivery cost equals {cost} {currency}.",
      additional:
        "Delivery cost is calculated by courier service and is paid upon order receival.",
      conditional:
        "For orders with total over {min_check} {currency} - delivery is free.",
    },

    btn_menu: "Menüyü görüntüle",
    btn_hide: `Don't show this again`,
  },
  delivery_bill: {
    warning: "You order<br> has been sent to the cafe.<br>",
    delivery_item: "Delivery",
    delivery_additional: "Additional",
    delivery_free: "Cumee",
    delivery_additional_long: "Additional upon receiving",
    cash_warning: "The order must be paid by cash after receiving.",
    cost_by_receive: "Delivery is paid additionally upon receiving of order.",
    delivery_cost_upper: "DELIVERY",
  },
  delivery_info: {
    title: "Delivery",
    free: "Delivery is free",
    constant: "Delivery price will equal",
    payment_check: "included in check",
    payment_receive: "paid upon receival",
    taxi: `Delivery price is calculated by courier service and is paid upon receival.`,
  },
  reserve_info: {
    title: "Deposit",
    info: "To make reservation you have to pay deposit",
  },
  units: {
    minutes: "Dk",
    hours: "Saat",
    g: "g",
    Ml: "Ml",
    l: "l",
    Kg: "Kg",
    cm: "Cm",
    secs: "Sn",
  },
  partners: {
    title: "Our partner:",
    go: "Open",
    apteka: {
      name: "Ласкава-Фарм",
      desc: `Network of online pharmacies. Buy medicines directly from your smartphone. Shipping throughout Ukraine.`,
    },
  },
  roulette: {
    get_a_chance: "Get a chance to win a dish from QRWaiter!",
    try: "Try now!",
    next_time: "Later",
    approve_code: "Enter confirmation code <br> <span>for your number.</span>",

    leave_info: "Enter some information, <br> to get a prize.",
    name_ph: "What is your name?",
    phone_ph: "Your mobile phone number",
    next: "Send code via SMS >",
    confirm: "<br> that you are at least 18 years old.",
    resend: "Resend code",

    limit: "You have already taken part in this giveaway",

    your_prize: "Your prize!",

    will_be_added:
      "Your prize will be added to your check. <br /> You will be able to claim it, if you will make an order via QRWaiter.",
    no_prize: "No luck :(",

    you_lost: `Unfortunately, you didn't win a prize that time. But don't worry, you'll get lucky next time!`,

    take_prize: "Claim prize!",
    close: "Kapat",
  },
  preorder_modes: {
    how: "How do you want to make an order?",
    normal_desc: 'Preorder food and drinks as "take-away" order.',
    delivery_desc:
      "Your order will be delivered to the specified address, and at specfied time and date.",

    min_check: "Minimal order total:",
  },
  preorder_time_modal: {
    header: "When you order should be served",
    header_delivery: "When your order should be delivered",
    date: "Date",
    date_no_change: `Preorder date cannot be changed. <br /> It must match the today date.<br />Otherwise - refresh the page.`,
    choose_time: "Choose time",
    choose_time_warn: "Choose time, until which your order must be ready.",

    personal_title: "Your personal info",
    call_you: "What is your name?",
    phone: "Your mobile phone",
    phone_desc: `Enter your mobile phone for feedback.`,
    address: "Your address",
    address_desc: "Enter your delivery address.",
    fast_as_possible: "Fast as possible",
    time: "Time",
    time_desc: "Choose time, until which your order should be ready.",
    address_warning: "Specify correct address and house number.",
  },
  delivery_mode_modal: {
    min_check: "Minimal order total:",
    cost: "Delivery cost:",
    cost_details: "Delivery conditions:",
    cost_types: {
      free: "Delivery in this cafe is free.",
      paid: "Delivery cost will be added to your check.",
      taxi:
        "Delivery cost is calculated according to delivery service pricing.",
      conditional:
        "For orders with total over {cost} {currency} - delivery cost is free.",
    },
    cost_free: "Cumee",
    cost_taxi: "Additional",
  },
  back_to_marketplace: "Back to cafe list",
  cafe_closed: {
    title: "Mekan kapandı :(",
    try_later: "Play snake",
    to_marketplace: "Back to cafes",
    refresh: "Sayfayı yenileyin",
  },
  desktop_delivery: {
    navbar_menu: "Menü",
    navbar_cart: "Sepet",
    navbar_back: "To mainpage",

    tab_cart: "Sepet",
    tab_client: "My contacts",
    tab_delivery: "Delivery",

    modal_subtitle: "Düzenleyebilirsiniz",

    next: "Next >",
    sms_confirm: "Enter confirmation code from SMS:",

    to_pay: "To pay",
    pay_cash: "Nakit ödeme",
    pay_online: "Çevrimiçi ödeme",
    address: "Address",
    address_ph: "Enter your address",

    menu_quantity: "Choose quantity",

    comment_desc: "Enter optional comment if needed.",
  },
  aiwaiter_survey: {
    help_us: "Help us create artifical intelligence waiter",
    start_button: "Start survey",
    describe_1: "Describe your favourite food.",
    describe_2: "What is it like?",
    example: "For example: italian, diet, japanese, vegan, sweet...",
    send: "Send",
  },
  phone_confirm: {
    title: "Your phone number",
    sub: "Phone confirmation",
    smscode: "Enter the code from SMS",
    change_phone: "Re-check phone",
  },
  order_buttons: {
    tips: `Bahşişler`,
    menu: "Menü",
    cart: "Sepet",
    if1: `If you are a cafe owner, you can`,
    if2: `Connect your own cafe`,
    feedback: `Geri bildirim bırakın`,
    go2cheque: "Go to order",
    dishes: "{n} yemek |  {n} bulaşık",
    send: "Sipariş gönder",
  },
  alcoholic_modal: {
    subtitle: "Ürünü siparişinize eklediniz.",
    text:
      "<br> that you are at least 18 years old.,<br> that you are at least 18 years old.",
    confirm: " I confirm, I confirm",
    cancel: "İptal",
  },
  reserve: {
    header: "Reservation details",

    name_ph: `Your name`,
    fullname_ph: "Your fullname",
    phone_ph: "Your phone number",

    today: "Today",
    tomorrow: "Tomorrow",
    guests_count: "0 | 1 person | {n} people",
    guests_amount: "Amount of persons",
    action: "Reserve",

    card_title: "RESERVE",
    card_guests_count: "Guests count:",
    card_reserve_time: "Reservation time:",
    card_reserve_date: "Reservation date:",
    card_phone: "Phone number:",

    back_to_market: "Geri to marketplace",
  },
  pay_methods: {
    title: "ÖDEME YÖNTEMİ",
    total: "TOPLAM",
    bonus_balance: "BONUS BAKİYE",
    null: {
      long: "Ödeme yöntemini seçin",
    },
    cash: {
      short: "Peşin",
      long: "Öde by cash",
      preorder: "Kapıda ödeme",
    },
    card: {
      short: "Terminal",
      long: "Terminal ile ödeme",
      preorder: "Terminali alarak",
    },
    online: {
      short: "Çevrimiçi",
      long: "Ödeme yap online",
      preorder: "Çevrimiçi ön ödemeli",
    },
    bonus: {
      short: "Bonus",
      long: "Bonuslarla öde",
    },
  },
  feedback: {
    header: "Yorum bırak",
    for_cafe: "Kafe için",
    for_qrwaiter: "QRWaiter için",
    title: "YoruMlar",
    rating: "Değerlendirme",
    send: "Yorum gönder",
    cancel: "Yorum bırakmayın",
    comment_ph: "Senin yorumun",
    close: "Kapat",
    thanks: "Geribildirim için teşekkür ederim!",
  },
  marketplace: {
    list: {
      search_ph: "Search",
      filters: "Filters",
      cities_list: "Cities list",
      load_error: "Unfortunately, there was an error searching for cafes.",
      loading: "Loading...",
      city: "City:",
      all_cities: "All cities",
      favorite_cafes: "Your favorite cafes",
      map: "Cafes map",
      no_cafes: "Unfortunately, there are no cafes near you.",
      schedule: "Çalışma zamanı:",
      closed: "Kapatd",
      day_off: "day off",
      opens_at: "opents at",
      opens_in: "till open",
      closes_in: "till close",
      distance: "km. from you",
      goto_menu: "View menu",
      goto_chain: "View cafes",
      open_24hr: "open around the clock",
      all_categories: "All",
    },
    filters: {
      by_mode: "By system",
      by_price: "By price",
      by_city: "City",
      apply: "Apply filters",
      all_cities: "All cities",
      geo_city: "Based on my geolocation",
      desktop_title: "SHOW CAFES",
      sys_inside: "Şiparişs via QR-codes",
      sys_preorder: "Preordering",
      sys_delivery: "Delivery",
      sys_reserve: "Masa reservations",
    },
    city_filters: {
      geo: "Based on my geolocation",
      all: "All",
    },
    map: {
      open: "Open",
    },
    chain: {
      count: "spots",
    },
    code_region: "Country code",
  },
  preorder_details: {
    contacts: "Your contacts",
    takeaway: "Takeaway",
    with_reservation: "Reserve a table",
    guests_count: "Çatal bıçak takımı kits count",
    preorder_title: "Preorder details",
    guests_count_suffix: "No cutlery | 1 kit | {n} kits",
    delivery_title: "Delivery details",
    choose_time: "Choose date and time",
    choose_time_only: "Choose time",

    details_types: {
      flat: "Flat",
      floor: "Floor",
      entrance: `Entrance`,
    },

    new_comment:
      "Sipariş için yorum bırakabilirsiniz.  Örneğin, alerjen bileşenleri veya isteklerinizi belirtin.",
  },
  choose_mode: {
    preorder: "Şipariş takeaway food",
    delivery: "Şipariş food with delivery",
    reserve: "Reserve a table for evening",
    view_menu: "Menüyü görüntüle",
    delivery_missing: "Connect delivery",
    preorder_missing: "Connect preordering",
    reserve_missing: "Connect table reservations",
    go_reserve: "Book a table",
    preview_menu: "Preview menu",
  },
  download_bill: {
    product_check: "Kontrol et",
    check_no: "Kontrol et #",
    table_no: "Masa №",
    waiter: "Waiter",
    qnt: "Bilgisayar",
    discount: "İndirim",
    cash: "Peşin",
    card: "Kart",
    online: "Çevrimiçi",
    pay_method: "Ödeme yöntemi",
    total: "TOPLAM",
  },
  rotate_device: "Lütfen cihazınızı döndürün.",
  comment_field_title: "Sipariş için yorumunuz",
  comment_field_info:
    " specify allergenic ingredients or your wishes., specify allergenic ingredients or your wishes.",
  promocode: {
    title: "Promosyonunuz var mı?",
    input: "Promosyon kodu",
    correct_code: "Your promo gives you 20% discount for the whole cheque!",
    wrong_code: "Geçersiz veya süresi dolmuş promosyon kodu",
    promo_types: {
      check_discount: "This promocode gives you {discount}% total discount!",
      free_delivery: "This promocodes gives you free delivery",
    },
  },
  load_error: {
    title: "Something went wrong :(",
    cafe_not_paid: "Menü is unavailable.",
    code: "Hata kodu:",
    back: "Back to mainpage",
    support: "Contact support",
    mode_setup: "System {mode} is not ready in this cafe.",
    wrong_setup: "Cafe is not ready.",
    not_found: `Object not found, check your link validity.`,
    request_failed: `Request failed, check your Internet connection.`,
  },
  desktop_blocker: {
    description:
      "Bu sayfa yalnızca mobil cihazda kullanılabilir.  Mobil sürümü açmak için QR kodunu tarayın.",
  },
  activate_cafe: {
    title: "Cafe {cafe_name} activated.",
    text:
      "Congratulations! The QR-code stickers have been activated and your guests will be able to make orders right after paying a subscription.",
    subscribe: "Ödeme yap subscription",
  },
  moeco_partner_card: {
    title_delivery: "Your order will delivered by eco-taxi moeco",
    motto: "Be eco with moeco",
    title_normal: "Our partner - eco taxi moeco.",
    save_contact: "Save contact",
  },
  marketplace_geo_modal: {
    hint: `It's to show cafes of your town`,
    title: " take your order., provide one-time acces <br> to your geolocation",
    ok: "OK",
    deny: `Don't give geolocation`,
    denied: "Denied in geolocation.",
    error_text: "To view cafes list, provide access to your geolocation.",
    try_again: "Retry",
  },
  split_payment: {
    number: "Fatura",
    sent_text: "Siparişiniz ödeme yapıldıktan sonra gönderilecektir.",
    pay_button: "Ödeme billsı",
  },
  online_payment_modal: {
    redirect_btn: "Ödemeye git",
    cancel_btn: "İptal ",
  },
  support_modal: {
    title: "Choose the best way for contacting QRWaiter support:",
    close: "Kapat",
  },
  successfull_payment: {
    title: "Ödemeniz kabul edildi.",
    button: "Bu pencereyi kapat",
    subtitle: "Bu sekmeyi kapatabilir ve siparişinize geri dönebilirsiniz.",
  },
  order_webstatuses: {
    confirmed_off: "Your order has been sent.<br>Awaiting confirmation...",
    confirmed_on: "Şipariş has been confirmed.",
    cancelled_on: "Order cancelled:<br>{reason}",
    accepted_off: "Cooking",
    accepted_on: "Cooking...<br>Bekleme süresi: {waiting_time} Dk",
    served_off: "Ready",
    served_on: "You order is ready",
    delivered_off: "Delivery",
    delivered_on: "Delivering...<br>ETA: {shipping_time} Dk",
    closed_off: "Ödeme yapment",
    closed_on: "Ödeme yapment complete",
    reserved_off: "Needs confirmation",
    reserved_on: "Kabul eted",
  },
  notifications_update: {
    title: "QRWaiter now will not send SMS notifications",
    content:
      "Order status can be found under the cheque or on marketplace page",
  },
  fix_address_modal: {
    title: " take your order., fix your address",
    subtitle: "Example: Manchester Street, 5, London",
  },
  error_modal: {
    title: "Hata kodu:",
    text: " something went wrong..., something went wrong...",
    solve: "Sayfayı yeniden yükleyin ve tekrar deneyin",
    change_address: " take your order., change your delivery address",
  },
  tips_modal: {
    title: "Hizmeti beğendiyseniz, bahşiş bırakabilirsiniz =)",

    btn_pay: "Ödeme yap",
    btn_add: "Kontrol ediniz",
    btn_cancel: "Geri",

    thanks: "Çok teşekkürler!",

    leave_tips: "Bahşiş bırakın",
    add_tips: "Bahşiş ekle",
  },
  order_not_accepted: {
    title: "Siparişiniz kabul edilmedi",
    button: "Başka bir şey sipariş et",
  },
  future_alert: {
    title: "Pre-order",
    info:
      "Your order has been made while cafe is closed. The cafe will receive notification about your order at {date} (after opening)",
  },
  packaging_info: {
    title: "Packaging",
    desc:
      "Packaging cost will be additionaly included in your check with price of",
  },
};

// module.exports = EN_LOCALE;
export default TR_LOCALE;
