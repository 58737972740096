<template>
  <div class="cafe-page-desktop">
    <img
      v-if="!from_map"
      :src="cafe.image_bg || '/desktop-pics/cafe-bg-placeholder-desktop.jpg'"
      class="bg-image"
      alt=""
    />
    <!-- <MarketplaceMap class="map" :cafes="[cafe]" v-else-if="from_map" /> -->
    <!-- <img
      v-else-if=""
      :src="cafe.image_bg || '/icons/desktop/cafe-card-photo-placeholder.png'"
      class="bg-image"
      alt=""
    /> -->
    <div
      class="cafe-page-map-backdrop"
      v-if="from_map"
      @click="$emit('close')"
    ></div>
    <div class="scroll-area">
      <div class="cafe-card-wrapper">
        <img
          :src="cafe.image"
          class="cafe-image"
        />
        <div class="rate-container">
          <div
            class="top-part"
            v-if="cashbackPercent"
          >
            {{ cashbackPercent }}%
          </div>
          <div
            class="bottom-part"
            v-if="false"
          >
            <div class="text">
              {{ cafe.rating ? cafe.rating.reviews : cafe.reviews_rating }}
            </div>
            <img
              src="/icons/desktop/small-rate-icon-black.svg"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="cafe-card-container">

        <div class="card-header-desktop">
          <div class="text-img-row">
            <!-- <img src="/icons/desktop/star-icon-outlined.svg" alt="" /> -->
            {{ cafe.name }}
          </div>
          <div class="icons-container">

            <a
              :href="`tel:${cafe.contact_phone}`"
              class="icon"
              v-if="cafe.contact_phone"
              target="_blank"
            >
              <img
                src="/icons/desktop/cafe-card-phone-black-icon.svg"
                alt=""
              /><img
                src="/icons/desktop/cafe-card-phone-black-icon-fill.svg"
                alt=""
              />
            </a>
            <a
              class="icon"
              :href="cafe.social.website"
              target="_blank"
              v-if="cafe.social.website"
            >
              <img src="/icons/desktop/cafe-card-website-black-icon.svg" />
              <img
                src="/icons/desktop/cafe-card-website-black-icon-fill.svg"
                class="borders"
              />
            </a>

            <a
              :href="cafe.social.instagram"
              class="icon"
              v-if="cafe.social.instagram"
              target="_blank"
            >
              <img
                src="/icons/desktop/cafe-card-instagram-black-icon.svg"
                alt=""
              /><img
                src="/icons/desktop/cafe-card-instagram-black-icon-fill.svg"
                alt=""
              />
            </a>
            <a
              :href="cafe.social.facebook"
              v-if="cafe.social.facebook"
              class="icon"
              target="_blank"
            >
              <img
                src="/icons/desktop/cafe-card-facebook-black-icon.svg"
                alt=""
              /><img
                src="/icons/desktop/cafe-card-facebook-black-icon-fill.svg"
                alt=""
              />
            </a>
            <a
              :href="cafe.address_url_override || `https://maps.google.com/?q=${cafe.address}`"
              v-if="cafe.address"
              target="_blank"
              class="icon"
            >
              <img
                src="/icons/desktop/cafe-card-map-black-icon.svg"
                alt=""
              /><img
                src="/icons/desktop/cafe-card-map-black-icon-fill.svg"
                alt=""
              />
            </a>
          </div>
        </div>
        <div
          class="description"
          v-html="descriptionHtml"
          v-if="cafe.description"
        >

        </div>
        <div
          v-if="cafe.has_preview"
          @click="openMenuPreview"
          class="go-to-cafe-button"
        >
          {{ $t('choose_mode.preview_menu') }}<img
            src="/icons/desktop/arrow.svg"
            alt=""
          />
        </div>
        <div class="system-cards-container">
          <SystemCardForCafePage
            @select="onSelect"
            mode="delivery"
            :overrideTranslation="cafe._id == 126"
            :variant="cardsUiVariant"
            :active="cafe.modes.delivery"
            :minCheckCost="cafe.delivery.min_check"
            :overrideValue="cafe.delivery.ui_button_title"
            :showPanda="!cafe.ui_settings.hide_panda"
            v-if="cafe.modes.delivery"
          />
          <SystemCardForCafePage
            @select="onSelect"
            mode="preorder"
            :overrideTranslation="cafe._id == 126"
            :variant="cardsUiVariant"
            :active="cafe.modes.preorder"
            :overrideValue="cafe.preorder.ui_button_title"
            :showPanda="!cafe.ui_settings.hide_panda"
            v-if="cafe.modes.preorder"
          />

          <!-- <SystemCardForCafePage
            @select="onSelect"
            mode="reserve"
            :active="cafe.modes.reserve"
          /> -->
        </div>

        <div
          class="rate-cafe-block"
          @click="show_feedback = true"
          v-if="!feedback_sent"
        >
          <img
            src="/icons/desktop/dislike-cafe-icon.svg"
            alt=""
          />
          {{ $t("order_buttons.feedback") }}
          <img
            src="/icons/desktop/like-cafe-icon.svg"
            alt=""
          />
        </div>
      </div>
    </div>

    <RateCafePopup
      :mode="'choose_mode'"
      :order_id="cafe._id"
      @success="feedback_sent = true"
      @close="show_feedback = false"
      v-if="show_feedback"
    />

  </div>
</template>
<script>
import SystemCardForCafePage from './SystemCardForCafePage.vue'
import MarketplaceMap from '@/components/marketplace/MarketplaceMap.vue'
import RateCafePopup from './RateCafePopup.vue'

import MarketplaceService from '@/services/marketplace'

export default {
  props: {
    cafe: {
      type: Object
    },
    from_map: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      show_feedback: false,
      feedback_sent: false
    }
  },
  methods: {
    onSelect(mode) {
      if (this.from_map) {
        MarketplaceService.set(this.cafe.online_menu_link, true)
        console.log('set flag')
      }

      this.$router.push({
        name: mode,
        params: {
          code: this.$route.params.code || this.cafe.online_menu_link
        }
      })
    },
    openMenuPreview() {
      this.$router.push({
        name: 'menu_preview',
        params: {
          id: this.$route.params.code || this.cafe.online_menu_link
        }
      })
    }
  },
  computed: {
    cashbackPercent() {
      return (this.cafe.loyalty ? this.cafe.loyalty.cashback_percent : this.cafe.cashback_percent) || 0
    },
    cardsUiVariant() {
      return this.cafe.modes.delivery && this.cafe.modes.preorder ? 'duo' : 'solo'
    },
    descriptionHtml() {
      if (!this.cafe.description) return ''

      return this.cafe.description.split('\n').join('<br>')
    }
  },
  components: { SystemCardForCafePage, MarketplaceMap, RateCafePopup }
}
</script>
<style scoped>
.cafe-page-desktop {
  width: 100vw;
  height: 90vh;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
}

.scroll-area {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100vh;
  overflow: auto;
}

.bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 48vh;
  object-fit: cover;
  object-position: center;
}
.cafe-card-wrapper {
  position: relative;
  width: 100%;
  height: 45vh;
  top: 45vh;
}
.cafe-card-container {
  padding: 6.6vh 11.11111111111111vh 0;
  background: rgba(237, 237, 237, 0.8);
  backdrop-filter: blur(20px);
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

.cafe-card-container::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}

.cafe-image {
  width: 28.88888888888889vh;
  height: 14.444444444444445vh;
  position: absolute;
  z-index: 11;
  object-fit: cover;
  top: -11.11111111111111vh;
  left: 11.11111111111111vh;
  border-radius: 1.1111vh;
}
.rate-container {
  font-size: 1.7777777777777777vh;
  line-height: 2vh;
  width: 8.555555555555555vh;
  position: absolute;
  top: 0;
  left: 42.22222222222222vh;
}
.top-part {
  height: 3.3333vh;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(7px);
  color: #ededed;
  position: absolute;
  top: -3.33vh;
  padding: 0.6666666666666666vh 1.11111111111111vh;
  text-align: center;
  width: 100%;
}
.text {
  color: #1d1d1b;
}
.bottom-part {
  height: 3.3333vh;
  padding: 0.6666666666666666vh 1.11111111111111vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
  position: relative;
  width: 100%;
}
.bottom-part img {
  margin-left: 0.6666666666666666vh;
  height: 1.5555555555555556vh;
}
.card-header-desktop {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.text-img-row {
  display: flex;
  align-items: center;
  font-size: 2vh;
  line-height: 2.3333333333333335vh;
  color: #1d1d1b;
}
.text-img-row img {
  width: 2.3333333333333335vh;
  margin-right: 1.1111vh;
}
.description {
  width: 62.5%;
  font-size: 1.6666666666666667vh;
  line-height: 132%;
  color: #464646;
}
.icons-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.icon {
  border-radius: 0.4444vh;
  margin-left: 3.3333vh;
  cursor: pointer;
}

.borders {
  border-radius: 0.4444vh;
}

.icon img {
  width: 4.4444vh;
}
.icon img:last-child {
  display: none;
}
.icon:hover img:last-child {
  display: block;
}
.icon:hover img:first-child {
  display: none;
}
.system-cards-container {
  display: flex;
  margin-top: 3.3333vh;
  overflow: hidden;
  margin-bottom: 3.3333vh;
  border-radius: 1.1vh;
}
.rate-cafe-block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 41.666666666666664vh;
  height: 8.8vh;
  margin: 0 auto 0;
  font-size: 1.7777777777777777vh;
  line-height: 2vh;

  text-decoration-line: underline;
  cursor: pointer;
}
.rate-cafe-block img {
  width: 2.4444444444444446vh;
  cursor: pointer;
}
.rate-cafe-block img:last-child {
  margin-left: 1.6666666666666667vh;
}
.rate-cafe-block img:first-child {
  margin-right: 1.6666666666666667vh;
}
.go-to-cafe-button {
  width: 37.22222222222222vh;
  height: 5.555555555555555vh;
  border: 0.1111vh solid #1d1d1b;
  box-sizing: border-box;
  border-radius: 1.111vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.2222vh;
  font-size: 2vh;
  line-height: 132%;
  color: #1d1d1b;
  margin-top: 1.5vh;
  cursor: pointer;
}
.go-to-cafe-button img {
  width: 1.6666666666666667vh;
}
.map {
  z-index: 1 !important;
  pointer-events: none;
}
.cafe-page-map-backdrop {
  position: fixed;
  width: 100vw;
  height: 30vh;
  top: 8vh;
  z-index: 5;
}
</style>
