var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"preorder-mode-wrapper",style:(_vm.wrapper_style)},[(_vm.modes.delivery)?_c('div',{staticClass:"option delivery",class:{
        image: _vm.cafe.ui_settings ? !_vm.cafe.ui_settings.hide_panda : true
      },on:{"click":function($event){return _vm.onSelect(true)}}},[_c('h2',[_vm._v(_vm._s(_vm.deliveryTitle))]),(_vm.deliveryState)?_c('p',{staticClass:"order-description"},[_vm._v("\n        "+_vm._s(_vm.$t('delivery_bill.delivery_item'))+":\n        "),(_vm.deliveryState.type == 'free')?[_vm._v("\n          "+_vm._s(_vm.$t('delivery_bill.delivery_free'))+"\n        ")]:(_vm.deliveryState.type == 'constant')?[_vm._v("\n          "+_vm._s(_vm.deliveryState.cost)+_vm._s(_vm.currency.symbol)+"\n        ")]:[_vm._v("\n          "+_vm._s(_vm.$t('delivery_bill.delivery_additional'))+"\n        ")]],2):_vm._e(),(_vm.hasMinCheck)?_c('p',{staticClass:"order-description"},[_vm._v("\n        "+_vm._s(_vm.$t('mode_modal.min_check_header'))+": "+_vm._s(_vm.minCheckCost || 0)+_vm._s(_vm.currency.symbol)+"\n      ")]):_vm._e(),_c('div',{staticClass:"option-title del"},[_c('div',{staticClass:"border-btn"},[_c('h3',[_vm._v("\n            "+_vm._s(_vm.$t("choose_mode.view_menu"))+"\n          ")]),_c('img',{staticClass:"arrow-next",attrs:{"src":"/icons/arrow-white.svg"}})])])]):_vm._e(),(_vm.modes.preorder)?_c('div',{staticClass:"option preorder",class:{
        image: _vm.cafe.ui_settings ? !_vm.cafe.ui_settings.hide_panda : true,
        'bagel': _vm.cafe._id == 522 || _vm.cafe._id == 1
      },on:{"click":function($event){return _vm.onSelect(false)}}},[_c('h2',[_vm._v(_vm._s(_vm.preorderTitle))]),_c('div',{staticClass:"option-title pre"},[_c('div',{staticClass:"border-btn"},[_c('h3',[_vm._v("\n            "+_vm._s(_vm.$t("choose_mode.view_menu"))+"\n          ")]),_c('img',{staticClass:"arrow-next",attrs:{"src":"/icons/arrow-white.svg"}})])])]):_vm._e(),(false)?_c('div',{staticClass:"option reserve mb-0",class:{
        image: _vm.cafe.ui_settings ? !_vm.cafe.ui_settings.hide_panda : true
      },on:{"click":function($event){return _vm.reserve()}}},[_c('h2',[_vm._v(_vm._s(_vm.reserveTitle))]),_c('div',{staticClass:"option-title res"},[_c('div',{staticClass:"border-btn"},[_c('h3',[_vm._v("\n            "+_vm._s(_vm.$t("choose_mode.go_reserve"))+"\n          ")]),_c('img',{staticClass:"arrow-next",attrs:{"src":"/icons/arrow-white.svg"}})])])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }