<template>
  <div
    v-if="active"
    @click="$emit('select', mode)"
    :class="`system-card bg-${mode} ${variant}`"
  >
    <div class="card-title">{{ modeTitle }}</div>
    <!-- <div class="card-description">
      {{ $t("choose_mode." + mode) }}
    </div> -->
    <div class="big-text mb-5vh" v-if="deliveryState">
      {{ $t("mode_modal.delivery_price_header") }}
    </div>
    <div class="small-text mb-30vh" v-if="deliveryState">
      {{
        $t(`mode_modal.delivery_types.${deliveryState.type}`, {
          cost: deliveryState.cost,
          currency: currency.symbol,
        })
      }}
    </div>
    <div
      class="small-text mb-30vh"
      v-if="deliveryState && deliveryState.min_check"
    >
      {{
        $t(`mode_modal.delivery_types.conditional`, {
          min_check: deliveryState.min_check,
          currency: currency.symbol,
        })
      }}
    </div>
    <!-- <div class="big-text mb-5vh" v-if="hasMinCheck">
            {{ $t("mode_modal.min_check_header") }}
          </div> -->
    <div class="small-text mb-30vh" v-if="hasMinCheck">
      {{ $t("mode_modal.min_check_header") }} {{ minCheckCost || 0
      }}{{ currency.symbol }}
    </div>
    <div class="go-to-system-link">
      <div class="border-btn">
        <h3>
          {{ $t("choose_mode.view_menu") }}
        </h3>
        <img src="/icons/arrow-white.svg" class="arrow-next" />
      </div>
    </div>
    <img :src="cardPanda" alt="" class="card-panda" v-if="showPanda"/>
    <img :src="cardCornerLine" alt="" class="corner-line" v-if="showPanda"/>
  </div>
  <div v-else class="empty-system-card system-card">
    Підключити бронювання столів <img src="/icons/desktop/add.svg" alt="" />
  </div>
</template>
<script>
export default {
  props: {
    mode: {
      type: String,
      default: "delivery",
    },
    active: {
      type: Boolean,
      default: false,
    },
    variant: { type: String, default: "trio" }, //solo,duo,trio
    deliveryState: Object,
    minCheckCost: Number,
    overrideTranslation: Boolean,
    overrideValue: String,
    showPanda: Boolean
  },
  computed: {
    hasMinCheck() {
      return !isNaN(this.minCheckCost);
    },
    currency() {
      return this.$store.state.currency.current;
    },
    cardCornerLine() {
      return `/icons/desktop/cafe-system-card-line-${this.mode}.svg`;
    },
    cardPanda() {
      return `/icons/desktop/cafe-system-card-panda-${this.mode}.svg`;
    },
    modeTitle() {
      if (this.overrideValue) {
        return this.overrideValue;
      }

      if (this.overrideTranslation) {
        return {
          delivery: 'ДОСТАВКА',
          preorder: 'ПРЕДЗАКАЗ'
        }[this.mode];
      }

      

       return this.mode;
    }
  },
};
</script>
<style scoped>
.system-card {
  padding: 2.222vh 2.2222vh 1.6666666666666667vh;
  cursor: pointer;
  height: 23.666666666666668vh;
  position: relative;
  overflow: hidden;
}

.small-text {
  font-size: 1.66vh;
}

.solo {
  width: 100%;
}
.duo {
  width: 50%;
}
.trio {
  width: 33.3333333%;
}

.card-title {
  font-weight: bold;
  font-size: 3.33333vh;
  line-height: 132%;
  color: #1d1d1b;
  margin-bottom: 0.5555555555555556vh;
  text-transform: uppercase;
}
.card-description {
  width: 22.77777777777778vh;
  height: 11.666666666666666vh;
  font-size: 15px;
  line-height: 132%;
  color: #1d1d1b;
  margin-bottom: 0.5555555555555556vh;
}
.go-to-system-link {
  font-size: 15px;
  line-height: 132%;
  color: #1d1d1b;
  position: absolute;
  bottom: 2.2vh;
  display: flex;
  align-items: center;
}
.go-to-system-link img {
  margin-left: auto;
  height: 1.3vh;
  width: auto;
  transform: rotate(270deg);
}

.border-btn {
  border: 1px solid #1d1d1b;
  padding: 1.2vh 1.5vh;
  border-radius: 0.7vh;
  align-items: center;
  width: 31vh;
  display: flex;
}

.border-btn h3 {
  font-size: 2vh;
  margin: 0;
  color: #1d1d1d;
  line-height: normal;
}

.card-panda {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 3;
}
.corner-line {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 1;
}
.bg-delivery {
  background: #cbd9e6;
}
.bg-preorder {
  background: #ffd2a8;
}
.bg-reserve {
  background: #aeccc6;
}
.empty-system-card {
  border: 0.22222vh solid #1d1d1b;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 1.7777777777777777vh;
  line-height: 132%;
  color: #1d1d1b;
  justify-content: center;
}
.empty-system-card img {
  width: 2.6666666666666665vh;
  margin-left: 2.2222vh;
}
</style>
