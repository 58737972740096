<template>
  <div>
    <div class="snake-wrapper" v-if="!client_modal && !phone_confirm_modal">
      <img src="/icons/cross-black.png" class="snake-close" @click="emitClose" v-if="showClose" />

      <div class="canvas-wrapper" id="snake_canvas_host">
        <canvas id="snake_canvas"></canvas>
      </div>

      <div class="snake-controls">
        <img
          src="../assets/snake_control_left.svg"
          class="snake-left"
          @click="sendGameInput('left')"
          alt="Snake Left Button"
        />
        <img
          src="../assets/snake_control_up.svg"
          class="snake-up"
          @click="sendGameInput('up')"
          alt="Snake Up Button"
        />
        <img
          src="../assets/snake_control_down.svg"
          class="snake-down"
          @click="sendGameInput('down')"
          alt="Snake Down Button"
        />
        <img
          src="../assets/snake_control_right.svg"
          class="snake-right"
          @click="sendGameInput('right')"
          alt="Snake Right Button"
        />
      </div>
    </div>

    <PhoneConfirmModal
      :token="phone_confirm_token"
      @confirmed="onPhoneConfirmed"
      @resend="askPhoneConfirmation"
      @cancel="cancelPhoneConfirmation" 
      v-if="phone_confirm_modal"
      />

    <ClientInfoModal
      @confirm="onClientInfoConfirm" 
      v-if="client_modal"/>
  </div>
</template>

<script>
import SnakeGame from "@/services/snake";
import GamesAPI from "@/api/games";

import SecurityAPI from "@/api/security";

import ClientInfoModal from "@/components/modals/ClientInfoModal.vue";
import PhoneConfirmModal from "@/components/modals/PhoneConfirmModal.vue";

export default {
  data() {
    return {
      game: null,
      sid: null,

      phone_confirm_modal: false,
      phone_confirm_token: null,
      client_modal: false,
      client_data: {}
    };
  },
  props: {
    source: {
      type: String,
      default: "n/a"
    },
    user: {
      type: String,
      default: null
    }
  },
  methods: {
    sendGameInput(type) {
      if (type === "left") this.game.input(-1, 0);
      if (type === "right") this.game.input(1, 0);
      if (type === "up") this.game.input(0, -1);
      if (type === "down") this.game.input(0, 1);
    },
    emitClose() {
      this.$emit("close");
    },
    initSession() {
      const source = this.$route.query.source || this.source;
      let user = this.user;

      if (this.$store.getters['clients/isAuthorized']) {
        const client = this.$store.state.clients.client;

        user = `${client.name} ${client.phone}`;
      }

      GamesAPI.createSession('snake', source, user).then(data => {
        this.sid = data.id;
      }).catch(error => {
        //silent.
      });
    },
    onHighScore(score) {
      GamesAPI.updateScore(this.sid, score);
    },
    launch() {
      const screen_w =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      const screen_h =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

      const canvas_host = document.getElementById("snake_canvas_host");

      // const canvas_w = screen_w;
      // const canvas_h = screen_h - 108; //108 = button height

      const canvas_w = canvas_host.offsetWidth - 4;
      const canvas_h = canvas_host.offsetHeight - 4;

      const callbacks = {
        onHighScore: this.onHighScore
      };

      this.game = new SnakeGame(
        document.getElementById("snake_canvas"),
        canvas_w,
        canvas_h,
        callbacks
      );
      this.game.start();

      this.initSession();
    },
    onClientInfoConfirm(data) {
      this.client_data = data;
      this.client_modal = false;
      this.askPhoneConfirmation();
    },
    cancelPhoneConfirmation() {
      this.phone_confirm_modal = false;
      this.emitClose();
    },
    askPhoneConfirmation() {
      this.phone_confirm_modal = true;

      SecurityAPI.ask(this.client_data.phone)
        .then(data => {
          this.phone_confirm_token = data.token;
        })
        .catch(error => {
          alert(
            `Сталася помилка підтвердження. Будь ласка, спробуйте пізніше.`
          );
        });
    },

    onPhoneConfirmed() {
      this.$store.dispatch('clients/create', this.client_data).finally(() => {
        this.phone_confirm_modal = false;
        this.$nextTick(this.launch);
      });
    }
  },
  computed: {
    showClose() {
      return this.$route.name != "snake";
    }
  },
  mounted() {
    // if (this.$store.getters['clients/isAuthorized']) {
    //   this.launch();
    // } else {
    //   this.client_modal = true;
    // }
    this.launch();
  },
  beforeDestroy() {
    if (this.game) this.game.stop();
  },
  components: {
    ClientInfoModal,
    PhoneConfirmModal
  }
};
</script>

<style>
.snake-wrapper {
  width: 100vw;
  height: 100vh;

  position: fixed;

  top: 0;
  left: 0;

  z-index: 1000;

  background-color: #1d1d1b;
}

.snake-controls {
  position: fixed;
  bottom: 0px;
  left: 0;
}

.snake-left {
  position: fixed;
  left: 5vw;
  bottom: 10px;
}

.snake-right {
  position: fixed;
  right: 5vw;
  bottom: 10px;
}

.snake-up {
  position: fixed;
  left: 35.5vw;
  bottom: 67px;
}

.snake-down {
  position: fixed;
  left: 35.5vw;
  bottom: 10px;
}

.snake-close {
  width: 24px;
  height: 24px;
  color: red;
  font-weight: bold;
  background-color: white;
  z-index: 1010;
  position: fixed;
  top: 16px;
  right: 16px;
  text-align: center;
  border-radius: 50%;
  background: #262626;
}

.canvas-wrapper {
  border: 2px dashed white;
  border-radius: 5px;

  margin-top: 60px;

  width: 90vw;
  height: 65vh;
  margin-left: 5vw;

  z-index: 1200;
}

.snake-close {
  width: auto;
  height: 4.2vh;
  opacity: 1;
  right: 5.5vw;
  top: 10px;
  position: absolute;
}
</style>