export default {
    namespaced: true,
    state: {
        info_popup_variant: null,
        popup_error: null
    },
    mutations: {
        setInfoPopupVariant(state, payload) {
            state.info_popup_variant = payload;
        },
        setPopupError(state, payload) {
            state.popup_error = payload;
        }
    }
}