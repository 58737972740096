import Vue from 'vue'


export default {
    namespaced: true,

    state: {
        cafe: null,
        menu: null,
        target: null,
    },

    mutations: {
        
    },

    actions: {
        
    },

    getters: {
        
    }
};