import BaseAPI from '../base'

function createSession(game, source, user) {
    return BaseAPI.sendPost('/games/init', {
        game,
        source,
        user
    });
}

function updateScore(id, score) {
    return BaseAPI.sendPost(`/games/score/${id}`, {
        score
    });
}

export default {
    createSession,
    updateScore
}