import BaseAPI from '../base'

function ask(phone, cafe_id) {
    return BaseAPI.sendPost(`security/ask`, {
      phone,
      cafe_id: cafe_id || null
    });
}

function check(token, code) {
    return BaseAPI.sendPost(`security/check/${token}`, {
        code
    });
}

export default {
    ask,
    check
}