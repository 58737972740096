<template>
  <div>
    <nav v-thematic :style="{top: uiBannerShown ? '5.71vh':'0'}" :class="isExtendedNavbar ? 'divider' : ''">
      <!-- <img
        @click="extendNavbar"
        src="/icons/logo.svg"
        alt="logo"
        class="qr-logo"
      />-->
      <!-- <div class="arrow-block" v-if="!isInside">
        <img
          src="../assets/arrow.svg"
          @click="extendNavbar"
          :class="isExtendedNavbar ? 'arrow rotate':'arrow'"
        />
      </div> -->
      <div class="info-box">
        <img
          src="../assets/arrow-back.svg"
          class="arrow-back"
          @click="$emit('go-back')"
          v-if="showArrowBack"
        />
        <div v-if="show_status" @click="extendNavbar">
          <span
            v-if="target.preorder || target.reserve"
            class="table-num color-grey"
          >
            {{ modeTitle }}

            <!-- <span v-if="target.demo" class="color-red"> DEMO </span> -->
          </span>

          <span
            v-else-if="!target.lite && target.demo"
            class="table-num color-grey"
            >Inside
           <!-- <span v-if="target.demo" class="color-red"> DEMO </span>--></span
          >
          <span
            v-else-if="!target.lite && !target.demo"
            class="table-num color-grey"
            >{{ $t(isHotel ? "navbar.room_no" : "navbar.table_no") }}
            {{ orderTableDisplayName }}</span
          >
          <span :class="cafeTitleClass">{{ cafe.name }}</span>
        </div>

        <!-- <span class="color-grey">
            {{ $t('preorder_modes.how') }}
        </span>-->
      </div>

      <!-- <span
        class="waiter-options"
        @click="toggleCallServices"
        v-if="canShowServices && !isShowingCallServices"
      ></span>-->
      <!-- {{ $t('navbar.services') }} -->
      <img
        @click="$emit('giftClicked')"
        v-if="canShowGift"
        class="gift-icon"
        :src="'/icons/gift-white.svg'">
      <img
        @click="extendNavbar"
        :src="cafe.image || '/icons/logo.svg'"
        alt="cafe-logo"
        :class="cafe.image ? 'cafe-logo' : 'qr'"
      />

      <!-- <div v-if="target.preorder" class="waiter-options">
        <img class="preorder-lang" src="/flags/global.svg" @click="toggleLanguageModal(true)" />
      </div> -->
    </nav>

    <div v-if="show_how" class="variant-text color-white">
      {{ $t("preorder_modes.how") }}
    </div>

    <div v-if="isExtendedNavbar" class="nav-info">
      <div>
        <div class="bonus-balance" v-if="isLoyaltyEnabled">
          <span class="text">{{ $t("pay_methods.bonus_balance") }}</span>
          <span class="value"
            >{{ bonusBalanceValue }} {{ currency.iso_code }}</span
          >
        </div>
        <div
          :class="{
            'contact-info': true,
            'no-space': !isLoyaltyEnabled,
          }"
        >
          <a
            :href="`tel:${cafe.contact_phone}`"
            @click="onContactClicked('phone', cafe.contact_phone)"
            v-if="cafe.contact_phone">
            <img src="/icons/navbar/phone.svg" />
          </a>
          <a
            :href="cafe.social.instagram"
            target="_blank"
            @click="onContactClicked('instagram', cafe.social.instagram)"
            v-if="cafe.social && cafe.social.instagram"
          >
            <img src="/icons/inst.png" />
          </a>
          <a
            :href="websiteLink"
            target="_blank"
            @click="onContactClicked('website', cafe.social.website)"
            v-if="cafe.social && cafe.social.website"
          >
            <img src="/icons/website.svg" />
          </a>
          <a
            :href="cafe.social.facebook"
            target="_blank"
            @click="onContactClicked('facebook', cafe.social.facebook)"
            v-if="cafe.social && cafe.social.facebook"
          >
            <img src="/icons/navbar/facebook.svg" />
          </a>

          <img
            src="/icons/navbar/language.svg"
            @click="toggleLanguageModal(true)"
            v-if="!choosing"
          />

          <img
            src="/icons/navbar/options.svg"
            class="menu"
            @click="toggleCallServices"
            v-if="isInside && canShowServices && !isShowingCallServices"
          />

          <a
            :href="cafe.address_url_override || `https://maps.google.com/?q=${cafe.address}`"
            target="_blank"
            @click="onContactClicked('address', cafe.address)"
            v-if="!isInside && cafe.address_location"
          >
            <img src="/icons/navbar/map.svg" />
          </a>
        </div>
        <a
          :href="offertLink"
          target="_blank"
          class="offert-link"
          v-if="canShowPublicOffert"
        >
          {{ $t("navbar.offert") }}
        </a>

      </div>
    </div>

    <div class="options-list" v-thematic v-if="isShowingCallServices">
      <img
        src="../assets/close.png"
        class="close-list"
        @click="toggleCallServices()"
      />
      <!-- <h4 v-thematic>Оберіть послугу:</h4> -->
      <ul>
        <li
          v-thematic
          :class="makeClass(srv.name)"
          v-for="srv in cafe.call_services"
          @click="callService(srv.name)"
        >
          {{ srv.name }}
          <span v-if="timeouts[srv.name]">({{ timeouts[srv.name] }} {{ $t('units.secs') }})</span>
        </li>
      </ul>

      <div @click="toggleCallServices()" class="close-options">
        <div class="btn-back">
          <img src="/icons/arrow-left-black.svg" />
        </div>
      </div>
    </div>

    <GeoModal @confirm="fetchGeolocation" v-if="geoModalActive" />
    <LanguageModal
      @switch="switchLocale"
      @close="toggleLanguageModal(false)"
      v-if="languageModal"
    />
    <CafeSchedule
      :schedule="cafe.schedule"
      @close="toggleScheduleModal(false)"
      v-if="scheduleModal"
    />

    <MarketplaceMap
      :canShowDetails="false"
      :cafes="[cafe]"
      @close="showingMap = false"
      v-if="showingMap"
    />
  </div>
</template>

<style scoped>
nav {
  padding: 1.8vh 2.5vh;
  height: 7.7vh;
  /* animation: slide-nav 0.3s; */
  display: flex;
  z-index: 6;
  position: fixed;
  align-items: center;
  width: 100%;
  background: #1d1d1b;

  top: 0;
  justify-content: space-between;
}

.info-box {
  position: relative;
  left: 0;
  display: flex;
  width: inherit;
  align-items: center;
}

.divider {
  border-bottom: 1px solid #908f8d;
}

.divider.dark {
  border-bottom: 1px solid #908f8d;
}

img.arrow-back {
  margin-right: 1.2vh;
  margin-left: -0.8vh;
  height: 3.2vh;
  width: auto;
}

.nav-info {
  background: #1d1d1b;
  color: #999999;
  position: fixed;
  animation: slide 0.3s both;
  width: 100%;
  z-index: 5;
  top: 7.4vh;
  border-radius: 0 0 2.5vh 2.5vh;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 2.3vh 5.4vw 2.5vh;
}

.bonus-balance {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.bonus-balance .text {
  font-size: 1.8vh;
  padding-top: 1vh;
  color: #908f8d;
}

.bonus-balance .value {
  font-size: 2.2vh;
  padding-top: 1vh;
  color: #129b81;
}

.contact-info {
  display: flex;
  justify-content: space-between;
  padding-top: 2vh;
}

.no-space {
  padding: 0;
}

.contact-info.no-margin {
  margin-bottom: 0;
}

.contact-info img {
  height: 5vh !important;
  width: auto;
}

/* .contact-info img.menu {
  height: 3vh !important;
  margin-top: 1vh;
} */

.arrow-block {
  width: 4.2vh;
  height: 3.5vh;
  margin-right: 1.8vh;
  position: relative;
}

img.arrow {
  display: block;
  transition: 0.3s;
  height: 100%;
  width: 100%;
  transform: rotate(-180deg);
}

img.arrow.rotate {
  transform: rotate(-90deg);
}

@keyframes slide {
  0% {
    opacity: 0;
    top: 0vh;
  }
  100% {
    opacity: 1;
  }
}

.nav-info span {
  float: right;
  padding-top: 2.1vh;
  line-height: 0.8;
}

.variant-text {
  position: fixed;
  top: 7vh;
  z-index: 2;
  font-size: 2vh;
  width: 100%;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 2vh 2vh;
  text-align: center;
  color: #a4a3a1;

  background: #1d1d1d;
  padding-bottom: 2vh;
}

.close-options {
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: fixed;
  z-index: 2;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.21) 0%,
    rgba(255, 255, 255, 0) 100%
  );

  backdrop-filter: blur(3px);
  padding: 2.4vh 5.4vw;
  margin: 0;
  bottom: -0.1vh;
}

.btn-back {
  background: #aeccc6;
  border-radius: 5vh;
}

.close-options img {
  height: 2.3vh;
  width: 2.3vh;
  margin: 2.5vh;
}

.nav-info img {
  width: auto;
  height: 3.7vh;
}

@keyframes slide-nav {
  0% {
    opacity: 0;
    top: -100px;
  }
  100% {
    top: 0;
  }
}

.options-list {
  width: 100%;
  height: 100%;
  top: 0vh;
  overflow-x: hidden;
  position: fixed;
  background: #ededed;
  z-index: 3;
}

.options-list.dark {
  background: black;
  color: #ededed;
}

.options-list .close-list {
  right: 5.4vw;
  top: 2.5vh;

  height: 4.6vh;
  width: auto;
  position: fixed;
}

.options-list h4 {
  text-align: left;
  padding: 2vh 2.5vh;
  margin: 0;
  font-size: 3vh;
  position: fixed;
  width: 100%;
  top: 7vh;
  background: #ededed;
  color: #908f8d;
}

.options-list h4.dark {
  background: black;
}

.options-list ul {
  list-style: none;
  text-align: center;
  padding-left: 0;
  font-size: 2.3vh;
  max-height: 80vh;
  overflow: auto;
  margin: 2.5vh 0;
}

.options-list li:first-child {
  margin-top: 8vh;
}

.options-list li:last-child {
  margin-bottom: 8vh;
}

.options-list li {
  padding: 1.5vh 0;
  font-weight: bold;
  font-size: 5vw;
  background: #edededed;
  color: #464646;
  border-radius: 5vh;
  border: 2px solid #464646;
  font-weight: 400;
  margin: 2.5vh 5.4vw 0;
  color: black;
}

.options-list li.dark {
  background: transparent;
  color: #ededed;
}

.cafe-logo {
  height: 100%;
  width: 7.9vh;
  object-fit: cover;
  border-radius: 5vh;
  position: relative;
}

.table-num {
  position: relative;
  left: 0;
  line-height: normal;
  display: block;
  vertical-align: super;
  font-weight: 500;
  /* font-size: 3.8vw; */
  font-size: 1.61vh;
}

.cafe-name {
  display: block;
  line-height: normal;
  left: 0;
  position: relative;
  font-size: 2.24vh;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 400;
  max-width: 29vh;
  white-space: nowrap;
}
.waiter-options {
  color: white;
  font-weight: 500;
}
span img {
  height: 3vh;
  width: auto;
  position: relative;
  top: 0;
}

.service-muted {
  color: white !important;
  background: #129b81 !important;
  border: none !important;
  padding: 1.7vh 0 !important;
}

.name-only {
  font-size: 5vw;
  position: relative;
  left: 0;
  text-align: center;
  padding: 0.7vh 0 0 0;
  width: 65vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.preorder-lang {
  width: 3.7vh;
  height: 3.7vh;
  position: relative;
}

.nav-bonus-container {
  display: flex;
  justify-content: space-between;
}

.nav-bonus-title {
  color: #464646;
}

.offert-link {
  display: block;
  padding-top: 2vh;
  color: #908f8d;
  font-size: 4vw;
}

.nav-bonus-value {
  color: #129b81;
}

.gift-icon {
  margin-right: 16px;
}

@media screen and (max-height: 667px) {
  .cafe-name,
  .table-num {
    top: 0;
  }
}
</style>

<script>
import GeolocationService from "@/services/geolocation";
import ScheduleService from "@/services/schedule";

import GeoModal from "@/components/GeoModal.vue";

import OrderAPI from "@/api/order";

import Polyglote from "@/services/polyglote";

import LanguageModal from "@/components/LanguageModal.vue";

import CafeSchedule from "@/components/CafeSchedule.vue";

import TelemetryService from "@/services/telemetry";

import MarketplaceMap from "@/components/marketplace/MarketplaceMap.vue";

import AnalyticsAPI from "@/api/analytics";

export default {
  props: {
    cafe: Object,
    target: Object,
    geolocation: Object,
    is_preview: Boolean,
    view: String,
    canShowGift: Boolean,
    show_status: {
      type: Boolean,
      default: true,
    },
    show_how: {
      type: Boolean,
      default: false,
    },
    choosing: {
      type: Boolean,
      default: false,
    },
    hide_services: {
      type: Boolean,
      default: false,
    },
    is_locked: {
      type: Boolean,
      default: false,
    },
    menu: {
      type: Object,
      default: {},
    },
    showArrowBack: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      freshGeolocation: "",
      isShowingCallServices: false,

      timeouts: {},
      geoModalActive: false,

      selectedService: null,
      isExtendedNavbar: false,

      remainingTime: null,
      isClosingSoon: false,
      showingMap: false,

      // currentLocale: "uk",

      languageModal: false,
      scheduleModal: false,
      hideBanner: false,
    };
  },
  methods: {
    toggleCallServices() {
      this.isShowingCallServices = !this.isShowingCallServices;
      // this.toggleGlobalScrolling(this.isShowingCallServices);
      // this.resetScroll();
      this.$emit("toggleCallServices", this.isShowingCallServices);

      if (this.isShowingCallServices) {
        this.collapseNavbar();
      }
    },
    fetchGeolocation() {
      GeolocationService.getPosition()
        .then((pos) => {
          GeolocationService.cacheGeolocationPermission();

          OrderAPI.callService(this.target, this.selectedService, pos)
            .then((data) => {
              // this.toggleCallServices();

              this.$set(this.timeouts, this.selectedService, 60);

              this.geoModalActive = false;
            })
            .catch((error) => {
              this.geoModalActive = false;
              alert(error);
            });
        })
        .catch((error) => {
          this.geoModalActive = false;
        });
    },
    callService(name) {
      if (this.timeouts[name] || this.timeouts[name] > 0) {
        return;
      }

      this.selectedService = name;

      if (GeolocationService.isPermissionCached()) {
        this.fetchGeolocation();
      } else {
        this.geoModalActive = true;
      }
    },
    timeoutTimer() {
      if (Object.keys(this.timeouts).length > 0) {
        for (let k in this.timeouts) {
          if (this.timeouts.hasOwnProperty(k)) {
            this.timeouts[k]--;

            if (this.timeouts[k] <= 0) {
              this.$delete(this.timeouts, k);
            }
          }
        }
      }
    },
    extendNavbar() {
      if (this.isShowingCallServices || this.is_locked) return;
      // if (!this.isInside && !this.isLoyaltyEnabled) return;

      this.isExtendedNavbar = !this.isExtendedNavbar;
    },
    collapseNavbar() {
      if (this.isExtendedNavbar) this.isExtendedNavbar = false;
    },
    makeClass(name) {
      return {
        "service-muted": this.timeouts[name] ? true : false,
      };
    },
    processSchedule() {
      if (!this.cafe.schedule) return;

      const helper = new ScheduleService.ScheduleHelper(this.cafe.schedule);

      if (helper.isOpen()) {
        this.isOpen = true;

        // this.scheduleString = helper.format();

        // if (!this.scheduleString) {
        //   this.scheduleString = this.$t("greeting.free_day");
        // }

        this.remainingTime = helper.remainingTime(
          this.$t("units.hours"),
          this.$t("units.minutes")
        );

        this.isClosingSoon = helper.isClosingSoon();
      } else {
        this.isOpen = false;
      }
    },
    loadLocale() {
      // this.currentLocale = Polyglote.getSavedLocale();
    },
    switchLocale(locale) {
      let system_language = ["uk", "ru", "en", "cs", 'tr'].includes(locale)
        ? locale
        : "en";

      if (system_language === 'ru') system_language = 'rus';

      Polyglote.updateLocale(system_language);

      this.$i18n.locale = system_language;

      this.toggleLanguageModal(false);

      this.processSchedule();

      TelemetryService.emit(TelemetryService.Events.ChangeLocale, locale);

      // this.$store.dispatch("menu/tryLocale", locale);
      this.$store.dispatch("menu/buildAllTranslations", [locale]);
      this.$store.commit("menu/addBuiltinTranslations", system_language);
    },
    toggleScheduleModal(enabled) {
      this.scheduleModal = enabled;
      this.toggleGlobalScrolling(enabled);
    },
    toggleLanguageModal(enabled) {
      this.isExtendedNavbar = false;
      this.languageModal = enabled;
      this.toggleGlobalScrolling(enabled);
    },
    onContactClicked(type, value) {
      // console.log(type, value);
      AnalyticsAPI.registerEvent({
        event_type: "cafe_contact_clicked",
        target: this.cafe ? this.cafe._id : null,
        platform: "web",
        payload: {
          source: type,
          value,
        },
      });
    },
  },
  mounted() {
    setInterval(this.timeoutTimer, 1000);

    this.loadLocale();

    this.processSchedule();

    window.addEventListener("scroll", this.collapseNavbar);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.collapseNavbar);
  },
  computed: {
    uiBannerShown() {
      return this.$store.state.uiBannerShown;
    },
    canShowServices() {
      return (
        !this.target.lite &&
        !this.hide_services &&
        !this.target.preorder &&
        this.target.mode != "hotels" &&
        this.cafe.call_services &&
        this.cafe.call_services.length > 0
      );
    },
    cafeTitleClass() {
      return {
        "cafe-name": true,
        "color-white": true,
        "name-only": !this.show_status,
      };
    },
    currentLocale() {
      return this.$i18n.locale;
    },
    isInside() {
      return !this.target.preorder;
    },
    bonusBalanceValue() {
      return this.$store.getters["clients/bonusBalance"];
    },
    isLoyaltyEnabled() {
      return (
        this.cafe.loyalty &&
        this.cafe.loyalty.cashback_percent &&
        this.cafe.loyalty.terms_agreed &&
        this.$store.getters["clients/isAuthorized"]
      );
    },
    currency() {
      return this.$store.state.currency.current;
    },
    modeTitle() {
      if (this.is_preview) return 'Preview';
      if (this.choosing) return "";
      if (this.target.reserve) return "Reserve";
      return this.target.is_delivery ? "Delivery" : "Takeaway";
    },
    canShowPublicOffert() {
      if (this.cafe.pay_methods && this.cafe.pay_methods.online) return true;
      if (
        this.cafe.preorder &&
        this.cafe.preorder.pay_methods &&
        this.cafe.preorder.pay_methods.online
      )
        return true;
      if (
        this.cafe.delivery &&
        this.cafe.delivery.pay_methods &&
        this.cafe.delivery.pay_methods.online
      )
        return true;
      if (
        this.cafe.hotels &&
        this.cafe.hotels.pay_methods &&
        this.cafe.hotels.pay_methods.online
      )
        return true;

      return false;
    },
    offertLink() {
      // const raw = JSON.stringify(this.cafe.bank_details);
      // const value = btoa(raw);
      return `/offert_view/${this.cafe._id}/${this.menu._id}`;
    },
    orderTableDisplayName() {
      const table_number = this.target.room || this.target.table;
      if (!table_number || !this.cafe) return;

      const section = this.cafe.sections[this.target.section];

      const hall_suffix = this.cafe.sections[this.target.section] ? ` (${this.cafe.sections[this.target.section].name})` : '';

      if (
        section &&
        section.tables_list &&
        section.tables_list[table_number - 1]
      ) {
        const table_item = section.tables_list[table_number - 1];

        if (table_item.name) return table_item.name + hall_suffix;
      }

      return `№ ${table_number}` + hall_suffix;
    },
    isHotel() {
      return this.target.mode == "hotels";
    },
    websiteLink() {
      if (!this.cafe || !this.cafe.social.website) return;

      const v = this.cafe.social.website;

      if (!v.startsWith('https://')) {
        return `https://${v}`;
      } else {
        return v;
      }
    }
  },
  components: {
    GeoModal,

    LanguageModal,
    CafeSchedule,
    MarketplaceMap,
  },
};
</script>
