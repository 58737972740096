<template>
  <div class="client-info-wrapper">
    <div class="hide-content"></div>
    <div class="client-info-content">
      <h4 class="client-info-header">{{ $t('preorder_time_modal.personal_title') }}</h4>
      <img src="../../assets/hi-hero-deliv.svg" class="client-info-image" />
      <input
        v-model="client.name"
        type="text"
        :class="['personal-info-input mt-30', !validation.name ? 'red-input' : '']"
        :placeholder="$t('preorder_time_modal.call_you')"
        maxlength="32"
      />

      <input
        v-imask="phone_mask"
        @complete="onPhoneMaskComplete"
        :value="client.phone"
        type="text"
        :class="['personal-info-input', !validation.phone ? 'red-input' : '']"
        :placeholder="$t('preorder_time_modal.phone')"
      />

      <LongButton @click="onConfirm" class="confirm-button">{{ $t('cart.next') }}</LongButton>
    </div>
  </div>
</template>

<script>
import { IMaskDirective } from "vue-imask";

export default {
  data() {
    return {
      client: {
        name: null,
        phone: null
      },
      validation: {
        name: true,
        phone: true
      },
      phone_mask: {
        mask: "{+38}-000-000-00-00",
        lazy: false
      }
    };
  },
  methods: {
    onPhoneMaskComplete(ev) {
      const detail = ev.detail;

      this.client.phone = detail.unmaskedValue;
      this.validation.phone = true;
    },
    onConfirm() {
      if (!this.client.name || this.client.name < 3) {
        this.validation.name = false;
        return;
      }

      if (!this.client.phone || this.client.phone.length < 7) {
        this.validation.phone = false;
        return;
      }

      this.$emit('confirm', this.client);
    }
  },
  directives: {
    imask: IMaskDirective
  },
  mounted() {
    this.toggleGlobalScrolling(true);
  },
  beforeDestroy() {
    this.toggleGlobalScrolling(false);
  }
};
</script>

<style scoped>
.hide-content {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.4);
  z-index: 2;
  top: 0;
  left: 0;
}

.client-info-content {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: block;
  z-index: 3;
  margin: 0 auto;
  background: #1d1d1b;
  width: 90vw;
  top: 50%;
  border-radius: 2.7vh 2.7vh 5vh 5vh;
  padding: 3.7vh 5.4vw;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  position: absolute;
}

.client-info-header {
  font-size: 6.2vw;
  color: #ededed;
  padding-bottom: 1.9vh;
  font-weight: 400;
  margin-bottom: 0;
  text-align: center;
}

.personal-info-input {
  outline: none;
  background: #ededed;
  width: 100%;
  border-radius: 10vw;
  text-align: center;
  color: black;
  display: block;
  margin: 0 auto;
  border: 1px solid #a4a3a1;
  margin-top: 2.5vh;
  padding: 1.8vh 0;
}

.client-info-image {
  margin: 0 auto;
  width: 51.2vw;
  height: auto;
  display: block;
}

.red-input {
  border: 2px solid #e2223b !important;
} 
</style>