<template>
  <div class="rate-cafe-popup">
    <div class="rate-cafe-backdrop" @click="$emit('close')"></div>
    <div class="flex-box-column" v-if="!sent">
      <div class="rate-cafe-popup-body">
        <div class="rate-cafe-title">
          {{ $t("feedback.title") }}
        </div>
        <div class="slider">
          <div
            :class="{
              'slider-item': true,
              'slider-item-active': selected_slider_tab == 1,
            }"
            @click="selected_slider_tab = 1"
          >
            {{ $t("feedback.for_cafe") }}
          </div>
          <div
            :class="{
              'slider-item  ': true,
              'slider-item-active': selected_slider_tab == 2,
            }"
            @click="selected_slider_tab = 2"
          >
            {{ $t("feedback.for_qrwaiter") }}
          </div>
        </div>
        <textarea
          :placeholder="$t('feedback.comment_ph')"
          v-model="comment"
          class="text-area"
        ></textarea>
        <div class="rating-list">
          <div
            @click="selected_rate = i"
            v-for="i in 5"
            :key="i"
            :class="{
              'rating-list-item': true,
              red: selected_rate == i && i <= 2,
              green: selected_rate == i && i > 2,
            }"
          >
            <img
              v-if="selected_rate != i"
              :src="
                `/icons/desktop/${
                  i <= 2 ? 'dislike' : 'like'
                }-cafe-icon-grey.svg`
              "
              alt=""
            />
            <img
              v-else
              :src="
                `/icons/desktop/${i <= 2 ? 'dislike' : 'like'}-cafe-icon.svg`
              "
              alt=""
            />{{ i }}
          </div>
        </div>
      </div>
      <div class="pop-up-button" @click="sendFeedback">
        {{ $t("feedback.send") }}
      </div>
    </div>
    <div class="flex-box-column" v-else>
      <div class="rate-cafe-popup-body flex-body">
        <img
          src="/icons/desktop/rate-cafe-popup-panda.png"
          class="panda"
          alt=""
        />
        <div class="panda-text">
          {{ $t("feedback.thanks") }}
        </div>
      </div>
      <div class="pop-up-button black-btn" @click="$emit('close')">
        {{ $t("feedback.close") }}
      </div>
    </div>
  </div>
</template>
<script>
import ReviewsAPI from "@/api/reviews";

export default {
  data() {
    return {
      selected_slider_tab: 1,
      selected_rate: null,
      comment: null,

      sent: false,
    };
  },
  props: {
    mode: String,
    order_id: String,
  },
  methods: {
    sendFeedback() {
      if (!this.selected_rate) return;

      ReviewsAPI.create(
        this.mode,
        this.order_id,
        this.selected_rate,
        this.comment,
        this.selected_slider_tab == 2
      )
        .then((data) => {
          this.sent = true;
          this.$emit("success");
        })
        .catch((error) => {
          alert(`Помилка :( (${error})`);
        });
    },
  },
};
</script>
<style scoped>
.rate-cafe-backdrop {
  background: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.rate-cafe-popup {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 13;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.flex-box-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.rate-cafe-popup-body {
  padding: 2.2222vh;
  background: #1d1d1b;
  border-radius: 2.2222vh;
  width: 55.55555555555556vh;
  position: relative;
}
.rate-cafe-title {
  font-size: 2.7777777777777777vh;
  line-height: 3.2222222222222223vh;
  color: #ededed;
  margin-bottom: 2.2222vh;
}
.text-area {
  width: 100%;
  height: 22.22222222222222vh;
  border: 0.1111vh solid #908f8d;
  box-sizing: border-box;
  border-radius: 1.1111vh;
  background: transparent;
  font-size: 2vh;
  line-height: 2.3333333333333335vh;
  color: #908f8d;
  resize: none;
  padding: 1.1111vh;
}
.slider {
  width: 100%;
  height: 3.2222222222222223vh;
  background: #464646;
  border-radius: 0.33333vh;
  margin-bottom: 1.6666666666666667vh;
  display: flex;
}
.slider-item {
  background: transparent;
  border-radius: 0.33333vh;
  font-size: 1.6666666666666667vh;
  line-height: 1.8888888888888888vh;
  color: #908f8d;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.slider-item-active {
  background: #ededed;
  color: #1d1d1b;
  cursor: default;
}
.rating-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.222vh;
}
.rating-list-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 2vh;
  line-height: 2.3333333333333335vh;
  color: #908f8d;
  cursor: pointer;
}
.rating-list-item img {
  width: 3.111111111111111vh;
  margin-bottom: 1.1111vh;
}
.rotate-icon {
  transform: rotateX(180deg);
}
.red {
  color: #e2223b;
}
.green {
  color: #129b81;
}
.pop-up-button {
  width: 37.22222222222222vh;
  height: 5.555555555555555vh;
  background: #129b81;
  box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.25);
  border-radius: 1.111vh;
  margin-top: 2.222vh;
  font-size: 1.7777777777777777vh;
  line-height: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ededed;
  cursor: pointer;
}
.black-btn {
  background: #1d1d1b;
}
.flex-body {
  display: flex;
  position: relative;
  height: 26.666666666666668vh;
}
.panda {
  width: 24.444444444444443vh;
  height: 24.444444444444443vh;
  position: absolute;
  bottom: 0;
  left: 0;
}
.panda-text {
  width: 28.88888888888889vh;
  height: 24.444444444444443vh;
  right: 0;
  bottom: 0;
  position: absolute;
  font-size: 2.7777777777777777vh;
  line-height: 3.2222222222222223vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #ededed;
  padding-right: 3.3333vh;
}
</style>
