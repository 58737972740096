<template>
  <div>
    <div class="hide-content"></div>
    <div v-if="isMobile" class="phone-confirm-modal">
      <div class="header">
        <h5 class="color-white mb-0" v-html="$t('roulette.approve_code')"></h5>
      </div>
      <img src="../../assets/hi-hero.svg" class="hi" />
      <input
        type="text"
        :class="
          isValid
            ? 'color-black personal-info-input'
            : 'personal-info-input red-input'
        "
        placeholder="XXXX"
        v-model="confirm_code"
        inputmode="numeric"
        name="phone_confirm_code"
        pattern="[0-9]{4}"
        autocomplete="one-time-code"
        maxlength="4"
      />

      <LongButton @click="onConfirm" class="next">{{
        $t("roulette.confirm")
      }}</LongButton>
      <LongButton class="repeat-code" @click="onResend" v-if="canResend">
        {{ $t("roulette.resend") }}
      </LongButton>
      <LongButton class="repeat-code" @click="onCancel" v-if="canResend">
        {{ $t("phone_confirm.change_phone") }}
      </LongButton>
    </div>
    <div class="phone-confirm-modal-desktop">
      <div class="flex-container">
        <div class="pop-up-info-block">
          <img
            src="/icons/desktop/panda-location.png"
            alt=""
            class="panda-image mb-20"
          />
          <div class="big-text white-text mb-20" v-html="$t('roulette.approve_code')"></div>
          <input
            class="mb-20 pop-up-input"
            placeholder="_ _ _ _"
            v-model="confirm_code"
            inputmode="numeric"
            name="phone_confirm_code"
            pattern="[0-9]{4}"
            autocomplete="one-time-code"
            maxlength="4"
          />
          <div class="small-text grey-text" @click="onCancel">
            {{ $t("phone_confirm.change_phone") }}
          </div>
        </div>
        <div class="pop-up-button" @click="onConfirm">
          {{  $t("roulette.confirm") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SecurityAPI from "@/api/security";

export default {
  data() {
    return {
      isValid: true,
      confirm_code: null,
      canResend: false,
    };
  },
  props: {
    token: String,
  },
  methods: {
    onConfirm() {
      if (!this.confirm_code || this.confirm_code.length < 3) {
        this.isValid = false;
        return;
      }

      SecurityAPI.check(this.token, this.confirm_code)
        .then((data) => {
          if (data.result) {
            this.$emit("confirmed");
          } else {
            this.isValid = false;
            this.confirm_code = null;
          }
        })
        .catch((error) => {
          this.isValid = false;
        });
    },
    onCancel() {
      this.$emit("cancel");
    },
    onResend() {
      this.$emit("resend");
      this.canResend = false;
    },
  },
  mounted() {
    const vm = this;
    setTimeout(() => {
      vm.canResend = true;
    }, 15 * 1000);
    this.toggleGlobalScrolling(true);
  },
  beforeDestroy() {
    this.toggleGlobalScrolling(false);
  },
};
</script>

<style scoped>
.hide-content {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.4);
  z-index: 6;
  top: 0;
  left: 0;
}

.phone-confirm-modal {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: block;
  z-index: 10;
  margin: 0 auto;
  background: #1d1d1b;
  width: 90vw;
  top: 50%;
  border-radius: 2.7vh 2.7vh 5vh 5vh;
  padding: 3.7vh 5.4vw;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  position: fixed;
}
.personal-info-input {
  outline: none;
  background: #ededed;
  width: 80vw;
  border-radius: 10vw;
  text-align: center;
  display: block;
  margin: 0 auto;
  border: 1px solid #a4a3a1;
  margin-bottom: 2.3vh;
  padding: 1.8vh 0;
}

.personal-info-input:focus,
.personal-info-input:not(:focus) {
  color: #1d1d1b;
}

.red-input {
  border: 2px solid #e2223b !important;
}

h5 {
  display: inline-block;
  letter-spacing: 0 !important;
  font-size: 4.8vw !important;
}

.header {
  margin-bottom: 1vh;
}

.repeat-code {
  margin: 2.5vh auto 0 !important;
  background: transparent !important;
  border: 1px solid #a4a3a1 !important;
  color: #a4a3a1 !important;
}
@media screen and (min-width: 1024px) {
  .hide-content {
    z-index: 13;
  }
  .phone-confirm-modal-desktop {
    z-index: 14;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .flex-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 55.55555555555556vh;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .pop-up-button {
    width: 37.22222222222222vh;
    height: 5.555555555555555vh;
    background: #129b81;
    box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.25);
    border-radius: 1.111vh;
    margin-top: 2.222vh;
    font-size: 1.7777777777777777vh;
    line-height: 2vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ededed;
  }
  .pop-up-info-block {
    width: 100%;
    background: #1d1d1b;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2.222vh;
    padding: 2.222vh 4.44444444444444vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
  }
  .small-text {
    font-size: 1.6666666666666667vh;
    line-height: 118.5%;
    text-align: center;
    color: #908f8d;
    width: 100%;
    cursor: pointer;
  }
  .big-text {
    font-size: 2.222vh;
    line-height: 118.5%;
    color: #ededed;
    width: 100%;
    text-align: center;
    cursor: pointer;
  }
  .white-text {
    color: #ededed;
  }

  .grey-text {
    color: #908f8d;
  }

  .panda-image {
    width: auto;
    height: 22.666666666666668vh;
  }

  .mb-20 {
    margin-bottom: 2.2222vh;
  }

  .pop-up-input {
    width: 37.22222222222222vh;
    height: 5.111111111111111vh;
    font-size: 1.7777777777777777vh;
    line-height: 2vh;
    background: #ffffff;
    text-align: center;
    border: 0.111111vh solid #908f8d;
    box-sizing: border-box;
    border-radius: 1.1111111111111112vh;
  }
}
</style>
