<template>
  <div v-thematic class="feedback-box">
    <h4 :class="isDarkMode ? 'about dark' : 'about'">
      {{ $t("feedback.title") }}
    </h4>
    <div :class="isDarkMode ? 'feedback-switcher dark' : 'feedback-switcher'">
      <div
        @click="toggleTab()"
        :class="{
          switch: true,
          cafe: true,
          'switch-active': !is_qrwaiter,
        }"
      >
        {{ $t("feedback.for_cafe") }}
      </div>
      <div
        @click="toggleTab()"
        :class="{
          switch: true,
          qrw: true,
          'switch-active': is_qrwaiter,
        }"
      >
        {{ $t("feedback.for_qrwaiter") }}
      </div>
    </div>

    <textarea
      @focus="freezeScrolling(false)"
      @blur="freezeScrolling(true)"
      v-thematic
      maxlength="512"
      v-model="comment"
    ></textarea>
    <div>
      <div v-thematic class="rating">
        <div
          v-for="n in 5"
          :key="n"
          @click="score = n"
          :class="{
            active: score == n,
            'active-red': score == n && n <= 2,
          }"
        >
          <img
            :src="
              score == n
                ? n > 2
                  ? '/icons/hand-green.svg'
                  : '/icons/hand-red.svg'
                : '/icons/hand-grey.svg'
            "
            :class="{
              'img-rate': true,
              rotated: n <= 2 && score != n,
              selected: score == n,
            }"
          />
          {{ n }}
        </div>
      </div>
    </div>
    <LongButton class="btn-bottom btn-cancel" @click="cancel()">{{
      $t("feedback.cancel")
    }}</LongButton>
    <LongButton variant="success" class="send btn-bottom" @click="onSend()">{{
      $t("feedback.send")
    }}</LongButton>
  </div>
</template>

<script>
import LongButton from "@/components/LongButton";

import ReviewsAPI from "@/api/reviews";

export default {
  data() {
    return {
      score: null,
      comment: null,
      is_qrwaiter: false,
    };
  },
  props: {
    order_id: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      default: "inside",
    },
  },
  methods: {
    onSend() {
      if (!this.score) return;

      ReviewsAPI.create(
        this.mode,
        this.order_id,
        this.score,
        this.comment,
        this.is_qrwaiter
      )
        .then((data) => {
          this.$emit("success");
        })
        .catch((error) => {
          alert(`Помилка :( (${error})`);
        });
    },
    cancel() {
      this.$emit("cancel");
    },
    toggleTab() {
      this.is_qrwaiter = !this.is_qrwaiter;
    },
    freezeScrolling(yes) {
      // this.toggleGlobalScrolling(yes);
    },
  },
  mounted() {
    // this.freezeScrolling(true);
  },
  beforeDestroy() {
    // this.freezeScrolling(false);
  },
  components: {
    LongButton,
  },
};
</script>

<style scoped>
.feedback-box {
  z-index: 5;
  padding: 2.5vh;
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 7vh;
  background: #ededed;
}

.feedback-box.dark {
  background: black;
}

.feedback-switcher {
  background: #a4a3a1;
  border-radius: 0.3vh;
  display: flex;
  margin-top: 2vh;
  height: 3.7vh;
  padding: 0 0.3vh;
  align-self: center;
  user-select: none;
}

h4.about {
  font-size: 2vh;
  margin: 0;
  color: #a4a3a1;
}

h4.dark {
  color: #a4a3a1;
}

.feedback-switcher.dark {
  background: #ededed;
}

.switch {
  text-align: center;
  border-radius: 0.3vh;
  font-size: 1.8vh;
  padding: 0.2vh 2vh;
  cursor: pointer;
  width: 50%;
  align-self: center;
  color: #464646;
}

.switch-active {
  color: #ededed;
  background: #464646;
}

.cafe.switch-active {
  color: #ededed;
  background: #464646;
}

.rating {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 5vh;
}

.rating.dark {
  color: #908f8d;
}

.rating div {
  display: grid;
  text-align: center;
  color: #908f8d;
}

h3 {
  font-size: 3vh;
  margin: 0;
}

h3.dark,
h5.dark {
  color: #ededed;
}

h5 {
  padding-top: 2.2vh;
  font-size: 2vh;
}

textarea {
  margin-top: 1.9vh;
  height: 18vh;
  background: transparent;
  border-radius: 1vh;
  outline: none;
  overflow-y: scroll;
  width: 100%;
}

textarea.dark {
  color: #ededed;
}

textarea.dark > * {
  border: 1px solid #ededed;
}

textarea:active,
textarea:hover {
  border: 1px solid #1d1d1d;
}

textarea.dark:active,
textarea.dark:hover {
  border: 1px solid #ededed;
}

img.img-rate {
  opacity: 0.5;
  height: 3vh;
  margin-bottom: 1vh;
  width: auto;
}

.active {
  color: #129b81 !important;
}

.active-red {
  color: #e2223b !important;
}

.img-rate.selected {
  opacity: 1;
}

img.rotated {
  transform: rotate(180deg);
}

.btn-bottom {
  position: fixed;
  bottom: 5vh;
  width: 90%;
  left: 0;
  right: 0;
  border-radius: 1.3vh;
}

.send {
  color: #ededed;
  box-shadow: none;
}

.btn-bottom.btn-cancel {
  border: 1px solid #908f8d;
  color: #908f8d;
  box-shadow: none;
  background: transparent;
  bottom: 13.5vh;
}
</style>
