<template>
  <div id="app">
    <!-- <DownloadAppBanner @close-banner="closeAppBanner()" v-if="uiBannerShown" /> -->
    <!-- <div class="extra-space" v-if="uiBannerShown"></div> -->
    
    <router-view v-if="!shouldBlockDesktop" />
    <DesktopBlocker v-if="shouldBlockDesktop" />

    <div class="landscape-blocker" v-if="isMobile">
      <RotateScreenModal />
    </div>
  </div>
</template>

<script>
import DownloadAppBanner from "@/components/modals/DownloadAppBanner.vue";
import Navbar from "@/components/Navbar";
import DesktopBlocker from "@/components/DesktopBlocker.vue";

import RotateScreenModal from "@/components/modals/RotateScreenModal.vue";

import Polyglote from "@/services/polyglote";
import moment from "moment";

export default {
  data() {
    return {
      
    }
  },
  components: {
    Navbar,
    DownloadAppBanner,
    DesktopBlocker,
    RotateScreenModal,
  },
  methods: {
    closeAppBanner() {
      this.$store.commit('setAppBannerShown', false);
    },
    checkLocale() {
      if (Polyglote.isLocaleSaved()) {
        this.$i18n.locale = Polyglote.getSavedLocale();
      } else {
        this.$i18n.locale = Polyglote.detect();

        Polyglote.updateLocale(this.$i18n.locale);
      }

      moment.locale(this.$i18n.locale);
    },
  },
  computed: {
    uiBannerShown() {
      return this.$store.state.uiBannerShown && this.isMobile;
    },
    isRouteForLargeScreen() {
      if (this.$route.name == "offert_view") return true;

      if (
        [
          "preorder",
          "delivery",
          "choose_mode",
          "direct_menu",
          "offert_view",
        ].includes(this.$route.name) &&
        [
          "kolomenskaya_yagoda",
          "test",
          "clearbarn_clever",
          "clearbarn",
          "clearbarn_bolshoy",
          "clearbarn_fuchika",
        ].includes(this.$route.params.code)
      ) {
        return true;
      }

      return ["marketplace", `marketplace_root`, `download_app`].includes(
        this.$route.name
      );
    },
    shouldBlockDesktop() {
      return !this.isMobile && ['new_order_code', 'reserve', 'hotel_room', 'inside_lite'].includes(this.$route.name);
      return false;
      if (this.isMobile) return false;

      return !this.isRouteForLargeScreen;
    },
  },
  mounted() {
    this.checkLocale();
  },
};
</script>

<style>
@import "./main.css";
#app {
  font-family: "Chadwick", "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.extra-space {
  height: 5.7vh;
}

.color-white {
  color: #ededed;
}
.color-red {
  color: #e2223b;
}
.color-black {
  color: #1d1d1b;
}
.color-green {
  color: #129b81;
}
.color-grey {
  color: #a4a3a1;
}

a,
a:hover,
a:active {
  text-decoration: none !important;
}

@font-face {
  font-family: Chadwick;
  src: url("/Chadwick.otf");
}
 
</style>
