<template>
  <!-- <div class="desktop-blocker color-white">
      <img :src="qrSrc" alt="Scan on mobile phone to proceed">
      <h3>
          Scan with your phone to proceed
          <br>
          Відскануйте за допомогою смартфону, щоб продовжити.
        </h3>
      <img src="/icons/grey-logo.svg" class="mx-auto mt-5 d-block" />
  </div> -->
  <div class="desktop-blocker">
      <img class="qr-logo" src="/icons/grey-logo.svg">
      <img src="../assets/error-hero.svg" class="panda-logo">

      <p class="blocker-desc">
          {{ $t('desktop_blocker.description') }}
      </p>
      
      <img :src="qrSrc" class="qr-code">

      <a class="support-link" href="https://t.me/QRWaiterSupportBot">
        Support
      </a>
  </div>
</template>

<script>
export default {
    mounted() {
        this.toggleGlobalScrolling(true);
    },
    beforeDestroy() {
        this.toggleGlobalScrolling(false);
    },
    computed: {
        qrSrc() {
            return `https://api.qrserver.com/v1/create-qr-code/?size=256x256&data=${window.location.href}`
        }
     }
}
</script>

<style scoped>
/* .desktop-blocker {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;

    z-index: 9999;
    text-align: center;

    background-color: #1d1d1b;

    padding-top: 20vh;
}

.desktop-blocker h3 {
    letter-spacing: 0.03rem !important;
}

.desktop-blocker img {
    z-index: 10000;
    padding-bottom: 3vh;
} */

.desktop-blocker {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;

    z-index: 10000;
    
    background-color: #1d1d1b;

    padding: 2vh 5vh;
}

.blocker-desc {
    color: #ededed;
    max-width: 60vh;

    position: relative;
    top:  30vh;
    left: 15vh;

    font-size: 2.7vh;
}

.qr-logo {
    /* margin-top: 5.2vh; */

    height: 3.3vh;
}

.qr-code {
    position: absolute;

    bottom: 19.4vh;
    right: 24.3vh;

    width: 32vh;
    height: 32vh;

    max-width: 32vh;
    max-height: 32vh;
}

.panda-logo {
    height: 20vh;
    width: auto;

    position: absolute;

    bottom: 19vh;
    left: 20.5vh;
}

.support-link {
    text-decoration: none;
    color: #cbd9e6;
    position: absolute;
    bottom: 4.2vh;
    font-size: 2.5vh;
}
</style>