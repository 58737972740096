<template>
  <div>
    <div class="hide-content"></div>
    <div class="geo-wrapper" v-if="!fail">
      <div class="general-popup">
        <p class="info pt-0">
          {{ isHotel ? $t("geo.title_hotel") : $t("geo.title") }}
        </p>
        <img class="geo-gadget-img" src="/icons/geo-hero.svg" alt="geo" />
        <h4 class="give-access color-white" v-html="$t('geo.please')"></h4>
        <p class="info c-dgrey">{{ $t("geo.just_click") }}</p>
      </div>

      <div class="actions-block">
        <LongButton @click="emitConfirm" class="rect-borders w-90 mt-20"
          >OK</LongButton
        >
        <LongButton
          variant="white-outline"
          @click="emitSkip"
          class="rect-borders mt-20"
          v-if="can_skip"
          >{{ $t("marketplace_geo_modal.deny") }}</LongButton
        >
        <router-link
          :to="`/direct_menu/${onlineMenuLink}`"
          v-if="onlineMenuLink"
        >
          <LongButton variant="white-outline" class="rect-borders w-90 mt-20">
            {{ $t("geo.not_in_cafe") }}
          </LongButton>
        </router-link>
      </div>
    </div>

    <div class="geo-wrapper" v-if="fail">
      <div class="general-popup">
        <!-- <img class="geo-fail-img" src="/icons/big-alarm.png" alt="geo" /> -->
        <p class="pt-0">{{ $t(`geo.title`) }}</p>
        <img
          v-if="fail !== 'browser'"
          src="../assets/error-hero.svg"
          class="error"
          alt="error"
        />
        <img
          alt="geo"
          class="geo-gadget-img"
          src="/icons/geo-fail-hero.svg"
          v-if="fail == 'browser'"
        />
        <h4 class="c-red" v-html="$t(`geo.fail_titles.${fail}`)"></h4>
        <p class="color-grey mt-20 p-0" v-if="fail == 'browser' || fail == 'not_in_cafe'">
          {{ $t(`geo.fail_subtitles.${fail}`) }}
        </p>
      </div>
      <div class="actions-block">
        <LongButton @click="doReload" class="rect-borders w-90 mt-20">{{
          $t("geo.reload")
        }}</LongButton>
        <LongButton
          variant="white-outline"
          @click="emitSkip"
          class="mt-20 w-90"
          v-if="can_skip"
          >{{ $t("marketplace_geo_modal.deny") }}</LongButton
        >
        <router-link
          :to="`/direct_menu/${onlineMenuLink}`"
          v-if="onlineMenuLink"
        >
          <LongButton variant="white-outline" class="rect-borders w-90 mt-20">
            {{ $t("geo.not_in_cafe") }}
          </LongButton>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>
.w-90 {
  width: 90%;
}

.c-dgrey {
  color: #908f8d !important;
}

.c-red {
  color: #e2223b;
}

.hide-content {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 6;
  overflow: hidden;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
  top: 0;
  left: 0;
}

.mt-20 {
  margin-top: 2.5vh !important;
}

.geo-wrapper {
  position: fixed;
  top: 50%;
  left: 0;
  z-index: 10;
  width: auto;
  transform: translateY(-50%);
  right: 0;
  margin: 0 auto;
}

.actions-box {
  z-index: 6;
}

.general-popup .info {
  line-height: normal;
  font-weight: 400 !important;
  color: #a4a3a1;
  font-size: 1.9vh;
  padding: 2.5vh 0 0;
}

.general-popup p {
  font-size: 1.9vh;
  color: #a4a3a1;
  line-height: normal;
  padding: 1.3vh 0 0;
  margin: 0;
}

.general-popup h4 {
  margin: 0;
  font-weight: 400;
  padding-top: 2.5vh;
  font-size: 2.5vh;
}

.geo-gadget-img {
  height: auto;
  margin-top: 3.7vh;

  width: 26vh;
}

img.error {
  margin-top: 6.6vh;
  margin-bottom: 2.5vh;
}

.geo-fail-img {
  height: auto;
  margin-top: 2.5vh;
  width: 17vw;
}
.geo-example {
  width: 75vw;
  margin-top: 2.5vh;
  height: auto;
}
</style>

<script>
import LongButton from "@/components/LongButton.vue";

export default {
  props: {
    fail: {
      type: [String, Boolean],
      default: null,
    },
    can_skip: {
      type: Boolean,
      default: false,
    },
    isHotel: {
      type: Boolean,
      default: false,
    },
    onlineMenuLink: {
      type: String,
      default: null,
    },
  },
  methods: {
    emitConfirm() {
      this.$emit("confirm");
    },
    doReload() {
      window.location.reload();
    },
    emitSkip() {
      this.$emit("skip");
    },
  },
  mounted() {
    console.log("freeze");
    this.toggleGlobalScrolling(true);
  },
  beforeDestroy() {
    this.toggleGlobalScrolling(false);
  },
  components: {
    LongButton,
  },
};
</script>