<template>
  <div :class="{
    'feedback-text': true,
    'feedback-text-black': blackOnly
  }"
   v-thematic @click="onClick">
        <img src="../assets/hand-red.svg" class="red-rotated">
        <h4 v-thematic>{{$t('order_buttons.feedback')}}</h4>
        <img src="../assets/hand-green.svg" alt="">
      </div>
</template>

<script>

export default {
  props: {
    blackOnly:{
     type:Boolean,
     default:false
    }
  },
    methods: {
        onClick() {
            this.$emit('click');
        }
    }
}
</script>

<style scoped>
.feedback-text {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 3.3vh auto;
  cursor: pointer;
}

.feedback-text-black h4 {
  color: #1d1d1d;
}

.feedback-text.dark, .feedback-text h4.dark {
  color: #ededed;
}

.feedback-text h4.dark {
  border-bottom: 1px solid #ededed;
}

.feedback-text h4 {
  font-size: 2vh;
  border-bottom: 1px solid #1d1d1d;
  padding: 0;
  color: #1d1d1d;
  margin: 0 2vh;
}

.feedback-text img {
  align-self: baseline;
  height: 2.5vh;
  width: auto;
}

img.red-rotated {
  align-self: center;
  /* transform: rotate(180deg); */
}
</style>