<template>
  <div>
    <!-- <div class="hide-content"></div> -->
    <div class="preorder-mode-wrapper" :style="wrapper_style">
      <div
        class="option delivery"
        :class="{
          image: cafe.ui_settings ? !cafe.ui_settings.hide_panda : true
        }"
        @click="onSelect(true)"
        v-if="modes.delivery"
      >
        <h2>{{ deliveryTitle }}</h2>
        <!-- <p>
          {{ $t("choose_mode.delivery") }}
        </p> -->

        <!-- <p class="order-description" v-if="deliveryState">
          {{
            $t(`mode_modal.delivery_types.${deliveryState.type}`, {
              cost: deliveryState.cost,
              currency: currency.symbol,
            })
          }}
        </p>
        <p
          class="order-description"
          v-if="deliveryState && deliveryState.min_check"
        >
          {{
            $t(`mode_modal.delivery_types.conditional`, {
              min_check: deliveryState.min_check,
              currency: currency.symbol,
            })
          }}
        </p> -->
        <p class="order-description" v-if="deliveryState">
          {{ $t('delivery_bill.delivery_item')}}:
          <template v-if="deliveryState.type == 'free'">
            {{ $t('delivery_bill.delivery_free')}}
          </template>
          <template v-else-if="deliveryState.type == 'constant'">
            {{ deliveryState.cost }}{{currency.symbol}}
          </template>
          <template v-else>
            {{ $t('delivery_bill.delivery_additional')}}
          </template>
        </p>

        <p class="order-description" v-if="hasMinCheck">
          {{ $t('mode_modal.min_check_header') }}: {{ minCheckCost || 0
          }}{{ currency.symbol }}
        </p>

        <div class="option-title del">
          <div class="border-btn">
            <h3>
              {{ $t("choose_mode.view_menu") }}
            </h3>
            <img src="/icons/arrow-white.svg" class="arrow-next" />
          </div>
        </div>
      </div>
      <div
        class="option preorder"
        :class="{
          image: cafe.ui_settings ? !cafe.ui_settings.hide_panda : true,
          'bagel': cafe._id == 522 || cafe._id == 1
        }"
        @click="onSelect(false)"
        v-if="modes.preorder"
      >
        <h2>{{ preorderTitle }}</h2>
        <!-- <p>
          {{ $t("choose_mode.preorder") }}
        </p> -->

        <div class="option-title pre">
          <div class="border-btn">
            <h3>
              {{ $t("choose_mode.view_menu") }}
            </h3>
            <img src="/icons/arrow-white.svg" class="arrow-next" />
          </div>
        </div>
      </div>
      <div class="option reserve mb-0" :class="{
          image: cafe.ui_settings ? !cafe.ui_settings.hide_panda : true
        }" @click="reserve()" v-if="false">
        <h2>{{ reserveTitle }}</h2>
        <!-- <p>
          {{ $t("choose_mode.reserve") }}
        </p> -->

        <div class="option-title res">
          <div class="border-btn">
            <h3>
              {{ $t("choose_mode.go_reserve") }}
            </h3>
            <img src="/icons/arrow-white.svg" class="arrow-next" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MarketplaceService from "@/services/marketplace";
import FeedbackButton from "@/components/FeedbackButton";

export default {
  data() {
    return {
      darkMode: true,
    };
  },
  props: {
    modes: Object,
    deliveryState: Object,
    cafe_id: [String, Number],
    minCheckCost: Number,
    cafe: Object
  },
  methods: {
    onSelect(is_delivery) {
      this.$emit("select", is_delivery);
    },
    reserve() {
      this.$router.push({
        name: "reserve",
        params: {
          code: this.$route.params.code,
        },
      });
    },
    toMarketplace() {
      widow.location.href = "https://delivery.qrwaiter.com.ua";
    },
    onCancel() {
      this.$emit("cancel");
    },
  },
  computed: {
    hasMinCheck() {
      return !isNaN(this.minCheckCost);
    },
    currency() {
      return this.$store.state.currency.current;
    },
    wrapper_style() {
      if (!this.has_variant) return {};
      return {
        padding: "6vh 0 1vh",
      };
    },
    deliveryTitle() {
      return this.cafe.delivery.ui_button_title ||  "DELIVERY";
    },
    preorderTitle() {
      return this.cafe.preorder.ui_button_title || "TAKEAWAY";
    },
    reserveTitle() {
      return this.cafe.reserve.ui_button_title || "RESERVE";
    },
    showBackTo() {
      return MarketplaceService.get().flag;
    },
  },
  components: {
    FeedbackButton,
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

.btn-back {
  background: #1d1d1b;
  border-radius: 5vh;
  position: absolute;
  padding: 2vh;
  left: 2.5vh;
  bottom: -25vh;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.25);
}

.c-blue {
  color: #cbd9e6;
}

.c-green {
  color: #aeccc6;
}

.c-yellow {
  color: #ffd2a8;
}

h2 {
  font-size: 3.8vh;
  line-height: normal;
  font-weight: bold;
  padding: 1.8vh 1.8vh 0.5vh;
  font-weight: bold;
}

.nav-box {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.21) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  backdrop-filter: blur(3px);
  padding: 2vh;
  position: fixed;
  z-index: 3;
  bottom: 0;
  width: 100%;
}

.cancel-btn {
  background: #1d1d1b;
  border-radius: 5vh;
  padding: 0;
  width: 7.4vh;
  height: 7.4vh;
  display: block;
  color: #ededed;
  border: none;
}

.option-title img.arrow-next {
  height: 1.4vh;
  margin: 0 2vh 0 0;
  margin-left: auto;
  width: auto;
  align-self: center;
  bottom: 0;
  position: relative;
  transform: rotate(270deg);
}

.cancel-btn img {
  height: 3vh;
}

.btn-back img {
  height: 3vh;
}

.preorder-mode-wrapper {
  position: relative;
  width: 100%;
}

/* .dark {
  background: #000000;
} */

h3 {
  font-size: 2.3vh;
  color: #1d1d1d;
  line-height: normal;
}

.option p {
  font-size: 2vh;
  line-height: normal;
  margin: 0;
  width: 65%;
  color: #464646;
  padding: 0 1.8vh 0;
}

.option img {
  right: -2vh;
  position: absolute;
  bottom: -2vh;
  z-index: 1;
  width: auto;
  height: 26vh;
}

.option:first-child {
  margin-top: 0;
}

.option {
  background-color: #CBD9E6;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  height: 25vh;
  z-index: 1;
  width: 100%;
  position: relative;
}

.option.image {
  background-image: url("/hero-pics/delivery-card.svg");
}

.option.preorder {
  background-color: #ffd2a8;
}

.option.preorder.bagel {
  background-color: #7FCCB6;
}

.option.preorder.bagel h2 {
  font-size: 3.1vh !important;
  width: 70%;
}

.option.preorder.image {
  background-image: url("/hero-pics/preorder-card.svg");
}

.option.preorder.bagel.image {
  background-image: url("/hero-pics/preorder-card-green.svg");
}

.option.reserve {
  background-color: #aeccc6;
}

.option.reserve.image {
  background-image: url("/hero-pics/reserve-card.svg");
}

.info-box {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.variant-text {
  font-size: 4.9vw;
  padding: 2.1vh 0 2.1vh 0; /* 2.1 0 2.1 5.4 OLD*/
  line-height: 0.8;
  background: #1d1d1b;
  text-align: center;
}
.option-title {
  position: absolute;
  padding: 2vh 1.8vh;
  bottom: 0;
}

.border-btn {
  border: 1px solid #1d1d1b;
  padding: 1.3vh 1.5vh;
  border-radius: 1vh;
  max-width: fit-content;
  min-width: 22vh;
  display: flex;
}

.option-info {
  position: relative;
  width: 100%;
  padding: 2vh;
}

.hide-content {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.4);
  z-index: 2;
  top: 0;
  left: 0;
}

.pt-20 {
  padding-top: 2.2vh;
}

.c-grey {
  color: #464646;
}

.reserve-image {
  right: -1.6vh !important;
  bottom: -2.5vh !important;
}
</style>
