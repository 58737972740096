import BaseAPI from '../base'

function create(mode, order_id, score, comment, for_qrwaiter) {
    return BaseAPI.sendPost(`reviews/create`, {
        order_id,
        score,
        comment,
        mode,
        for_qrwaiter
    });
}

export default {
    create
}