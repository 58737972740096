<template>
    <div>
        <OrderLoader />
    </div>
</template>

<script>
import OrderLoader from '@/components/OrderLoader.vue'

import MarketplaceService from '@/services/marketplace'

import ReferralsAPI from '@/api/referrals'

export default {
    methods: {
        goToChooseMode() {
            this.$router.push({
                name: 'choose_mode',
                params: this.$route.params
            });
        }
    },
    created() {
        //tell marketplace service that this is direct menu
        MarketplaceService.set(this.$route.params.code, false);

        //if there is campaign link in the query, send the request to process visit
        //and if needed, skip choose mode to one of the campaigns 
        if (this.$route.query && this.$route.query.cmpid) {
            ReferralsAPI.sendCampaignVisit(this.$route.query.cmpid).then(data => {
                const campaign = data.campaign;

                window.localStorage.setItem(`last_referral_id`, `${this.$route.params.code}:${this.$route.query.cmpid}`);

                if (campaign.force_mode) {
                    this.$router.push({
                        name: 'choose_mode',
                        params: this.$route.params,
                        query: {
                            force_mode: campaign.force_mode
                        }
                    });
                } else {
                    this.goToChooseMode();
                }
            }).catch(error => {
                this.goToChooseMode();
            });
        } else {
            this.goToChooseMode();
        }
    },
    components: {
        OrderLoader
    }
}
</script>

<style>

</style>