<template>
  <div class="choose-mode-wrapper">
    <div
      class="choose-mode-backdrop"
      :style="{
        background: isDarkMode ? 'black' : '#ededed',
      }"
    ></div>
    <div class="cafe-page-container">
      <img
        :src="cafeBackgroundImage"
        v-if="showMainBg"
        :style="{
          top: isBannerShown ? '13.4vh' : '7.7vh',
        }"
        class="cafe-logo-wrapper"
      />

      <img
        :src="cafe.image ? cafe.image : '/icons/cafe-placeholder.jpg'"
        :alt="`${cafe.name} logo`"
        class="cafe-logo-main"
      />

      <div
        class="cafe-bonus"
        v-if="cashbackPercent"
      >
        {{ cashbackPercent }}%
      </div>
      <div
        class="cafe-rating"
        v-thematic
        v-if="cafe.rating.reviews && false"
      >
        {{ cafe.rating.reviews }}
        <img :src="
            isDarkMode
              ? '/icons/hand-rating-white.svg'
              : '/icons/hand-rating.svg'
          " />
      </div>
    </div>

    <div
      class="cafe-general-info"
      v-thematic
    >
      <div class="title">
        <img
          :src="
            addedToFav
              ? '/icons/star-white-active.svg'
              : '/icons/star-white.svg'
          "
          class="star"
          v-if="false"
          @click="addedToFav = !addedToFav"
        />
        <!-- <img
          :src="addedToFav ? '/icons/star-active.svg' : '/icons/star.svg'"
          class="star"
          v-else
          @click="addedToFav = !addedToFav"
          
        /> -->
        <h3 v-thematic>{{ cafe.name }}</h3>
      </div>
      <div
        class="cafe-description"
        v-html="descriptionHtml"
        v-thematic
        v-if="cafe.description"
      ></div>

      <button
        class="menu-action-btn w-100 transparent-btn"
        @click="openMenuPreview"
        v-thematic
        v-if="cafe.has_preview"
      >
        {{ $t("choose_mode.preview_menu") }}
        <span><img
            :src="
              isDarkMode ? `/icons/arrow-black.svg` : `/icons/arrow-white.svg`
            "
            class="arrow"
          /></span>
      </button>
    </div>

    <PreorderMode
      :modes="cafe.modes"
      :cafe_id="cafe._id"
      :cafe="cafe"
      :deliveryState="deliveryUiState"
      :minCheckCost="cafe.delivery.min_check"
      @select="onSelect"
      @cancel="backToMarketplace"
    />

    <div
      class="bottom-wrapper"
      v-thematic
      v-if="hasValidSchedule"
    >
      <div
        class="schedule-box"
        v-thematic
      >
        <h4 v-thematic>{{ $t("schedule.title") }}</h4>
        <div
          class="schedule-days"
          v-thematic
        >
          <div
            @click="selectedScheduleDay = index"
            :class="selectedScheduleDay == index ? 'active' : ''"
            class="day"
            v-thematic
            v-for="(day, index) in scheduleDays"
            :key="index"
          >
            {{ $t("schedule.short." + day) }}
          </div>
        </div>
        <div
          :class="[
            selectedScheduleDay !== 0 ? 'borders-right' : '',
            selectedScheduleDay !== 6 ? 'borders-left' : '',
            selectedScheduleDay !== 0 && selectedScheduleDay !== 6
              ? 'full-borders'
              : '',
          ]"
          v-thematic
          class="schedule-info"
        >
          <p
            class="work-time"
            v-thematic
          >{{ selectedDayText }}</p>
          <template v-if="scheduleVerdict.workday && todayDay == selectedScheduleDay">
            <p
              class="till-close"
              v-if="scheduleVerdict.is24hr"
            >
              {{ $t("marketplace.list.open_24hr") }}
            </p>

            <p
              class="till-close"
              v-else-if="scheduleVerdict.open"
            >
              {{ scheduleVerdict.time_left }}
              {{ $t("marketplace.list.closes_in") }}
            </p>

            <p
              class="till-close"
              v-else
            >
              {{ scheduleVerdict.time_left }}
              {{ $t("marketplace.list.opens_in") }}
            </p>
          </template>
        </div>
      </div>

      <!-- <button
      class="menu-action-btn color-blue"
      @click="askForMode('delivery')"
      v-if="canRequestMode('delivery')"
    >
      {{ $t("choose_mode.delivery_missing") }} <span>+</span>
    </button>
    <button
      class="menu-action-btn color-yellow"
      @click="askForMode('preorder')"
      v-if="canRequestMode('preorder')"
    >
      {{ $t("choose_mode.preorder_missing") }} <span>+</span>
    </button>
    <button
      class="menu-action-btn color-green"
      @click="askForMode('reserve')"
      v-if="canRequestMode('reserve')"
    >
      {{ $t("choose_mode.reserve_missing") }} <span>+</span>
    </button> -->

      <div class="feedback-container">
        <FeedbackButton
          class="feedback-btn"
          :blackOnly="true"
          @click="feedbackIsShown = true"
          v-if="!feedbackWasSent"
        />

        <ContactIcons
          class="contact-icons-container"
          :cafe="cafe"
        />
      </div>
    </div>

    <CafeFeedback
      :mode="'choose_mode'"
      :order_id="`${cafe._id}`"
      @cancel="feedbackIsShown = false"
      @success="
        feedbackIsShown = false;
        feedbackWasSent = true;
      "
      v-if="feedbackIsShown"
    />
  </div>
</template>

<script>
import PreorderMode from '@/components/PreorderMode.vue'

import ScheduleService from '@/services/schedule'

import FeedbackButton from '@/components/FeedbackButton.vue'

import ContactIcons from '@/components/order-details/ContactIcons.vue'

import AnalyticsAPI from '@/api/analytics'

import CafeFeedback from '@/components/CafeFeedback.vue'

import moment from 'moment'

export default {
  props: {
    cafe: Object,
    isMapOpened: false,
    showMainBg: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      addedToFav: false,
      selectedScheduleDay: 0,
      feedbackWasSent: false,
      feedbackIsShown: false,
      modesAsked: [],
      scheduleDays: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
      todayDay: 'mon'
    }
  },

  methods: {
    changeWorkDay(day) {
      this.schedule.activeDay = day
    },
    onSelect(mode) {
      this.$emit('select', mode)
    },
    backToMarketplace() {
      this.$emit('return')
    },
    askForMode(mode) {
      AnalyticsAPI.registerEvent({
        event_type: 'cafe_mode_requested',
        target: this.cafe._id,
        platform: 'web',
        payload: {
          mode,
          client_id: this.$store.state.clients.client_id || null
        }
      })

      this.modesAsked.push(mode)
    },
    canRequestMode(m) {
      return !this.cafe.modes[m] && !this.modesAsked.includes(m)
    },
    openMenuPreview() {
      this.$router.push({
        name: 'menu_preview',
        params: {
          id: this.cafe.online_menu_link
        }
      })
    }
  },
  computed: {
    hasValidSchedule() {
      return this.cafe.schedule && Object.values(this.cafe.schedule).find(v => v.workday)
    },
    isBannerShown() {
      return this.$store.state.uiBannerShown
    },
    cashbackPercent() {
      if (this.cafe.loyalty) return this.cafe.loyalty.cashback_percent

      return this.cafe.cashback_percent || 0
    },
    selectedDayText() {
      if (!this.hasValidSchedule) return '-'
      const key = this.scheduleDays[this.selectedScheduleDay]

      const schedule_item = this.cafe.schedule[key]

      if (!schedule_item || !schedule_item.workday) {
        return this.$t('schedule.free_day')
      }

      const fmtTime = t => `${t}`.padStart(2, '0')

      return `${fmtTime(schedule_item.startHour)}:${fmtTime(schedule_item.startMinute)} - ${fmtTime(
        schedule_item.endHour
      )}:${fmtTime(schedule_item.endMinute)} `
    },
    scheduleVerdict() {
      if (!this.hasValidSchedule) return '-'
      const helper = new ScheduleService.ScheduleHelper(this.cafe.schedule)

      return {
        open: helper.isOpen(),
        text: helper.format(),
        workday: helper.isWorkday(),
        time_left: helper.remainingTime(this.$t('units.hours'), this.$t('units.minutes')),
        open_hours: helper.hoursTillOpen(),
        is24hr: helper.isOpenAllDay()
      }
    },
    cafeBackgroundImage() {
      return (
        this.cafe.image_bg ||
        (this.isDarkMode
          ? '/desktop-pics/cafe-bg-placeholder-black.jpg'
          : '/desktop-pics/cafe-bg-placeholder-white.jpg')
      )
    },
    deliveryUiState() {
      if (this.cafe.delivery.service_tag == 'qrwaiter' || this.cafe.delivery.service_status != 'ready') {
        if (this.cafe.delivery.cost_type == 'free')
          return {
            type: 'free',
            cost: 0
          }

        if (this.cafe.delivery.cost_type == 'taxi') {
          return {
            type: 'additional',
            cost: 0
          }
        }

        if (this.cafe.delivery.cost_type == 'constant') {
          return {
            type: 'constant',
            cost: this.cafe.delivery.cost
          }
        }

        if (this.cafe.delivery.cost_type == 'conditional') {
          if (this.cafe.delivery.cost_conditional_type == 'constant') {
            return {
              type: 'constant',
              cost: this.cafe.delivery.cost,
              min_check: this.cafe.delivery.cost_min_check
            }
          } else {
            return {
              type: 'additional',
              cost: this.cafe.delivery.cost,
              min_check: this.cafe.delivery.cost_min_check
            }
          }
        }
      } else {
        if (this.cafe.delivery.taxi_payer == 'client') {
          return {
            type: 'constant',
            cost: this.cafe.delivery.taxi_cost
          }
        }

        if (this.cafe.delivery.taxi_payer == 'cafe') {
          return {
            type: 'additional'
          }
        }

        if (this.cafe.delivery.taxi_payer == 'conditional') {
          if (!this.cafe.delivery.cost_min_checks.length) {
            return {
              type: 'additional'
            }
          }

          const max_package = this.cafe.delivery.cost_min_checks.slice().pop()

          return {
            type: 'constant',
            cost: max_package.price,
            min_check: max_package.limit
          }
        }
      }
    },
    descriptionHtml() {
      if (!this.cafe.description) return ''

      return this.cafe.description.split('\n').join('<br>')
    }
  },
  mounted() {
    window.document.body.style.backgroundColor = this.isDarkMode ? 'black' : '#ededed'

    const moment_day = moment().day() - 1
    this.selectedScheduleDay = moment_day < 0 ? 6 : moment_day
    this.todayDay = this.selectedScheduleDay
  },
  components: {
    PreorderMode,
    FeedbackButton,
    ContactIcons,
    CafeFeedback
  }
}
</script>


<style scoped>
* {
  padding: 0;
  margin: 0;
}

.pt-50 {
  padding-top: 5.5vh;
}

.cafe-page-container {
  position: relative;
  background: transparent;
  height: 35vh;
  top: 26vh;
}

.map-page-container {
  height: 0;
  position: relative;
  top: -9.2vh;
}

.cafe-logo-wrapper {
  display: block;
  z-index: -10;
  position: fixed;
  width: 100%;
  /* top: 7.7vh; */
  height: 23vh;
  object-fit: cover;
}

.cafe-logo-main {
  position: relative;
  z-index: 2;
  object-fit: cover;
  bottom: 0;
  left: 2vh;
  height: 12.4vh;
  width: 100%;
  max-width: 24.7vh;
  border-radius: 1.5vh;
}

img.star {
  height: 2.8vh;
  display: block;
  margin-right: 1.2vh;
  width: auto;
}

.cafe-bonus {
  position: relative;
  right: 0;
  top: 5.5vh;
  width: 9.5vh;
  float: right;
  padding: 0.3vh 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(7px);
  z-index: 2;
  color: #ededed;
  font-size: 2vh;
}

.cafe-rating {
  position: relative;
  display: block;
  z-index: 2;
  align-items: center;
  padding: 0.3vh 0;
  right: 0;
  top: -3.4vh;
  text-align: center;
  width: 9.5vh;
  margin-left: auto;
}

.cafe-rating.dark {
  color: #ededed;
}

.cafe-rating img {
  padding: 0 0 0 0.4vh;
  height: 1.6vh;
  margin-top: -0.2vh;
  width: auto;
}

.cafe-general-info {
  background: rgba(237, 237, 237, 0.1);
  backdrop-filter: blur(20px);
  position: relative;
  z-index: 1;
  padding: 3.3vh 2vh 2.5vh;
}

.cafe-general-info.dark {
  background: rgb(0 0 0 / 70%);
}

.title {
  display: flex;
  align-items: center;
  padding-top: 1.8vh;
  position: relative;
}

.title h3 {
  color: #1d1d1d;
  font-size: 2.3vh;
}

.title h3.dark {
  color: #ededed;
}

.schedule-box h4 {
  font-size: 2.2vh;
  color: #1d1d1d;
}

.schedule-box h4.dark {
  color: #ededed;
}

.schedule-box {
  background: #ededed;
  position: relative;
  margin: 0 auto;
  z-index: 0;
  padding: 2.5vh 2.5vh 0;
}

.schedule-box.dark {
  background: #000;
}

.schedule-days {
  display: flex;
  padding-top: 1.2vh;
  justify-content: space-between;
}

/* .schedule-days.dark {
  background: #ededed;
} */

.schedule-info {
  background: #1d1d1d;
  padding: 1.2vh 2vh;
  border-radius: 0 0 0.6vh 0.6vh;
  margin-top: -1px;
  color: #ededed;
}

.schedule-info.dark {
  background: #1d1d1d;
}

.borders-left {
  border-radius: 0 0.6vh 0.6vh 0.6vh;
}
.borders-right {
  border-radius: 0.6vh 0 0.6vh 0.6vh;
}
.full-borders {
  border-radius: 0.6vh;
}

p.work-time.dark {
  color: #ededed;
}

p.till-close {
  font-size: 1.7vh;
  line-height: normal;
  color: #908f8d;
}

.day {
  font-size: 1.7vh;
  text-align: center;
  padding: 1vh 1.8vh;
  color: #1d1d1d;
  transition: 0.3s;
  border-radius: 0.6vh 0.6vh 0 0;
}

.day.dark {
  color: #908f8d;
}

.day.active {
  background: #1d1d1d;
  color: #ededed;
}

.day.active.dark {
  color: #ededed;
  background: #1d1d1d;
}

.cafe-description {
  font-size: 1.7vh;
  color: #464646;
  padding-top: 1.2vh;
}

.cafe-description.dark {
  color: #908f8d;
}

.bottom-wrapper {
  background: #ededed;
}

.bottom-wrapper.dark {
  background: #000;
}

.mode-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  background: #ededed;
}

.mode-bg.dark {
  background: black;
}

.contact-icons-container {
  margin-bottom: 12vh;
}

img.arrow {
  height: 1.2vh;
  width: auto;
  transform: rotate(-90deg);
}

.feedback-btn {
  margin-top: 5.5vh !important;
}

.choose-mode-wrapper {
  position: absolute;
  width: 100%;
  z-index: 5;
  top: -4.4vh;
}

.feedback-container {
  width: 100%;
  /* background: black; */
  height: fit-content;
}

.choose-mode-backdrop {
  width: 100vw;
  position: fixed;
  top: 40.7vh;
  height: 120vh;
  overflow: hidden;
  left: 0;
  z-index: -3;
  /* background: black; */
}
</style>