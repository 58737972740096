import TelemetryAPI from '@/api/telemetry'

let telemetry_id = null;
let pending = [];
let pending_gestures = [];

let sync_timer_id = null;

let last_cafe_id = null;

const Events = {
    OpenPage: 'open_page',
    ChangeLocale: 'change_locale',
    MakeOrder: 'make_order',
    CallService: 'call_serivce',
    ViewCategory: 'view_category',
    ViewProduct: 'view_product',
    ClickRecommendation: 'click_recommendation',
    BuyProduct: 'buy_product',
    SendOrder: 'send_order',
    CancelOrder: 'cancel_order',
    OrderUpdate: 'order_update',
    GiveGeolocation: 'give_geolocation',
    StartPaying: 'start_paying',
    ChoosePayMethod: 'choose_pay_method',
    ViewTutorial: 'view_tutorial',
    UseActiveOrder: 'use_active_order',
    SwitchMenu: 'switch_menu',
    SendCreateRequest: 'send_create_request'
};

function syncGestures() {
    if (pending_gestures.length == 0) return;

    TelemetryAPI.writeGestures(telemetry_id, pending_gestures).then(data => {
        console.log(`[Telemetry] Synced ${pending_gestures.length} gestures.`);
        pending_gestures = [];
    }).catch(error =>{
        console.error(`[Telemetry] Syncing gestures failed: ${error}`);
        pending_gestures = [];
    })
}

function init(cafe_id, mode, platform) {
    if (telemetry_id && !last_cafe_id) {
        last_cafe_id = cafe_id;
        TelemetryAPI.changeTarget(telemetry_id, cafe_id, mode).catch(err => {
            console.log(`Telemetry changeTraget error: ${err}`)
        });
        return;
    }

    TelemetryAPI.initSession({
        cafe_id,
        mode,
        platform,
        device: {
            screen_width: window.screen.width,
            screen_height: window.screen.height
        }
    }).then(data => {
        telemetry_id = data.id;

        pending.forEach((ev) => sendEvent(ev));
        pending = [];

        rrwebRecord({
            emit(event) {
                pending_gestures.push(event);
            }
        });
        
        last_cafe_id = cafe_id;

        if (sync_timer_id != null) clearInterval(sync_timer_id);

        sync_timer_id = setInterval(syncGestures, 5 * 1000);
    }).catch(error => {
        console.error(`[Telemetry] Error while creating session: ${error}`);
    });
}

function sendEvent(ev) {
    TelemetryAPI.writeEvent(telemetry_id, ev.type, ev.payload, ev.timestamp).then(ok => {
        //ok
    }).catch(error => {
        console.error(`[Telemetry] Event sync error: ${error}`);
    });
}

function emit(type, payload) {
    if (!telemetry_id) {
        pending.push({
            type,
            payload,
            timestamp: Date.now()
        });
        return;
    }

    sendEvent({
        type,
        payload,
        timestamp: Date.now()
    });
}

function getTelemetryId() {
    return telemetry_id;
} 

function addMilestone(type) {
    TelemetryAPI.setMilestone(telemetry_id, type);
}

export default {
    Events,
    init,
    emit,
    getTelemetryId,
    addMilestone
}