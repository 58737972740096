import vuex from 'vuex'
import Vue from 'vue'

import menu from './menu.module.js'
import preorder from './preorder.module.js'
import cart from './cart.module.js'
import clients from './clients.module.js'
import currency from './currency.module.js'
import worldwide from './worldwide.module.js'
import promotions from './promotions.module.js'
import desktop from './desktop.module'

Vue.use(vuex);

export default new vuex.Store({
    modules: {
        menu,
        preorder,
        cart,
        clients,
        currency,
        worldwide,
        promotions,
        desktop
    },
    state: {
        country: 'ua',
        uiBannerShown: false
    },
    mutations: {
        setCountry(state, payload) {
            state.country = payload;
        },
        setAppBannerShown(state,payload) {
            state.uiBannerShown = payload;
        },
    },
    actions: {

    },
    getters: {
        getCountryOptions(state) {
            const COUNTRY_OPTIONS = {
                ua: {
                    phone_mask: '{+38}-000-000-00-00'
                },
                ru: {
                    phone_mask: '{+7}-000-000-00-00'
                }
            };  

            return COUNTRY_OPTIONS[state.country.toLowerCase()];
        }
    }
});