const CZ_LOCALE = {
  errors: {
    access_denied: `В доступе отказано.`,
    invalid_data: `Неверный формат данных`,
    db_query_error: `Ошибка чтения базы даных.`,
    db_write_error: `Ошибка записи в базу данных.`,
    not_found: `Запрашиваемый объект не найден.`,
    cafe_not_ready: `Заведение не настроено дlя приема заказов.`,
    request_failed: `Слабое интернет соединение.`,

    retry: "Znovu načtěte stránku a zkuste to znovu.",
    tech_problem:
      "Vaši objednávku bohužel z technických důvodů nebylo možné zpracovat.",
  },
  // error_modal: {
  //     text: ' произошла ошибка загрузки., во время отправки заказа произошла ошибка.  заберите Ваш заказ., попробуйте обновить страницу, выключить/включить геолокацию и отправить заказ позже.'
  // },
  error_modal: {
    title: "Error code",
    text: "Something went wrong...",
    solve: "Update page and try again",
    change_address: "Please, change your delivery address",
  },

  banner: {
    title: "Aplikace pro jídlo",
    get_app: "STAŽENÍ",
  },

  greeting: {
    make_order: "Сделать заказ",
    table: "Stůl",
    until_close_left: "do zavírací doby",
    closed: "Zavřeno :(",
    not_paid: "Заведение не оплатило услуги QRWaiter :(",
    free_day: "Volno.",

    introduce_text: "Ваши персональные данные",
    introduce_input_name: "Как к Вам обращаться?",
    introduce_input_phone: "Vaše telefonní číslo",
    skip: "Пропустить",
    go: "Вперед!",

    preorder: "ПРЕДЗАКАЗ",

    choose_language: "Выберите язык:",
    tutorial: "Впервые у нас? Узнайте, как сделать заказ :)",
  },
  active_order: {
    check: " похоже Вы уже что-то заказали , похоже Вы уже что-то заказали ",
    modal_text:
      'Если это Ваш заказ - нажмите "Přijmout" чтобы добавить его в чек',
    accept: "Принять",
    cancel: "Zrušit",
  },
  schedule: {
    title: "Otevírací doba",

    mon: "Pondělí",
    tue: "Úterý",
    wed: "Středa",
    thu: "Čtvrtek",
    fri: "Pátek",
    sat: "Sobota",
    sun: "Neděle",

    short: {
      mon: "Pn",
      tue: "Ut",
      wed: "St",
      thu: "Ct",
      fri: "Pt",
      sat: "Sb",
      sun: "Nd",
    },

    free_day: "Víkend",
  },
  navbar: {
    services: "Služby",
    table_no: "Stůl č.",
    offert: "VEŘEJNÁ NABÍDKA",
  },
  menu: {
    show_more: "показать больше...",
    show_less: "показать меньше...",

    choose_variant: "Zvolte možnost:",
    choose_modificators: "Vyberte si doplňky",
    add_to_cart: "+ Přidat do košíku",
    added_to_cart: "Přidáno do košíku",
    recommending: "QRWaiter doporučuje",

    menu_closed_after: "Toto menu je bohužel otevřeno od ",
    menu_closed_before: "Toto menu je bohužel otevřeno do ",

    out_of_stock: "Bohužel toto jídlo není na skladě.",

    show_all: "показать все",
    exclusives: "Doporučení",

    weight: "Hmotnost:",
    where_to_start: "Kde začít?",

    price_per_unit: "Price for 1 {unit}:",
  },
  switcher: {
    primary: "Основное меню",
    tags: {
      bar: "Бар меню",
    },
  },
  cart: {
    empty: "Váš košík je prázdný.",
    comment: "Komentář:",
    comment_desc: "Přání",
    send: "Odeslat objednávku",
    next: "Dále >",
    min_check: "Minimální výše vaší objednávky musí činit:",
    wait: "Zpracování...",
    cashback: "Бонус от этого заказа составит {cashback}",
    empty: "Cart is empty. But you can always add a product!",
  },
  not_paid_modal: {
    title: "Заведение не оплатило услуги",
    text:
      " в Вашей локации нет заведений., это заведение не оплатило услуги QRWaiter, и поэтому отправка заказов в этом заведении временно заблокирована.",
  },
  card: {
    status_cart: "Můžete upravovat",
    status_waiting: "Zaslán instituci",
    status_serving: "Byla přijata před {mins} min",
    status_served: "Vyřízeno.",
    status_serving_recent: "Byla přijata před 1 min",
    status_cancelled: "Zrušeno.",
    status_unpaid: "Nezaplaceno.",
    status_paying: "Platba...",
    status_executed: "Zaplaceno",
    order: "Objednávka",
    check: "Účtenka",
    waiting_time: "Čekací doba:",
    approve_code: "Zadejte potvrzovací kód <br> <span>z SMS </span>",
    append: "Doobjednávka",
    waiting_time_long: "více než 20 minut",
    dont_close:
      " пока официант не приймет Ваш заказ., пока официант не приймет Ваш заказ.",
    play_game: " нажав на нее ниже., нажав на нее ниже.",
    bonus_balance: "Zůstatek bonusového účtu: {balance} Kč.",
    download_app: "Stáhnout aplikaci",
    order_else: `Další objednávka`,
    cancel_reason: "Zrušeno:",
    self_service:
      " предоставьте одноразовый<br> доступ к Вашей геолокации, заберите Ваш заказ.",
  },
  actions: {
    cancel: "Zrušit objednávku",
    pay: "Zaplatit",
    cancel_2: "Zrušit objednávku",
  },
  pay: {
    support_text:
      "Pokud jste spokojeni s rychlostí obsluhy a službami, můžete nechat spropitné a podpořit restauraci :)",
    tip_amount: "k vašemu účtu",
    tip_info:
      " Spropitné bylo vybráno automaticky, protože obsluha rychle přijala vaši objednávku.",
    choose_pay_method: "Zvolte způsob platby",
    method_bonus: "Бонусный účet QRWaiter",
    method_cash: "Zaplatit v hotovosti",
    method_card: "Zaplatit přes terminál",
    method_liqpay: "Zaplatit online",
    method_bonus: "Zaplatit z bonusového účtu",
  },
  cancel: {
    confirm_text: "Opravdu chcete zrušit objednávku?",
    select_reason: "Uveďte důvod:",
    reasons: {
      wrong_order: "Objednal/a jsem si to chybně",
      no_time: "Nemám čas čekat",
      custom: "Jiný důvod",
    },

    enter_reason: "Napište prosím důvod",

    button_continue: "Pokračovat",
    button_cancel: "Zrušit",
  },
  geo: {
    title: "Potřebujeme si zkontrolovat",
    please: "Povolte prosím přístup k polohovým službám",
    just_click: "Stačí klepnout na toto tlačítko :)",
    reload: "Znovu načtěte stránku",
    fail_title:
      " чтобы система поняла, чтобы система поняла, что Вы находитесь в заведении.",
    fail_subtitle:
      "Pro povolení přístupu přejděte do Nastavení > Soukromí > Polohové služby",

    fail_titles: {
      browser: "Nepovolili jste přístup k polohovým službám.",
      out_of_range: "Nacházíte se<br>mimo zónu doručení.",
      not_in_cafe: "Jste mimo zónu kavárny",
    },
    fail_subtitles: {
      browser: "Aktualizujte prosím stránku a zkuste to znovu.",
      out_of_range: "Doručení do vaší destinace není k dispozici.",
      not_in_cafe: "Chcete-li provést objednávku, musíte být v kavárně",
    },

    not_in_cafe: "Nejsem v zařízení",
  },
  deleted_dish: {
    sorry: "Omlouváme se za nepříjemnosti",
    description:
      "Obsluha odebrala tuto/tyto položku/y z vaší objednávky z důvodu:",
    order_else: "Objednejte si něco jiného :)",
  },
  superman: {
    wait: "Počkejte prosím",
    paid_cash_card: "Obsluha už k vám letí s účtem",
    paid_online: "Vaše platba je na cestě ke zpracování",
  },
  list: {
    total: "Celkem:",
    free: "Zdarma",
    tips: "Spropitné",
    cutlery: "Cutlery",
  },
  bill: {
    check_num: "ID účtenky:",
    serving_time: "Čas:",
    waiter: "Obsluha:",
    table: "Stůl:",
    date: "Datum:",
    discount: "Sleva:",
    download_check: "Uložit účtenku",

    cash: "Hotovost",
    card: "Terminal",
    online: "Online",
    connect_cafe: "Připojit svou restauraci",
  },
  preorder: {
    mode_preorder: "TAKEAWAY",
    mode_delivery: "DELIVERY",

    address: "Ulice, č. p., obec",

    contact_phone: "Контактный телефон заведения:",

    edit: "Редактировать",
  },
  preorder_bill: {
    title: "Предзаказ",

    action_pay: "Potvrdit objednávku",

    id: "ID účtenky:",
    takeaway: "Čas vyzvednutí:",
    date: "Datum:",
    phone: "Telefonní číslo",
    address: "Adresa:",
    time: "Čas:",

    cafe_address: "Adresa restaurace:",
    open_map: "Otevřít mapu",

    with_takeaway: "S sebou",
    with_reservation: "+ rezervace stolu",

    btn_back_to_menu: "Back to menu",

    warning:
      "Ваш предзаказ был отправлен. <br>Он будет готов в указанное Вами время.<br><br> Чтобы получить его, просто покажите<br> официанту этот чек. <br><br> ! Не закрывайте эту страницу либо<br>сохраните чек чтобы не потерять его..",
    send_after_payment:
      "Vaše objednávka bude odeslána do restaurace po zaplacení.",
    individual: {
      tenshi_delivery:
        "Doručení hradí se při převzetí. Стоимость доставки меняеться в соответствии с тарифами службы доставки.",
    },
    taxi_tariff:
      "Стоимость доставки нужно будет оплатить дополнительно при получении.",
  },
  address: {
    correct_title: "Změňte prosím adresu",
    select_google:
      "Adresa musí být vybrána z rozbalovací nabídky navrhovaných adres",
    correct_example: "Příklad: st. Shevchenka, 1, Kiev",
  },
  delivery_bill: {
    warning: "Ваш заказ<br> был отправлен заведению.<br>",
    delivery_item: "Doručení",
    delivery_additional: "Navíc",
    delivery_free: "Zdarma",
    delivery_additional_long: "Navíc при получении",
    cash_warning: "Заказ нужно будет оплатить наличными при получении.",
    cost_by_receive: "Доставка оплачивается дополнительно при получении.",
    delivery_cost_upper: "DORUČENÍ",
  },
  delivery_info: {
    title: "Doručení",
    free: "Doručení zdarma",
    constant: "Cena doručení",
    payment_check: "zahrnuta do ceny objednávky",
    payment_receive: "hradí se při převzetí",
    taxi: `Cena doručení je účtována dle ceníku dopravní služby a hradí se při převzetí objednávky.`,
  },
  reserve_info: {
    title: "Záloha",
    info: "Pro rezervaci je nutné složit zálohu",
  },
  units: {
    minutes: "min",
    hours: "h",
    g: "г",
    ml: "ml",
    l: "л",
    kg: "kg",
    cm: "cm",
    secs: "sec.",
  },
  partners: {
    title: "Наш партнер:",
    go: "Перейти",
    apteka: {
      name: "Ласкава-Фарм",
      desc: `Сеть онлайн аптек. Покупайте лекарства прямо со своего телефона. Doručení по всей Украине.`,
    },
  },
  roulette: {
    get_a_chance: "Получите шанс выиграть блюдо за счет QRWaiter!",
    try: "Попробовать!",
    next_time: "В другой раз",
    approve_code: "Zadejte potvrzovací kód <br> <span>z SMS </span>",

    leave_info: "Оставьте информацию, <br> чтобы получить приз.",
    name_ph: "Как Вас зовут?",
    phone_ph: "Ваш номер телефона",
    next: "Zaslat kód v SMS >",
    confirm: "Potvrdit",
    resend: "Zaslat kód znovu",

    limit: "You have already taken part in this giveaway",

    your_prize: "Ваш приз!",

    will_be_added:
      "Ваш приз будет добавлен в чек. <br /> Вы сможете его забрать, сделав заказ через QRWaiter.",
    no_prize: "Не повезло :(",

    you_lost:
      " что-то пошло не так :(, Вы ничего не выиграли. Но не огорчайтесь, повезет в следующий раз!",

    take_prize: "Забрать приз!",
    close: "Закрыть",
  },
  preorder_modes: {
    how: "Jak si přejete provést objednávku?",
    normal_desc: "Objednávka jídla a nápojů na odnos s sebou.",
    delivery_desc: " и в указанные дату и время., и в указанные дату и время.",
    min_check: "Minimální výše objednávky:",
  },
  preorder_time_modal: {
    header: "На какое время приготовить Ваш заказ",
    header_delivery: "Когда Ваш заказ должен быть доставлен",
    date: " я совершеннолетнийта",
    date_no_change: `Дату предзаказа сменить нельзя.<br />Она должна совпадать с сегодняшней. <br />Если это не так - обновите страницу.`,
    choose_time: "Выберите время",
    choose_time_warn: "Выберите время, на которое Ваш заказ должен быть готов.",

    personal_title: "Ваши персональные данные",
    call_you: "Как Вас зовут?",
    phone: "Ваш номер телефона",
    phone_desc: `Укажите Ваш номер телефона для связи с Вами.`,
    address: "Ваш адрес",
    address_desc: "Укажите Ваш адрес для доставки",
    fast_as_possible: "Co nejdříve",
    time: "Čas",
    time_desc: "Выберите время, на которе Ваш заказ должен быть готов.",
    address_warning: "Укажите корректный адрес и номер дома.",
  },
  delivery_mode_modal: {
    min_check: "Minimální výše objednávky:",
    cost: "Cena doručení:",
    cost_details: "Doručovací podmínky:",
    cost_types: {
      free: "Doručení v této restauraci je zdarma.",
      paid: "Cena doručení bude přidána k celkové ceně vaší objednávky.",
      taxi: "Cena doručení je účtována dle ceníku dopravní služby",
      conditional: "Při objednávce od {cost} {currency} doručení zdarma.",
    },
    cost_free: "Zdarma",
    cost_taxi: "Navíc",
  },
  mode_modal: {
    min_check_header: "Minimální výše",
    min_check_content: "Minimální výše objednávky:",

    delivery_price_header: "Podmínky a náklady na doručení",

    preorder_title:
      "Pomocí systému TAKEAWAY si můžete prohlédnout nabídku a pohodlně zadat objednávku „s vámi“.",
    takeaway_title:
      "Pomocí systému TAKEAWAY si můžete prohlédnout nabídku a pohodlně zadat objednávku „s vámi“.",
    delivery_title:
      "Pomocí systému DELIVERY můžete zobrazit nabídku a pohodlně objednat objednávku na doručení.",
    rooms_title:
      "Pomocí systému ROOMS můžete procházet nabídku a objednávat jídlo, pití nebo jinou pokojovou službu přímo do svého pokoje.",
    reserve_title: "Se systémem RESERVE si můžete rezervovat stůl na večer.",

    delivery_types: {
      free: "Doručení v této restauraci je zdarma.",
      constant: "Cena doručení: {cost}{currency}.",
      additional: "Cena doručení je účtována dle ceníku dopravní služby.",
      conditional: "Při objednávce od {min_check}{currency} doručení zdarma.",
    },

    btn_menu: "Přejít na nabídku",
    btn_hide: "Znovu se neukazuj",
  },
  back_to_marketplace: "Zpět na seznam restaurací",
  cafe_closed: {
    title: "Restaurace je zavřena :(",
    try_later: "Zahrát si hada",
    to_marketplace: "Zpět na úvod",
    refresh: "Aktualizovat stránku",
  },
  desktop_delivery: {
    navbar_menu: "Меню",
    navbar_cart: "Корзина",
    navbar_back: "Zpět na úvod",

    tab_cart: "Корзина",
    tab_client: "Мои контакты",
    tab_delivery: "Doručení",

    modal_subtitle: "Вы можете редактировать",

    next: "Далее >",
    sms_confirm: "Введите код подтверждения из СМС:",

    to_pay: "К оплате",
    pay_cash: "Zaplatit v hotovosti",
    pay_online: "Zaplatit online",
    address: "Адрес",
    address_ph: "Введите Ваш адрес",

    menu_quantity: "Укажите количество",

    comment_desc: "Укажите дополнительный комментарий при необходимости.",
  },
  aiwaiter_survey: {
    help_us: "Помоги нам в создании искуственного интеллекта официанта.",
    start_button: "Пройти опрос",
    describe_1: "Опиши свою любмую еду",
    describe_2: "Какая она?",
    example:
      "Например: итальянская, диетическая, японская, веганская, сытная, сладкая...",
    send: "Отправить",
  },
  phone_confirm: {
    change_phone: "Zkontrolovat telefonní číslo",
  },
  order_buttons: {
    tips: `Spropitné`,
    menu: "Menu",
    cart: "Košík",
    if1: ` Вы можете, Вы можете`,
    if2: `Připojit svůj podnik`,
    feedback: `Přidat recenzi`,
    go2cheque: "Přejít k objednávce",
    dishes: "jídel | {n} jídlo | {n} jídla | {n} jídel",
    send: "Odeslat objednávku",
  },
  alcoholic_modal: {
    subtitle: "Přidali jste do objednávky zboží podléhající spotřební dani.",
    text: "<br> что Вам исполнилось 18 лет.,<br> что Вам исполнилось 18 лет.",
    confirm: "Да, я совершеннолетний",
    cancel: "Zrušit",
  },
  reserve: {
    header: "Detail rezervace",

    name_ph: `Vaše jméno`,
    phone_ph: "Telefonní číslo",

    today: "Dnes",
    tomorrow: "Zítra",
    guests_count: "0 osob | 1 osoba | {n} osoby | {n} osob",
    guests_amount: "Počet osob",
    action: "Rezervovat",

    card_title: "RESERVE",
    card_guests_count: "Počet míst:",
    card_reserve_time: "Čas rezervace:",
    card_reserve_date: "Datum rezervace:",
    card_phone: "Telefonní číslo",
  },
  pay_methods: {
    title: "ZPŮSOB PLATBY",
    total: "CELKEM",
    bonus_balance: "BONUSOVÝ ÚČET",
    null: {
      long: "Zvolte způsob platby",
    },
    cash: {
      short: "Hotovost",
      long: "Zaplatit v hotovosti",
      preorder: "Hotově při převzetí",
    },
    card: {
      short: "Terminal",
      long: "Zaplatit kartou",
      preorder: "Kartou při převzetí",
    },
    online: {
      short: "Online",
      long: "Zaplatit online",
      preorder: "Online platba předem",
    },
    bonus: {
      short: "Bonusy",
      long: "Zaplatit z bonusového účtu",
    },
  },
  feedback: {
    header: "Přidejte recenzi",
    title: "Recenze:",
    for_cafe: "O restauraci",
    for_qrwaiter: "O QRWaiter",
    rating: "Hodnocení",
    send: "Odeslat recenzi",
    cancel: "Nepřidávat recenzi",
    comment_ph: "Your comment",
    close: "Close",
    thanks: "Thank you for feedback!",
  },
  marketplace: {
    list: {
      search_ph: "Vyhledávání",
      filters: "Filtry",
      cities_list: "Seznam obcí",
      load_error: "Bohužel došlo k chybě",
      loading: "Načítání...",
      city: "Obec:",
      all_cities: "Všechny obce",
      favorite_cafes: "Vaše oblíbené restaurace",
      map: "Mapa restaurací",
      no_cafes: "Bohužel kolem vás nejsou žádné kavárny",
      schedule: "Otevírací doba:",
      closed: "Zavřeno",
      day_off: "víkend",
      opens_at: "otevře se v",
      opens_in: "do otevření",
      closes_in: "do zavření",
      distance: "km. ot vas",
      goto_menu: "View menu",
      goto_chain: "View cafes",
      open_24hr: "nepřetržitě",
      all_categories: "Vsi",
    },
    filters: {
      by_mode: "Podle systémů:",
      by_price: "Podle ceny:",
      by_city: "Podle obce",
      apply: "Použít filtry",
      all_cities: "Všechny obce",
      geo_city: "Podle mé polohy",
      desktop_title: "SHOW CAFES",
      sys_inside: "Orders via QR-codes",
      sys_preorder: "Preordering",
      sys_delivery: "Delivery",
      sys_reserve: "Table reservations",
    },
    city_filters: {
      geo: "Podle mé polohy",
      all: "Všechny",
    },
    map: {
      open: "Otevřít",
    },
    chain: {
      count: "spots",
    },
  },
  preorder_details: {
    contacts: "Vaše kontakty",
    takeaway: "S sebou",
    with_reservation: "Rezervovat stůl",
    guests_count: "Počet sad příborů",
    guests_count_suffix: "Bez příboru | {n} sada | {n} sady | {n} sad",
    preorder_title: "Detail objednávky",
    delivery_title: "Detail doručení",
    choose_time: "Zvolte datum a čas",

    details_types: {
      flat: "Byt",
      floor: "Podlaží",
      entrance: `Vchod`,
    },

    new_comment:
      "Můžete přidat poznámku k objednávce. Uveďte například své potravinové alergie nebo napište svá přání.",
  },
  choose_mode: {
    preorder: "Objednejte si jídlo s sebou",
    delivery: "Objednejte si jídlo s doručením",
    reserve: "Rezervujte si stůl na večer",
    click: "Objednat *tap*",
    view_menu: "Přejít na nabídku",
    delivery_missing: "Připojit dodávku",
    preorder_missing: "Připojte předobjednávku",
    reserve_missing: "Připojte rezervaci",
    go_reserve: "Go reserve",
    preview_menu: "Přejít na nabídku",
  },
  download_bill: {
    product_check: "Účtenka",
    check_no: "Účtenka č.",
    table_no: "Stůl č. ",
    waiter: "Obsluha",
    qnt: "ks",
    discount: "Sleva",
    cash: "Hotovost",
    card: "Terminál",
    online: "Online",
    pay_method: "Způsob platby",
    total: "CELKEM",
  },
  rotate_device: "Otočte své zařízení.",
  comment_field_title: "Váš komentář k objednávce",
  comment_field_info:
    "K objednávce můžete zanechat komentář. Například uveďte alergenní složky nebo vaše přání.",
  promocode: {
    title: "Máte promo kód?",
    input: "Promo kód",
    correct_code: "Pod propagačním kódem získáte slevu 20% na celý šek!",
    wrong_code: "Promo kód je již použit",
  },
  load_error: {
    title: "К сожалению, что-то пошло не так :(",
    cafe_not_paid:
      "Restaurace neprodloužila předplatné QRWaiteru. Menu není k dispozici :(",
    code: "Kód chyby:",
    back: "На главную",
    support: "Contact support",
    mode_setup: "Systém {mode} není v této restauraci nastaven",
    wrong_setup: "Restaurace je nastavena nekorektně.",
    not_found: ` проверьте корректность ссылки., проверьте корректность ссылки.`,
    request_failed: ` проверьте доступ к Интернету., проверьте доступ к Интернету.`,
  },
  desktop_blocker: {
    description:
      " чтобы открыть мобильную версию., чтобы открыть мобильную версию.",
  },
  activate_cafe: {
    title: "Podnik {cafe_name} byl úspěšně aktivován.",
    text:
      "Gratulujeme! Nálepky s QR kódy byly úspěšně aktivovány a po zaplacení předplatného si vaši hosté budou moct objednávat.",
    subscribe: "Zaplatit předplatné",
  },
  moeco_partner_card: {
    title_delivery: "Ваш заказ будет доставлен экологическим такси moeco",
    motto: "Будь эко з moeco",
    title_normal: "Наш партнер по доставке -<br> эко-такси moeco.",
    save_contact: "Сохранить контакт",
  },
  marketplace_geo_modal: {
    hint: "Je to nutné pro zobrazení restaurací ve vašem městě.",
    title: "Povolte prosím jednorázový<br> přístup k polohovým službám",
    ok: "OK",
    deny: "Nepovolovat přístup",
    denied: "Přístup k polohovým službám byl odepřen.",
    error_text:
      " предоставьте доступ к геолокации., предоставьте доступ к геолокации.",
    try_again: "Opakovat pokus",
  },
  split_payment: {
    number: "счет",
    sent_text: "Ваш заказ будет отправлен заведению после оплаты.",
    pay_button: "Zaplatit účet",
  },
  online_payment_modal: {
    redirect_btn: "Přejděte na platbu",
    cancel_btn: "Vrátit",
  },
  support_modal: {
    title: "Vyberte nejlepší způsob, jak kontaktovat podporu:",
    close: "Zavřít",
  },
  successfull_payment: {
    title: "Vaše platba byla přijata",
    button: "Zavřít",
    subtitle: "Toto okno můžete zavřít a vrátit se k objednávce",
  },
  order_webstatuses: {
    confirmed_off: "Vaše objednávka byla odeslána.<br>Čeká na potvrzení...",
    confirmed_on: "Vaše objednávka byla odeslána.<br>Čeká na potvrzení...",
    cancelled_on: "Objednávka byla zrušena:",
    accepted_off: "V procesu",
    accepted_on: "V procesu...<br>Čekací doba: {waiting_time} min.",
    served_off: "Čekací doba",
    served_on: "Čekací doba",
    delivered_off: "Dodávka",
    delivered_on: "Zobrazování ....<br>Počkejte na volání od kurýra",
    closed_off: "Platba",
    closed_on: "Platba dokončena",
    reserved_off: "Reservation confirmation",
    reserved_on: "Accepted",
  },
  notifications_update: {
    title: "QRWaiter now will not send SMS notifications",
    content:
      "Order status can be found under the cheque or on marketplace page",
  },
  fix_address_modal: {
    title: "",
    subtitle: "",
  },
  tips_modal: {
    title: "If you liked the service, you can leave a tip =)",

    btn_pay: "Pay",
    btn_add: "Add to check",
    btn_cancel: "Go back",

    thanks: "Thanks a lot!",

    leave_tips: "Leave a tip",
    add_tips: "Add a tip",
  },
  order_not_accepted: {
    title: "Your order was not accepted",
    button: "Order something else",
  },
  future_alert: {
    title: "Pre-order",
    info:
      "Your order has been made while cafe is closed. The cafe will receive notification about your order at {date} (after opening)",
  },
  packaging_info: {
    title: "Packaging",
    desc:
      "Packaging cost will be additionaly included in your check with price of",
  },
};


export default CZ_LOCALE;
