var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"thematic",rawName:"v-thematic"}],staticClass:"feedback-box"},[_c('h4',{class:_vm.isDarkMode ? 'about dark' : 'about'},[_vm._v("\n    "+_vm._s(_vm.$t("feedback.title"))+"\n  ")]),_c('div',{class:_vm.isDarkMode ? 'feedback-switcher dark' : 'feedback-switcher'},[_c('div',{class:{
        switch: true,
        cafe: true,
        'switch-active': !_vm.is_qrwaiter,
      },on:{"click":function($event){return _vm.toggleTab()}}},[_vm._v("\n      "+_vm._s(_vm.$t("feedback.for_cafe"))+"\n    ")]),_c('div',{class:{
        switch: true,
        qrw: true,
        'switch-active': _vm.is_qrwaiter,
      },on:{"click":function($event){return _vm.toggleTab()}}},[_vm._v("\n      "+_vm._s(_vm.$t("feedback.for_qrwaiter"))+"\n    ")])]),_c('textarea',{directives:[{name:"thematic",rawName:"v-thematic"},{name:"model",rawName:"v-model",value:(_vm.comment),expression:"comment"}],attrs:{"maxlength":"512"},domProps:{"value":(_vm.comment)},on:{"focus":function($event){return _vm.freezeScrolling(false)},"blur":function($event){return _vm.freezeScrolling(true)},"input":function($event){if($event.target.composing){ return; }_vm.comment=$event.target.value}}}),_c('div',[_c('div',{directives:[{name:"thematic",rawName:"v-thematic"}],staticClass:"rating"},_vm._l((5),function(n){return _c('div',{key:n,class:{
          active: _vm.score == n,
          'active-red': _vm.score == n && n <= 2,
        },on:{"click":function($event){_vm.score = n}}},[_c('img',{class:{
            'img-rate': true,
            rotated: n <= 2 && _vm.score != n,
            selected: _vm.score == n,
          },attrs:{"src":_vm.score == n
              ? n > 2
                ? '/icons/hand-green.svg'
                : '/icons/hand-red.svg'
              : '/icons/hand-grey.svg'}}),_vm._v("\n        "+_vm._s(n)+"\n      ")])}),0)]),_c('LongButton',{staticClass:"btn-bottom btn-cancel",on:{"click":function($event){return _vm.cancel()}}},[_vm._v(_vm._s(_vm.$t("feedback.cancel")))]),_c('LongButton',{staticClass:"send btn-bottom",attrs:{"variant":"success"},on:{"click":function($event){return _vm.onSend()}}},[_vm._v(_vm._s(_vm.$t("feedback.send")))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }