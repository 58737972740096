import BaseAPI from '../base'

function getProfile(client_id) {
    return BaseAPI.sendGet(`clients/profile/${client_id}`);
}

function create(name, phone, address) {
    return BaseAPI.sendPost(`clients/create`, {
        name,
        phone,
        address
    });
}

function getBalance(client_id, cafe_id) {
    return BaseAPI.sendGet(`clients/balance/${client_id}/${cafe_id}`);
}

export default {
    getProfile,
    create,
    getBalance
}