<template>
  <div class="language-modal-wrapper">
    <div class="hide-content"></div>

    <div :class="isDarkMode ? 'language-modal-container dark':'language-modal-container'">
      <ul :class="isDarkMode ? 'select-language dark':'select-language'">
        <li v-for="locale in available" @click="onSwitch(locale)"  :key="locale" :class="currentLanguage == locale ?  'active' : ''">
          <img class="lan-img" :src="`/flags/${locale}.png`" />
          <p>{{ name_translations[locale] || locale }}</p>
        </li>
      </ul>
      <button class="choose-lang" @click="onConfirm">
        {{ button_translations[currentLanguage] || 'Select'}}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentLanguage: 'uk',

      button_translations: {
        uk: 'Обрати',
        en: 'Select',
        ru: 'Выбрать',
        tr: 'Seçme'
      },

      name_translations: {
        en: "English",
        ru: "Русский",
        uk: "Українська",
        be: "Беларуская",
        cs: "čeština",
        de: "Deutsch",
        fr: "Français",
        it: "Italiano",
        sv: 'Svenska',
        no: 'Norsk',
        pt: 'Português',
        hu: "Magyar",
        el: "ελληνικά",
        pl: 'polski',
        nl: 'Nederlands',
        ja: "日本語",
        th: 'ไทย',
        ko: '한국어',
        zh: '中文, 汉语, 漢語',
        hi: 'हिन्दी; हिंदी',
        tr: 'Türkçe',
        ar: 'اَلْعَرَبِيَّةُ',
        az: "Azərbaycan dili",
        lv: 'latviešu valoda'
      }
    }
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    onSwitch(arg) {
      this.currentLanguage = arg;
    },
    onConfirm() {
      this.$emit("switch", this.currentLanguage);
    }
  },
  computed: {
    available() {
      return this.$store.getters['menu/supportedLocales'];
    }
  },
  mounted() {
    this.currentLanguage = this.$i18n.locale;
  }
};
</script>

<style scoped>
.language-modal-container {
  background: #a4a3a1;
  display: flex;
  flex-flow: column nowrap;
  margin: 0 auto;
  transition: 0.3s;
  overflow-y: auto;
  z-index: 10;
  height: 100vh;
  position: absolute;
  left: 0;
  right: 0;
  top: 7vh;
  width: 100%;
}

.language-modal-container.dark {
  background: #1d1d1b;
}

.choose-lang {
    width: 90%;
    position: fixed;
    bottom: 3.2vh;
    background: #129b81;
    text-align: center;
    font-size: 2vh;
    padding: 1.6vh 0;
    border-radius: 5vh;
    left: 0;
    right: 0;
    margin: 0 auto;
    border: none;
    outline: none;
    color: #ededed;
}

.lan-img {
  height: 3vh;
  width: auto;
  margin-right: 2vh;
}

.select-language {
  display: flex;
  width: 100%;
  padding: 0;
  list-style-type: none;
  flex-flow: column;
  align-self: center;
  color: white;
  text-align: center;
  margin: 0;
}

.select-language.dark li {
  background: #464646;
  color: #ededed;
}

.select-language li:first-child {
  margin-top: 0;
}

.select-language li {
  display: flex;
  margin-top: 0.6vh;
  background: #ededed;
  align-items: center;
  padding: 2vh;
  font-size: 2.5vh;
  color: #1d1d1d;
}

.select-language li.active {
  background: #129b81;
  color: #ededed;
}

.select-language li p {
  margin: 0;
  line-height: normal;
}

.hide-content {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 4;
  overflow: hidden;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
  top: 0;
  left: 0;
}

.close {
  width: auto;
  height: 4.2vh;
  opacity: 1;
  right: 5.5vw;
  position: absolute;
}
</style>
